import React from 'react';
import {Box} from "@mui/material";

interface Props {
    language: number
}

const WorkInProgress = (props: Props) => {
    const textEn = "Under development"
    const textFr = "En cours de Développement"
    const textDe = "In Entwicklung"
    let displayed = ""

    if (props.language === 0)
        displayed = textEn
    else if (props.language === 2)
        displayed = textDe
    else
        displayed = textFr

    return (
        <Box style={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px", width: "100%", marginLeft: "20px", marginRight: "20px"}}>
            <h1 style={{textAlign: "center", width: "90%"}}>{displayed}</h1>
        </Box>
    );
};

export default WorkInProgress;
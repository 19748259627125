import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import MyText from "../components/MyText";
import ConstantsJson from "../assets/jsons/constants.json"
import VideoLoopJson from "../assets/jsons/videoLoop.json"
import {useInView} from "react-intersection-observer";
import "../assets/css/constants.css"
import "../assets/css/offices.css"
import {Flex} from "rebass";
import Footer from "../components/Footer";
import {FaPlay as PlayIcon} from "react-icons/fa"
import PageTitle from "../components/PageTitle";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const VideoLoop = (props: Props) => {
    const [ref1, inViewRef1] = useInView({triggerOnce: true})

    return (
        <Box>
            <PageTitle title={VideoLoopJson.availableLanguages[props.language].pageTitle} backgroundPicture={VideoLoopJson.availableLanguages[props.language].backgroundPicture} backgroundPositionY={"top"}/>
            <Box ref={ref1} className={inViewRef1 ? "fadeDisplayAnim2" : "noOpacity"} style={{marginTop: "100px"}}>
                <MyText value={VideoLoopJson.availableLanguages[props.language].videoTitle} style={{color: "var(--Grey)", textAlign: "center", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)", marginBottom: "30px"}}/>
            </Box>
            <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} marginTop={"100px"} paddingTop={"70px"} paddingBottom={"70px"} backgroundColor={"var(--InputGrey)"} style={{position: "relative", zIndex: "0"}}>
                <video poster={require("../"+"assets/picturesAndVideos/offices/previewVideoLoop"+ ".webp")} src={require("../"+"assets/picturesAndVideos/offices/videoLoop"+ ".mp4")} controls={true} loop={true} style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "50%" : "90%", height: "50%", borderRadius: "var(--MapRadius)"}}/>
                <PlayIcon className={inViewRef1 ? "fadeDisplayAnim5" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "-2%", top: "45%", zIndex: "-2"}}/>
                <PlayIcon className={inViewRef1 ? "fadeDisplayAnim6" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "8%", top: "45%", zIndex: "-2"}}/>
                <PlayIcon className={inViewRef1 ? "fadeDisplayAnim7" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "72%", top: "45%", zIndex: "-2"}}/>
                <PlayIcon className={inViewRef1 ? "fadeDisplayAnim8" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "82%", top: "45%", zIndex: "-2"}}/>
            </Flex>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language} svgChange={true}/>
        </Box>
    );
};

export default VideoLoop;
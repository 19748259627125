import React, {useState} from "react";
import "../assets/css/constants.css"
import { Box } from "@mui/material";
import { MdCancel as BaseRightIcon} from "react-icons/md";

interface Props {
    backgroundColor?: string;
    borderBottom?: string;
    borderRadius?: string;
    onClick?: () => void;
    borderRight?: string;
    fontFamily?: string;
    borderLeft?: string;
    borderTop?: string;
    minHeight?: string;
    fontSize?: string;
    minWidth?: string;
    padding?: string;
    opacity?: number;
    height?: string;
    margin?: string;
    cursor?: string;
    color?: string;
    width?: string;
    text: string;

    hover?: {
        backgroundColor?: string;
        borderBottom?: string;
        borderRadius?: string;
        borderRight?: string;
        fontFamily?: string;
        borderLeft?: string;
        borderTop?: string;
        minHeight?: string;
        fontSize?: string;
        minWidth?: string;
        padding?: string;
        opacity?: number;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
    }

    leftIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick?: () => void;
    };

    rightIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick?: () => void;
    };

    rightIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };

    leftIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };
}

export const Button = (props: Props) => {
    const [hover, setHover] = useState(false);
    const [rightIconHover, setRightIconHover] = useState(false);
    const [leftIconHover, setLeftIconHover] = useState(false);
    const isRightIcon = props.rightIcon?.icon;
    const isLeftIcon = props.leftIcon?.icon;
    const isLeftAndRightIcon = isRightIcon && isLeftIcon;

    const getPadding = () => {
        if (isLeftAndRightIcon)
            return "8px 40px 8px 40px"
        else if (isLeftIcon)
            return "8px 30px 8px 40px"
        else if (isRightIcon)
            return "8px 40px 8px 30px"
        else
            return "8px 30px 8px 30px"
    }

    const getPaddingHover = () => {
        if (isLeftAndRightIcon)
            return "7px 39px 7px 39px"
        else if (isLeftIcon)
            return "7px 29px 7px 39px"
        else if (isRightIcon)
            return "7px 39px 7px 29px"
        else
            return "7px 29px 7px 29px"
    }

    const style = {
        backgroundColor: props.backgroundColor || "var(--Green)",
        borderRadius: props.borderRadius || "4px",
        borderBottom: props.borderBottom || "none",
        borderRight: props.borderRight || "none",
        borderLeft: props.borderLeft || "none",
        borderTop: props.borderTop || "none",
        padding: props.padding || getPadding(),
        cursor: props.cursor || "pointer",
        color: props.color || "var(--White)",
        fontFamily: props.fontFamily || "inherit",
        fontSize: props.fontSize || "inherit",
        minHeight: props.minHeight || "auto",
        minWidth: props.minWidth || "auto",
        height: props.height || "auto",
        margin: props.margin || "0",
        width: props.width || "auto",
        textAlign: "center" as "center",
        lineHeight: props.height || "auto",
        outline: "none",
    };

    const styleHover = {
        backgroundColor: (props.hover && props.hover.backgroundColor) || "var(--Green)",
        borderRadius: (props.hover && props.hover.borderRadius) || "4px",
        borderBottom: (props.hover && props.hover.borderBottom) || "1px solid var(--LightGreen)",
        borderRight: (props.hover && props.hover.borderRight) || "1px solid var(--LightGreen)",
        borderLeft: (props.hover && props.hover.borderLeft) || "1px solid var(--LightGreen)",
        borderTop: (props.hover && props.hover.borderTop) || "1px solid var(--LightGreen)",
        padding: (props.hover && props.hover.padding) || getPaddingHover(),
        cursor: (props.hover && props.hover.cursor) || "pointer",
        color: (props.hover && props.hover.color) || "var(--White)",
        fontFamily: (props.hover && props.hover.fontFamily) || "inherit",
        fontSize: (props.hover && props.hover.fontSize) || "inherit",
        minHeight: (props.hover && props.hover.minHeight) || "auto",
        minWidth: (props.hover && props.hover.minWidth) || "auto",
        height: (props.hover && props.hover.height) || "auto",
        margin: (props.hover && props.hover.margin) || "0",
        width: (props.hover && props.hover.width) || "auto",
        textAlign: "center" as "center",
        lineHeight: props.height || "auto",
        outline: "none",
    };

    const rightIconStyle = {
        backgroundColor: (props.rightIcon && props.rightIcon.backgroundColor) || "transparent",
        padding: (props.rightIcon && props.rightIcon.padding) || "0",
        cursor: (props.rightIcon && props.rightIcon.cursor) || "pointer",
        color: (props.rightIcon && props.rightIcon.color) || "var(--White)",
        size: (props.rightIcon && props.rightIcon.size) || "inherit",
        minHeight: (props.rightIcon && props.rightIcon.minHeight) || "auto",
        minWidth: (props.rightIcon && props.rightIcon.minWidth) || "auto",
        height: (props.rightIcon && props.rightIcon.height) || "auto",
        margin: (props.rightIcon && props.rightIcon.margin) || "0 0 -2 -25",
        width: (props.rightIcon && props.rightIcon.width) || "auto",
        opacity: (props.rightIcon && props.rightIcon.opacity) || 1,
        visibility: (props.rightIcon && props.rightIcon.icon) ? "visible" : "hidden",
        outline: "none"
    };

    const rightIconHoverStyle = {
        backgroundColor: (props.rightIconHover && props.rightIconHover.backgroundColor) || "transparent",
        padding: (props.rightIconHover && props.rightIconHover.padding) || "0",
        cursor: (props.rightIconHover && props.rightIconHover.cursor) || "pointer",
        color: (props.rightIconHover && props.rightIconHover.color) || "var(--White)",
        size: (props.rightIconHover && props.rightIconHover.size) || "inherit",
        minHeight: (props.rightIconHover && props.rightIconHover.minHeight) || "auto",
        minWidth: (props.rightIconHover && props.rightIconHover.minWidth) || "auto",
        height: (props.rightIconHover && props.rightIconHover.height) || "auto",
        margin: (props.rightIconHover && props.rightIconHover.margin) || "0 0 -2 -25",
        width: (props.rightIconHover && props.rightIconHover.width) || "auto",
        opacity: (props.rightIconHover && props.rightIconHover.opacity) || 1,
        outline: "none"
    };

    const leftIconStyle = {
        backgroundColor: (props.leftIcon && props.leftIcon.backgroundColor) || "transparent",
        padding: (props.leftIcon && props.leftIcon.padding) || "0",
        cursor: (props.leftIcon && props.leftIcon.cursor) || "pointer",
        color: (props.leftIcon && props.leftIcon.color) || "var(--White)",
        size: (props.leftIcon && props.leftIcon.size) || "inherit",
        minHeight: (props.leftIcon && props.leftIcon.minHeight) || "auto",
        minWidth: (props.leftIcon && props.leftIcon.minWidth) || "auto",
        height: (props.leftIcon && props.leftIcon.height) || "auto",
        margin: (props.leftIcon && props.leftIcon.margin) || "0 -25px -2 0",
        width: (props.leftIcon && props.leftIcon.width) || "auto",
        opacity: (props.leftIcon && props.leftIcon.opacity) || 1,
        visibility: (props.leftIcon && props.leftIcon.icon) ? "visible" : "hidden",
        position: "relative" as "relative",
        zIndex: "1",
        outline: "none",
    };

    const leftIconHoverStyle = {
        backgroundColor: (props.leftIconHover && props.leftIconHover.backgroundColor) || "transparent",
        padding: (props.leftIconHover && props.leftIconHover.padding) || "0",
        cursor: (props.leftIconHover && props.leftIconHover.cursor) || "pointer",
        color: (props.leftIconHover && props.leftIconHover.color) || "var(--White)",
        size: (props.leftIconHover && props.leftIconHover.size) || "inherit",
        minHeight: (props.leftIconHover && props.leftIconHover.minHeight) || "auto",
        minWidth: (props.leftIconHover && props.leftIconHover.minWidth) || "auto",
        height: (props.leftIconHover && props.leftIconHover.height) || "auto",
        margin: (props.leftIconHover && props.leftIconHover.margin) || "0 -25px -2 0",
        width: (props.leftIconHover && props.leftIconHover.width) || "auto",
        opacity: (props.leftIconHover && props.leftIconHover.opacity) || 1,
        position: "relative" as "relative",
        zIndex: "1",
        outline: "none"
    };

    const LeftIcon = (props.leftIcon && props.leftIcon.icon) || BaseRightIcon;
    const RightIcon = (props.rightIcon && props.rightIcon.icon) || BaseRightIcon;

    const rightIcon = <RightIcon style={rightIconHover ? rightIconHoverStyle : rightIconStyle} onMouseEnter={() => setRightIconHover(true)} onMouseLeave={() => setRightIconHover(false)} onClick={() => {props.rightIcon?.onClick && props.rightIcon.onClick()}}/>
    const leftIcon = <LeftIcon style={leftIconHover ? leftIconHoverStyle : leftIconStyle} onMouseEnter={() => setLeftIconHover(true)} onMouseLeave={() => setLeftIconHover(false)} onClick={() => {props.leftIcon?.onClick && props.leftIcon.onClick()}}/>;

    return (
        <Box style={{display: "flex"}}>
            {leftIcon}
            <p onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={hover ? styleHover : style} onClick={() => {props.onClick && props.onClick()}}>{props.text}</p>
            {rightIcon}
        </Box>
    )
};

export default Button;
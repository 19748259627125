import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import LegalJson from "../assets/jsons/legal.json"
import MyText from "../components/MyText";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const LegalNotice = (props: Props) => {
    return (
        <Box>
            <PageTitle title={LegalJson.availableLanguages[props.language].title} backgroundPicture={LegalJson.availableLanguages[props.language].backgroundLink}/>
            <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "300px", width: "75%", marginTop: "120px", marginBottom: "120px", marginLeft: "auto", marginRight: "auto"}}>
                <Box>
                    <MyText value={LegalJson.availableLanguages[props.language].name} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].siret} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].address} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].postalCode} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].town} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].tel} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].mail} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].capital} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].tva} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].writeDirector} style={{marginBottom: "30px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].editedBy} style={{marginBottom: "5px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].hosting} style={{marginBottom: "30px"}}/>
                    <MyText value={LegalJson.availableLanguages[props.language].creation}/>
                </Box>
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default LegalNotice;
import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import SearchJson from "../src/assets/jsons/search.json";
import { CookiesProvider } from 'react-cookie';

import { ThemeProvider } from 'theme-ui'
import type { Theme } from 'theme-ui'
import {useCookies} from "react-cookie";
import MyRoutes from "./MyRoutes";
import countapi from "countapi-js";

export const theme: Theme = {
    fonts: {
        myExtraLight: 'Muli-ExtraLight',
        myLight: 'Muli-Light',
        myRegular: 'Muli-Regular',
        mySemiBold: 'Muli-SemiBold',
        myBold: 'Muli-Bold',
    },
    fontSizes: {
        mySmall: "var(--SmallFontSize)",
        myMid: "var(--MidFontSize)",
        myBig: "var(--BigFontSize)",
        myTitle: "var(--TitleFontSize)",
        myPageTitle: "var(--PageTitleFontSize)",
        myGiant: "var(--GiantFontSize)",
    },
    colors: {
        myWhite: "var(--White)",
        myBlack: "var(--Black)",
        myLightGreen: "var(--LightGreen)",
        myGreen: "var(--Green)",
        myDarkGreen: "var(--DarkGreen)",
        myGrey: "var(--Grey)",
        myDarkGrey: "var(--DarkGrey)",
        myInputGrey: "var(--InputGrey)",
        myLightBlue: "var(--LightBlue)",
        myBeige: "var(--Beige)",
        myBackground: "var(--Background)",
        primary: "var(--Green)",
    },
    borderStyles: {
        myRadius: "var(--BorderRadius)",
        myInputRadius: "var(--InputBorderRadius)",
    },
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height,
    };
}

function App() {
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);
    const [windowHeight, setWindowHeight] = useState(getWindowDimensions().height);
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState([] as {string: string, link: string}[])
    const [cookies, setCookie] = useCookies(['name']);
    const [language, setLanguage] = useState(1);
    const [isLoading, setIsLoading] = useState(true)
    const loadingSpinner = document.getElementById("loadingSpinner")

    const [count, setCount] = useState(0);
    const [countTotal, setCountTotal] = useState(0);

    useEffect(()=> {
        if (window.location.pathname.toLowerCase() !== "/counter" && window.location.pathname.toLowerCase() !== "/intro") {
            countapi.get('wollenschneider.fr', '4a91bc2d-458b-4c68-8aaa-51981e5bd08a').then((result) => { setCount(result.value); countapi.set('wollenschneider.fr', '4a91bc2d-458b-4c68-8aaa-51981e5bd08a', result.value + 1).then((result) => {  })});
            countapi.get('wollenschneider.frtotal', 'a28f08b9-96e5-49c9-94f1-83e2b1737d8b').then((result) => { setCountTotal(result.value); countapi.set('wollenschneider.frtotal', 'a28f08b9-96e5-49c9-94f1-83e2b1737d8b', result.value + 1).then((result) => {  }) });
        }
    },[])

    useEffect(() => {
        if (cookies.name)
            setLanguage(cookies.name)
    }, [])

    useEffect(() => {
        if (loadingSpinner) {
            setTimeout(() => {
                loadingSpinner.style.display = "none"
                setIsLoading(false)
            }, 500)
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowWidth(getWindowDimensions().width);
            setWindowHeight(getWindowDimensions().height);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setSearchValue(searchValue.charAt(0).toUpperCase() + searchValue.slice(1).toLowerCase())
        search()
    }, [searchValue])

    useEffect(() => {
        setCookie('name', language, { path: '/' });
        setSearchValue("")
    }, [language])

    const search = () => {
        if (searchValue !== "") {
            let results;
            if (searchValue.length >= 2) {
                results = SearchJson.availableLanguages[language].searchTab.filter((item) => {
                    return item.string.toLowerCase().includes(searchValue.toLowerCase());
                });
                if (results.length === 0)
                    results = SearchJson.availableLanguages[language].searchTab.filter((item) => {
                        return item.substring.toLowerCase().includes(searchValue.toLowerCase());
                    });
            }
            else {
                results = SearchJson.availableLanguages[language].searchTab.filter((item) => {
                    return item.string.toLowerCase().startsWith(searchValue.toLowerCase());
                });
                if (results.length === 0 && searchValue.length >= 2)
                    results = SearchJson.availableLanguages[language].searchTab.filter((item) => {
                        return item.substring.toLowerCase().includes(searchValue.toLowerCase());
                    });
            }
            setSearchResult(results);
            if (results.length === 0)
                setSearchResult([{string: SearchJson.availableLanguages[language].noResult, link: ""}]);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <CookiesProvider>
                    <ParallaxProvider>
                        <ToastContainer theme={"colored"}/>
                            <Header windowWidth={windowWidth} windowHeight={windowHeight} language={language} setLanguage={setLanguage} isSearching={isSearching} setIsSearching={setIsSearching} searchValue={searchValue} setSearchValue={setSearchValue} searchResult={searchResult}/>
                            <MyRoutes windowWidth={windowWidth} windowHeight={windowHeight} language={language} setLanguage={setLanguage} isSearching={isSearching} setIsSearching={setIsSearching} searchValue={searchValue} setSearchValue={setSearchValue} searchResult={searchResult}/>
                    </ParallaxProvider>
                </CookiesProvider>
            </BrowserRouter>
        </ThemeProvider>
  );
}

export default App;

import React, {Ref} from "react";
import { Text } from 'theme-ui'
import "../assets/css/header.css"
interface Props {
    value: string | undefined
    style?: object;
    as?: string
    className?: any
    onClick?: () => void
}

export const MyText = (props: Props) => {
    return <Text as={props.as ? props.as as React.ElementType : "p" as  React.ElementType}
            sx={{
                fontSize: "myMid",
                fontFamily: "myRegular",
                whiteSpace: "pre-wrap",
            }}
            onClick={props.onClick}
            className={props.className ? props.className : ""}
            style={props.style}>
        {props.value}
    </Text>
};

export default MyText;
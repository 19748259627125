import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import {Flex} from "rebass";
import MyText from "../components/MyText";
import MyImage from "../components/MyImage";
import FootLifterJson from "../assets/jsons/footLifter.json"
import ConstantsJson from "../assets/jsons/constants.json"
import MyTextSplit from "../components/MyTextSplit";
import {Parallax} from "react-scroll-parallax";
import {useInView} from "react-intersection-observer";
import MyVideo from "../components/MyVideo";
import InsolesVideo from "../assets/picturesAndVideos/footLifter/360footLifter.mp4"
import Footer from "../components/Footer";
import ContantsJson from "../assets/jsons/constants.json";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const FootLifter = (props: Props) => {
    const [section1El1, inViewSection1El1] = useInView({triggerOnce: true})
    const [section1El2, inViewSection1El2] = useInView({triggerOnce: true})

    const [section2El1, inViewSection2El1] = useInView({triggerOnce: true})
    const [section2El2, inViewSection2El2] = useInView({triggerOnce: true})
    const [section2El3, inViewSection2El3] = useInView({triggerOnce: true})
    const [section2El4, inViewSection2El4] = useInView({triggerOnce: true})

    const [section3El1, inViewSection3El1] = useInView({triggerOnce: true})
    const [section3El2, inViewSection3El2] = useInView({triggerOnce: true})
    const [section3El3, inViewSection3El3] = useInView({triggerOnce: true})
    const [section3El4, inViewSection3El4] = useInView({triggerOnce: true})
    const [section3El5, inViewSection3El5] = useInView({triggerOnce: true})
    const [section3El6, inViewSection3El6] = useInView({triggerOnce: true})

    const [section4El1, inViewSection4El1] = useInView({triggerOnce: true})

    return (
        <Box>
            <PageTitle title={FootLifterJson.availableLanguages[props.language].sections[0].pageTitle} backgroundPicture={props.windowWidth > ContantsJson.MobileScreenEndWith ? FootLifterJson.availableLanguages[props.language].sections[0].backgroundPicture : FootLifterJson.availableLanguages[props.language].sections[0].backgroundPicture2} backgroundPositionY={"top"}/>
            <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} marginTop={"90px"} maxWidth={"90%"} width={"max-content"} marginLeft={"auto"} marginRight={"auto"}>
                <Box ref={section1El1} className={inViewSection1El1 ? "fadeDisplayAnim3" : "noOpacity"} style={{width: "100%", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "left" : "center", marginBottom: "20px"}}>
                    <MyText as={"h1"} value={FootLifterJson.availableLanguages[props.language].sections[1].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                </Box>
                <Box ref={section1El2} className={inViewSection1El2 ? "fadeDisplayAnim4" : "noOpacity"} style={{textAlign: "left"}}>
                    <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[1].textsplit} boxStyle={{fontSize: "var(--BigFontSize)", fontFamily: "Muli-Light"}}/>
                </Box>
            </Flex>
            <Flex flexDirection={props.windowWidth > ConstantsJson.MobileScreenEndWith ? "row" : "column"} justifyContent={"center"} alignItems={"center"} marginTop={"130px"}>
                <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ConstantsJson.MobileScreenEndWith ? "50%": "90%"} marginLeft={"60px"} marginRight={"60px"}>
                    <Box ref={section3El1} className={inViewSection3El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "left" : "center", width: "100%", marginBottom: "20px"}}>
                        <MyText as={"h1"} value={FootLifterJson.availableLanguages[props.language].sections[4].title} style={{width: "100%", fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                    </Box>
                    <Box ref={section3El3} className={inViewSection3El3 ? "fadeDisplayAnim3" : "noOpacity"} style={{marginTop: "5px", textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[4].texts![0].textsplit}/>
                    </Box>
                    <Box ref={section3El4} className={inViewSection3El4 ? "fadeDisplayAnim4" : "noOpacity"} style={{marginTop: "5px", textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[4].texts![1].textsplit}/>
                    </Box>
                    <Box ref={section3El5} className={inViewSection3El5 ? "fadeDisplayAnim5" : "noOpacity"} style={{marginTop: "5px", textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[4].texts![2].textsplit}/>
                    </Box>
                    <Box ref={section3El6} className={inViewSection3El6 ? "fadeDisplayAnim6" : "noOpacity"} style={{marginTop: "5px", textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[4].texts![3].textsplit}/>
                    </Box>
                </Flex>
                <Box ref={section3El2} className={inViewSection3El2 ? "rightToLeftDisplayAnim2" : "noOpacity"} style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "45%" : "90%", marginRight: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "60px" : "auto", marginLeft: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0" : "auto", marginTop: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0": "50px"}}>
                    <Parallax speed={10}>
                        <MyVideo src={InsolesVideo} style={{width: "100%", position: "relative", bottom: "-40px"}} playsInline={true}/>
                    </Parallax>
                </Box>
            </Flex>
            <Flex flexDirection={props.windowWidth > ConstantsJson.MobileScreenEndWith ? "row" : "column"} justifyContent={"center"} alignItems={"center"} marginTop={"130px"}>
                {props.windowWidth > ConstantsJson.MobileScreenEndWith && <Box ref={section2El2} className={inViewSection2El2 ? "rightToLeftDisplayAnim2" : "noOpacity"} style={{width: "45%", marginRight: "60px", marginLeft: "auto", marginTop: "50px"}}>
                    <Parallax speed={8} style={{marginLeft: "2%", marginTop: props.windowWidth > 1000 ? "" : "30px"}}>
                        <Box style={{position: "relative"}}>
                            <MyImage src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink1+props.language+".webp") : require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink1+props.language+"Mob.webp")} style={{scale: "0.8", width: "100%"}}/>
                            <Parallax speed={10} style={{position: "absolute", left: "13%", top: "20%"}}>
                                <MyImage src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink2+props.language+".webp") : require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink2+props.language+"Mob.webp")} style={{scale: "1.2", position: "relative", left: "-10%"}}/>
                                <Parallax speed={15} style={{position: "absolute", left: "13%", top: "40%"}}>
                                    <MyImage src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink3+props.language+".webp") : require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink3+props.language+"Mob.webp")} style={{scale: "1.2"}}/>
                                </Parallax>
                            </Parallax>
                        </Box>
                    </Parallax>
                </Box>}
                <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ConstantsJson.MobileScreenEndWith ? "50%": "90%"} marginLeft={"60px"} marginRight={"60px"}>
                    <Box ref={section2El1} className={inViewSection2El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "left" : "center", width: "100%", marginBottom: "20px"}}>
                        <MyText as={"h1"} value={FootLifterJson.availableLanguages[props.language].sections[2].title} style={{width: "100%", fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                    </Box>
                    <Box ref={section2El3} className={inViewSection2El3 ? "fadeDisplayAnim3" : "noOpacity"} style={{textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[2].texts![0].textsplit}/>
                    </Box>
                    <Box ref={section2El4} className={inViewSection2El4 ? "fadeDisplayAnim4" : "noOpacity"} style={{marginTop: "5px", textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootLifterJson.availableLanguages[props.language].sections[2].texts![1].textsplit}/>
                    </Box>
                </Flex>
                {props.windowWidth < ConstantsJson.MobileScreenEndWith && <Box ref={section2El2} className={inViewSection2El2 ? "rightToLeftDisplayAnim2" : "noOpacity"} style={{width: "80%", marginRight: "auto", marginLeft: "auto", marginTop: "0"}}>
                    <Parallax speed={8} style={{marginLeft: "2%", marginTop: props.windowWidth > 1000 ? "" : "30px"}}>
                        <Box style={{position: "relative"}}>
                            <MyImage src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink1+props.language+".webp") : require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink1+props.language+"Mob.webp")} style={{scale: "0.8", width: "100%", position: "relative", left: "-10%"}}/>
                            <Parallax speed={10} style={{position: "absolute", left: "13%", top: "20%"}}>
                                <MyImage src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink2+props.language+".webp") : require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink2+props.language+"Mob.webp")} style={{scale: "1.2", position: "relative", left: "-18%"}}/>
                                <Parallax speed={10} style={{position: "absolute", left: "13%", top: "40%"}}>
                                    <MyImage src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink3+props.language+".webp") : require("../"+FootLifterJson.availableLanguages[props.language].sections[2].pictureLink3+props.language+"Mob.webp")} style={{scale: "1.2", position: "relative", left: "-12%"}}/>
                                </Parallax>
                            </Parallax>
                        </Box>
                    </Parallax>
                </Box>}
            </Flex>
            <Flex alignItems={"center"} ml={"auto"} mr={"auto"} marginBottom={"100px"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ConstantsJson.MobileScreenEndWith ? "75%" : "90%"} marginTop={"120px"}>
                <Box>
                    <Box ref={section4El1} className={inViewSection4El1 ? "fadeDisplayAnim1" : "noOpacity"}>
                        <MyText value={FootLifterJson.availableLanguages[props.language].sections[3].text} style={{fontFamily: "Muli-Light", whiteSpace: "pre-wrap", fontSize: "var(--TitleFontSize)", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "center" : "left"}}/>
                    </Box>
                </Box>
            </Flex>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default FootLifter;
import React from 'react';
import {Box} from "@mui/material";
import FirstSvg from "../assets/picturesAndVideos/svg/titlePage/title.svg";
import DefaultBackgroundPicture from "../assets/picturesAndVideos/home/homePagePictures/formesChaussures.webp";
import {useInView} from "react-intersection-observer";
import MyText from "./MyText";
import {Flex} from "rebass";

interface Props {
    title?: string;
    backgroundPicture?: string;
    backgroundPositionY?: string
}

const PageTitle = (props: Props) => {
    const [ref, inViewRef] = useInView({triggerOnce: true})

    return (
        <Box className={"backgroundPictures"} style={{backgroundImage: props.backgroundPicture ? 'url(' + require('../'+props.backgroundPicture+'.webp') + ')' : `url(${DefaultBackgroundPicture})`, height: "410px", backgroundPositionY: props.backgroundPositionY, textAlign: "center"}}>
            <Box className={"sectionTitleWhite"} style={{backgroundImage: `url(${FirstSvg})`, height: "410px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "var(--PageTitleFontSize"}}>
                <Flex alignItems={"center"} ref={ref} className={inViewRef ? "fadeDisplayAnim1" : "noOpacity"}>
                    <MyText as={"h1"} value={props.title} style={{marginTop: "-100px", textShadow: "0 4px 2px rgba(0, 0, 0, 0.25)", fontSize: "var(--PageTitleFontSize)"}}/>
                </Flex>
            </Box>
        </Box>
    );
};

export default PageTitle;

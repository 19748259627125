import React from 'react';
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import HeaderJson from "../assets/jsons/header.json";
import HomeJson from "../assets/jsons/home.json";
import FooterJson from "../assets/jsons/footer.json";
import BackgroundSvg from "../assets/picturesAndVideos/svg/footer/background.svg"
import BackgroundSvg2 from "../assets/picturesAndVideos/svg/footer/background2.svg";
import BackgroundSvgPicture from "../assets/picturesAndVideos/general/machineCoudre.webp";
import { MdKeyboardArrowUp as CarretUpIcon } from 'react-icons/md';
import { FaCircle as Circle } from "react-icons/fa";
import { GrMail as MailIcon } from "react-icons/gr";
import {useInView} from "react-intersection-observer";
import MyText from "./MyText";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
    svgChange?: boolean
}

const Footer = (props: Props) => {
    const navigate = useNavigate();
    const Scroll = require('react-scroll');
    const scroll = Scroll.animateScroll;

    const style = {
        circlesContact: {
            position: "relative" as "relative",
            top: "calc(2px + 0.6vw + 10px)",
            height: "clamp(3px, 1vw, 7px)"
        },
        circlesSchedule: {
            height: "clamp(3px, 1vw, 7px)",
            marginRight: "5px"
        },
    }

    const [section1El1, inViewSection1El1] = useInView({triggerOnce: true})
    const [section1El2, inViewSection1El2] = useInView({triggerOnce: true})
    const [section1El3, inViewSection1El3] = useInView({triggerOnce: true})

    const [section2El1, inViewSection2El1] = useInView({triggerOnce: true})
    const [section2El2, inViewSection2El2] = useInView({triggerOnce: true})
    const [section2El3, inViewSection2El3] = useInView({triggerOnce: true})

    const [section3El1, inViewSection3El1] = useInView({triggerOnce: true})
    const [section3El2, inViewSection3El2] = useInView({triggerOnce: true})

    return (
        <Box>
            <Box className={"backgroundPictures"} style={{backgroundImage: `url(${BackgroundSvgPicture})`, height: "630px"}}>
                <Box style={{backgroundImage: `url(${props.svgChange ? BackgroundSvg2 : BackgroundSvg})`, height: "630px", display: "flex", flexDirection: "column", alignItems: "center", color: "var(--White)", flexWrap: "wrap"}}>
                    <Box style={{display: "flex", justifyContent: "space-evenly", width: "100%", paddingTop: "40px", marginTop: "auto", marginBottom: "auto"}}>
                        <Box style={{fontSize: "var(--MidFontSize)", width: "100%"}}>
                            <Box ref={section1El1} className={inViewSection1El1 ? "sectionTitleGreen fadeDisplayAnim2": "sectionTitleGreen noOpacity"} style={{display: "flex", justifyContent: "center", marginBottom: "15px"}}>
                                <MyText as={"h1"} value={FooterJson.availableLanguages[props.language]["schedule"][0].title} style={{fontSize: "var(--TitleFontSize)"}}/>
                            </Box>
                            <Box ref={section1El2} className={inViewSection1El2 ? "opacityDisplayAnim3" : "noOpacity"} style={{marginTop: "15px", fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", display: "flex", justifyContent: "center"}}>
                                <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].appointement}/>
                            </Box>
                            <Box ref={section1El3} className={inViewSection1El3 ? "opacityDisplayAnim4" : "noOpacity"} style={{display: "flex", width: "100%", justifyContent: "space-evenly", textAlign: "center", flexWrap: "wrap"}}>
                                <Box style={{width: "33.3%", minWidth: "300px"}}>
                                    <Box style={{marginTop: "15px", marginBottom: "5px"}}>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].sarralbe} style={{fontFamily: "Muli-Bold"}}/>
                                    </Box>
                                    <Box>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].sarralbe1} style={{fontSize: "var(--SmallFontSize)"}}/>
                                    </Box>
                                    <Box>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].sarralbe2} style={{fontSize: "var(--SmallFontSize)"}}/>
                                    </Box>
                                </Box>
                                <Box style={{width: "33.3%", minWidth: "300px"}}>
                                    <Box style={{marginTop: "15px", marginBottom: "5px"}}>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].sarreguemines} style={{fontFamily: "Muli-Bold"}}/>
                                    </Box>
                                    <Box>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].sarreguemines1} style={{fontSize: "var(--SmallFontSize)"}}/>
                                    </Box>
                                    <Box>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].sarreguemines2} style={{fontSize: "var(--SmallFontSize)"}}/>
                                    </Box>
                                </Box>
                                <Box style={{width: "33.3%", minWidth: "300px"}}>
                                    <Box style={{marginTop: "15px", marginBottom: "5px"}}>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].metz} style={{fontFamily: "Muli-Bold"}}/>
                                    </Box>
                                    <Box>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].metz1} style={{fontSize: "var(--SmallFontSize)"}}/>
                                    </Box>
                                    <Box>
                                        <MyText value={FooterJson.availableLanguages[props.language]["schedule"][0].metz2} style={{fontSize: "var(--SmallFontSize)"}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box ref={section2El1} className={inViewSection2El1 ? "sectionTitleGreen fadeDisplayAnim2" : "noOpacity sectionTitleGreen"} style={{display: "flex", justifyContent: "center", marginBottom: "15px"}}>
                        <MyText as={"h1"} value={HeaderJson.availableLanguages[props.language]["navigation"][HeaderJson.availableLanguages[props.language]["navigation"].length - 1].name} style={{fontSize: "var(--TitleFontSize)"}}/>
                    </Box>
                    <Box ref={section2El2} className={inViewSection2El2 ? "goto opacityDisplayAnim3" : "noOpacity"} style={{fontFamily: "Muli-Light", fontSize: "var(--SmallFontSize)"}} onClick={() => window.location.assign(`mailto:${HomeJson.mail}`)}>
                        <MailIcon size={Number("var(--SmallFontSize)")} style={{verticalAlign: "middle"}}/>
                        <MyText value={HomeJson.mail} className={"goto"} style={{marginLeft: "5px", display: "inline-block"}}/>
                    </Box>
                    <Box ref={section2El3} className={inViewSection2El3 ? "opacityDisplayAnim4" : "noOpacity"} style={{display: "flex", justifyContent: "space-evenly", width: "96%", marginTop: "20px", fontFamily: "Muli-Light", marginBottom: "25px"}}>
                        <Box className={"goto"} style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"}} onClick={() => window.location.assign(`tel:${HomeJson.sarralbeTel}`)}>
                            <MyText value={HomeJson.sarralbe} style={{fontSize: "var(--SmallFontSize)"}}/>
                            <MyText value={HomeJson.sarralbeTel} style={{fontSize: "var(--SmallFontSize)"}}/>
                        </Box>
                        <Circle style={style.circlesContact}/>
                        <Box className={"goto"} style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"}} onClick={() => window.location.assign(`tel:${HomeJson.sarregueminesTel}`)}>
                            <MyText value={HomeJson.sarreguemines} style={{fontSize: "var(--SmallFontSize)"}}/>
                            <MyText value={HomeJson.sarregueminesTel} style={{fontSize: "var(--SmallFontSize)"}}/>
                        </Box>
                        <Circle style={style.circlesContact}/>
                        <Box className={"goto"} style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"}} onClick={() => window.location.assign(`tel:${HomeJson.metzTel}`)}>
                            <MyText value={HomeJson.metz} style={{fontSize: "var(--SmallFontSize)"}}/>
                            <MyText value={HomeJson.metzTel} style={{fontSize: "var(--SmallFontSize)"}}/>
                        </Box>
                    </Box>
                    <Box style={{color: "var(--White)", textAlign: "center", fontFamily: "Muli-ExtraLight", fontSize: "var(--SmallFontSize)"}}>
                        <Box ref={section3El1} className={inViewSection3El1 ? "downToUpDisplayAnim4" : "noOpacity"}>
                            <CarretUpIcon size={40} className={"infiniteFloatingBounceAnim"} style={{cursor: "pointer"}} onClick={() => {scroll.scrollToTop()}}/>
                        </Box>
                        <Box className={inViewSection3El1 ? "downToUpDisplayAnim4" : ""}>
                            <text style={{color: "var(--FooterGrey)"}}>{FooterJson.availableLanguages[props.language]["legal"][0].copyright}</text>
                        </Box>
                        <Box className={inViewSection3El1 ? "downToUpDisplayAnim4" : ""} style={{color: "var(--FooterGrey)"}}>
                            <text className={"goto"} onClick={() => {navigate("/legalnotice"); window.scrollTo(0, 0)}}>{FooterJson.availableLanguages[props.language]["legal"][0].legal}</text>
                            <text> - </text>
                            <text className={"goto"} onClick={() => {navigate("/privatelifeprotection"); window.scrollTo(0, 0)}}>{FooterJson.availableLanguages[props.language]["legal"][0].privacy}</text>
                        </Box>
                    </Box>
                    <text ref={section3El2} className={inViewSection3El2 ? "downToUpDisplayAnim4" : "noOpacity"} style={{color: "var(--FooterGrey)", fontFamily: "Muli-ExtraLight", fontSize: "var(--SmallFontSize)", marginBottom: "25px"}}>{FooterJson.availableLanguages[props.language]["legal"][0].made} Frédéric Forster</text>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;

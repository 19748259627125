import React from "react";
import {toast, ToastPosition} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const ToastInfo = (text: string, position?: string, autoCloseDelay?: number, hideProgressBar?: boolean, closeOnClick?: boolean, pauseOnHover?: boolean, draggable?: boolean) => {
    const baseAutoCloseDelay = autoCloseDelay || 2500;
    const baseHideProgressBar = hideProgressBar || true;
    const baseCloseOnClick = closeOnClick || true;
    const basePauseOnHover = pauseOnHover || true;
    const baseDraggable = draggable || false;
    const basePosition = position || "top-center";

    toast.info(text, {
        position: basePosition as ToastPosition,
        autoClose: baseAutoCloseDelay,
        hideProgressBar: baseHideProgressBar,
        closeOnClick: baseCloseOnClick,
        pauseOnHover: basePauseOnHover,
        draggable: baseDraggable,
        progress: undefined,
    });
};

export default ToastInfo;
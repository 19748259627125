import React from 'react';
import {Box} from "@mui/material";

const FooterContact = () => {
    return (
        <Box>
            <h1>End Page Contact</h1>
        </Box>
    );
};

export default FooterContact;

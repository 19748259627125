import React from "react";
import { Input, Box, Label } from 'theme-ui'
import NumberFormat from "react-number-format";

interface Props {
    value: string;
    setValue: (e: any) => void;
    label?: string;
    placeholder?: string;
    onChange?: any;
    style?: object;
    boxStyle?: object;
    labelStyle?: object;
    numbersOnly?: boolean;
    charactersOnly?: boolean;
    required?: boolean;
    maxLength?: number;
    type?: string;
    regex?: RegExp;
    status?: number;
    focus?: number;
    setFocus?: any;
    autoFocus?: boolean;
    autocomplete?: string;
}

export const MyInputText = (props: Props) => {
    const numbersOnly = new RegExp(
        '^[/\\d+/]*$'
    );

    const charactersOnly = new RegExp(
        '^[/\\D+/]*$'
    );

    const handleChange = (e: any) => {
        if (props.onChange)
            props.onChange(e)
        else {
            if (props.regex) {
                if (e.target.value.match(props.regex))
                    props.setValue(e.target.value);
            } else {
                if (props.numbersOnly !== props.charactersOnly) {
                    if (props.numbersOnly && e.target.value.match(numbersOnly))
                        props.setValue(e.target.value);
                    if (props.charactersOnly && e.target.value.match(charactersOnly))
                        props.setValue(e.target.value);
                } else if (!props.numbersOnly && !props.charactersOnly && !props.regex)
                    props.setValue(e.target.value);
            }
        }
    }

    const getResult = (type: string) : any => {
        if (type === "color") {
            if (props.status === 1)
                return "orange"
            if (props.status === 2)
                return "red"
            if (props.status === 3)
                return "var(--Green)"
            return "inherit"
        }
        if (type === "numberFormatStyle") {
            if (props.status === 1)
                return {color: "var(--Grey)", background: "var(--InputGrey)", outline: "none", border: "none", borderBottom: "1px solid orange", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", fontFamily: "Muli-Regular", fontSize: "var(--MidFontSize)", transition: "all 0.2 ease", padding: "7px", width: "100%"}
            if (props.status === 2)
                return {color: "var(--Grey)", background: "var(--InputGrey)", outline: "none", border: "none", borderBottom: "1px solid red", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", fontFamily: "Muli-Regular", fontSize: "var(--MidFontSize)", transition: "all 0.2 ease", padding: "7px", width: "100%"}
            if (props.status === 3)
                return {color: "var(--Grey)", background: "var(--InputGrey)", outline: "none", border: "none", borderBottom: "1px solid var(--Green)", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", fontFamily: "Muli-Regular", fontSize: "var(--MidFontSize)", transition: "all 0.2 ease", padding: "7px", width: "100%"}
            return {color: "var(--Grey)", background: "var(--InputGrey)", outline: "none", border: "none", borderBottom: "1px solid grey", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", fontFamily: "Muli-Regular", fontSize: "var(--MidFontSize)", transition: "all 0.2 ease", padding: "7px", width: "100%"}
        }
        if (type === "inputBorderBottom") {
            if (props.status === 1)
                return "1px solid orange"
            if (props.status === 2)
                return "1px solid red"
            if (props.status === 3)
                return "1px solid var(--Green)"
            return "1px solid grey"
        }
    }

    return (
        <Box style={props.boxStyle}>
            {props.label &&
                <Label
                    sx={{
                        color: getResult("color"),
                        marginLeft: "5px",
                        marginBottom: "2px",
                        fontSize: "myTitle",
                        fontFamily: "myRegular",
                        transition: "all 0.2s ease",
                    }}
                    style={props.labelStyle}>
                    {props.label}</Label>
            }
            {props.type === "tel" && <NumberFormat
                autoFocus={props.autoFocus}
                value={props.value}
                onChange={(e: any) => {props.setValue(e.target.value)}}
                required={props.required}
                style={getResult("numberFormatStyle")}
                format="## ## ## ## ##"
                mask="_"
                onBlur={() => props.setFocus(2)}
                onFocus={() => props.setFocus(1)}/>}
            {props.type !== "tel" && <Input
                type={props.type ? props.type : "text"}
                required={props.required}
                sx={{
                    color: "myGrey",
                    background: "myInputGrey",
                    outline: "none",
                    border: "none",
                    borderBottom: getResult("inputBorderBottom"),
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    fontFamily: "myRegular",
                    fontSize: "myBig",
                    transition: "all 0.2s ease",
                }}
                autoFocus={props.autoFocus}
                maxLength={props.maxLength}
                onBlur={() => props.setFocus(2)}
                onFocus={() => props.setFocus(1)}
                autoComplete={props.autocomplete}
                value={props.value} style={props.style} onChange={(e) => handleChange(e)} placeholder={props.placeholder || ""}/>}
        </Box>
    )
};

export default MyInputText;
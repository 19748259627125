import React, {useEffect, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HoverVideoPlayer from 'react-hover-video-player';
import ButtonSelected from "../components/ButtonSelected";
import "../assets/css/home.css"
import ConstantsJson from "../assets/jsons/constants.json";
import HomeJson from "../assets/jsons/home.json"
import HeaderJson from "../assets/jsons/header.json"
import FirstSvg from "../assets/picturesAndVideos/svg/homePage/first.svg";
import FirstSvgPicture from "../assets/picturesAndVideos/home/homePagePictures/interieurPieds.webp";
import FirstSvgPictureMob from "../assets/picturesAndVideos/home/homePagePictures/interieurPiedsMob.webp";
import SecondSvg from "../assets/picturesAndVideos/svg/homePage/second.svg";
import ThirdSvg from "../assets/picturesAndVideos/svg/homePage/third.svg";
import ThirdSvgPicture from "../assets/picturesAndVideos/home/homePagePictures/formesChaussures.webp";
import ThirdSvgPictureMob from "../assets/picturesAndVideos/home/homePagePictures/formesChaussuresMob.webp";
import WollenLogo from "../assets/picturesAndVideos/icons/logo_wollen.webp";
import { GrMail as MailIcon } from "react-icons/gr";
import { BsTelephoneFill as TelIcon } from "react-icons/bs";
import { GiPositionMarker as PositionIcon } from "react-icons/gi";
import { MdOutlineCancel as CancelIcon } from 'react-icons/md';
import { useInView } from "react-intersection-observer";
import {isMobile} from 'react-device-detect';
import MyImage from "../components/MyImage";
import Footer from "../components/Footer";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const firstPartStyle = {
    city: {
        fontFamily: "Muli-Bold",
        fontSize: "var(--TitleFontSize)",
        marginBottom: "0"
    },

    street: {
        fontFamily: "Muli-Light",
        fontSize: "var(--BigFontSize)",
    },

    postalCode: {
        fontFamily: "Muli-ExtraLight",
        fontSize: "var(--MidFontSize)",
    },

    circles: {
        position: "relative" as "relative",
        top: "calc(10px + 0.6vw + 95px)",
        height: "clamp(10px, 1vw, 200px)"
    },

    circlesMobile: {
        position: "relative" as "relative",
        top: "50px",
        height: "clamp(10px, 1vw, 200px)",
        marginRight: "auto",
        marginLeft: "auto",
    },

    circlesMobile2: {
        position: "relative" as "relative",
        top: "50px",
        height: "clamp(10px, 1vw, 200px)",
        marginRight: "auto",
        marginLeft: "auto",
    }
}

const Home = (props: Props) => {
    const navigate = useNavigate()
    const [videoBoxHover, setVideoBoxHover] = useState(-1)
    const qualities = HomeJson.availableLanguages[props.language].sections[3].qualities
    const yearsOfExperience = new Date().getFullYear() - 1909;
    const [preciseLocalisation, setPreciseLocalisation] = useState("")
    const mapLanguage = [ "en", "fr", "de"]

    useEffect(() => {
        if (window.location.href === "www.wollenschneider.fr" || window.location.href === "wollenschneider.fr")
            window.location.replace("https://www.wollenschneider.fr")
    }, [])

    const [section1El1, inViewSection1El1] = useInView({triggerOnce: true})
    const [section1El2, inViewSection1El2] = useInView({triggerOnce: true})
    const [section1El3, inViewSection1El3] = useInView({triggerOnce: true})
    const [section1El4, inViewSection1El4] = useInView({triggerOnce: true})
    const [section1El5, inViewSection1El5] = useInView({triggerOnce: true})

    const [section2El1, inViewSection2El1] = useInView({triggerOnce: true})
    const [section2El2, inViewSection2El2] = useInView({triggerOnce: true})
    const [section2El3, inViewSection2El3] = useInView({triggerOnce: true})
    const [section2El4, inViewSection2El4] = useInView({triggerOnce: true})
    const [section2El5, inViewSection2El5] = useInView({triggerOnce: true})
    const [section2El6, inViewSection2El6] = useInView({triggerOnce: true})

    const [section3El1, inViewSection3El1] = useInView({triggerOnce: true})
    const [section3El2, inViewSection3El2] = useInView({triggerOnce: true})
    const [section3El3, inViewSection3El3] = useInView({triggerOnce: true})
    const [section3El4, inViewSection3El4] = useInView({triggerOnce: true})
    const [section3El5, inViewSection3El5] = useInView({triggerOnce: true})
    const [section3El6, inViewSection3El6] = useInView({triggerOnce: true})
    const [section3El7, inViewSection3El7] = useInView({triggerOnce: true})
    const [section3El8, inViewSection3El8] = useInView({triggerOnce: true})
    const [section3El9, inViewSection3El9] = useInView({triggerOnce: true})

    const [section4El1, inViewSection4El1] = useInView({triggerOnce: true})
    const [section4El2, inViewSection4El2] = useInView({triggerOnce: true})
    const [section4El3, inViewSection4El3] = useInView({triggerOnce: true})
    const [section4El4, inViewSection4El4] = useInView({triggerOnce: true})
    const [section4El5, inViewSection4El5] = useInView({triggerOnce: true})
    const [section4El6, inViewSection4El6] = useInView({triggerOnce: true})
    const [section4El7, inViewSection4El7] = useInView({triggerOnce: true})
    const [section4El8, inViewSection4El8] = useInView({triggerOnce: true})
    const [section4El9, inViewSection4El9] = useInView({triggerOnce: true})

    const [section5El1, inViewSection5El1] = useInView({triggerOnce: true})
    const [section5El2, inViewSection5El2] = useInView({triggerOnce: true})
    const [section5El3, inViewSection5El3] = useInView({triggerOnce: true})

    const [section6El1, inViewSection6El1] = useInView({triggerOnce: true})
    const [section6El2, inViewSection6El2] = useInView({triggerOnce: true})
    const [section6El3, inViewSection6El3] = useInView({triggerOnce: true})
    const [section6El4, inViewSection6El4] = useInView({triggerOnce: true})
    const [section6El5, inViewSection6El5] = useInView({triggerOnce: true})

    return (
        <Box>

            {/* First part */}
            <Box style={{backgroundImage: props.windowWidth > ConstantsJson.MobileScreenEndWith ? `url(${FirstSvgPicture})` : `url(${FirstSvgPictureMob})`, height: "860px"}} className={"backgroundPictures"}>
                <Box style={{backgroundImage: `url(${FirstSvg})`, height: "860px"}}>
                    <Box style={{height: "700px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center", color: "var(--White)", width: "100%", marginTop: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "" : "110px"}}>
                            <Box ref={section1El1} className={inViewSection1El1 ? "fadeDisplayAnim4" : "noOpacity"}>
                                <span style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)"}}>{HomeJson.availableLanguages[props.language].sections[0].companyTitle}</span>
                            </Box>
                            <Box ref={section1El2} className={inViewSection1El2 ? "fadeDisplayAnim1" : "noOpacity"} style={{display: "flex", alignItems: "center", height: "80px"}}>
                                {props.windowWidth > ConstantsJson.MobileScreenEndWith && <Box style={{display: "flex", flexDirection: "column", width: "var(--GiantFontSize)", position: "absolute", left: "-8rem", minWidth: "90px"}}>
                                    <span style={{marginLeft: "auto", marginRight: "auto", position: "relative", top: "-10px"}}>{HomeJson.availableLanguages[props.language].sections[0].logoTitle}</span>
                                    <MyImage src={WollenLogo} style={{scale: "1.3"}}/>
                                    <span style={{marginLeft: "auto", marginRight: "auto", position: "relative", bottom: "-10px"}}>{HomeJson.availableLanguages[props.language].sections[0].logoSubtitle}</span>
                                </Box>}
                                <h1 style={{fontFamily: "Muli-Bold", fontSize: "var(--GiantFontSize)", letterSpacing: "2px", color: "var(--Green)", textShadow: "0 6px 4px rgba(0, 0, 0, 0.25)"}}>{HomeJson.company}</h1>
                            </Box>
                            <Box ref={section1El3} className={inViewSection1El3 ? "fadeDisplayAnim4" : "noOpacity"} style={{display: "flex", alignItems: "center", marginTop: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "20px" : "0"}}>
                                <span style={{fontFamily: "Muli-Light", fontSize: "var(--TitleFontSize)", marginRight: "4px"}}>{HomeJson.availableLanguages[props.language].sections[0].founded}</span>
                                <span style={{fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}>{HomeJson.foundedDate}</span>
                            </Box>
                            <span ref={section1El5} className={inViewSection1El5 ? "fadeDisplayAnim4" : "noOpacity"} style={{fontFamily: "Muli-Bold", fontSize: "var(--CompanyDescriptionFontSize)", marginTop: props.windowWidth <= ConstantsJson.MobileScreenEndWith ? "50px" : "100px", textAlign: "center", marginLeft: "15px", marginRight: "15px", marginBottom: "0"}}>{HomeJson.availableLanguages[props.language].sections[0].shoesAndInsoles}</span>
                            {props.windowWidth <= ConstantsJson.MobileScreenEndWith ?
                                <Box ref={section1El4} className={inViewSection1El4 ? "fadeDisplayAnim2" : "noOpacity"} style={{display: "flex", justifyContent: "space-evenly", width: "100%", flexDirection: "column"}}>
                                    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px"}}>
                                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[0].link); window.scrollTo(0, 0)}} className={"goto"}>
                                            <span style={firstPartStyle.city}>{HomeJson.sarralbe+" "+HomeJson.sarralbePostalCode}</span>
                                            <span style={firstPartStyle.street}>{HomeJson.sarralbeStreet}</span>
                                        </Box>
                                        <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarralbeTel}`)}>
                                            <span style={firstPartStyle.postalCode}>{HomeJson.sarralbeTel}</span>
                                        </Box>
                                    </Box>
                                    <FaCircle style={firstPartStyle.circlesMobile}/>
                                    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px"}}>
                                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[1].link); window.scrollTo(0, 0)}} className={"goto"}>
                                            <span style={firstPartStyle.city}>{HomeJson.sarreguemines+" "+HomeJson.sarregueminesPostalCode}</span>
                                            <span style={firstPartStyle.street}>{HomeJson.sarregueminesStreet}</span>
                                        </Box>
                                        <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarregueminesTel}`)}>
                                            <span style={firstPartStyle.postalCode}>{HomeJson.sarregueminesTel}</span>
                                        </Box>
                                    </Box>
                                    <FaCircle style={firstPartStyle.circlesMobile2}/>
                                    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px"}}>
                                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[2].link); window.scrollTo(0, 0)}} className={"goto"}>
                                            <span style={firstPartStyle.city}>{HomeJson.metz+" "+HomeJson.metzPostalCode}</span>
                                            <span style={firstPartStyle.street}>{HomeJson.metzStreet}</span>
                                        </Box>
                                        <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.metzTel}`)}>
                                            <span style={firstPartStyle.postalCode}>{HomeJson.metzTel}</span>
                                        </Box>
                                    </Box>
                                </Box>
                                :
                                <Box ref={section1El4} className={inViewSection1El4 ? "fadeDisplayAnim2" : "noOpacity"} style={{display: "flex", justifyContent: "space-evenly", width: "100%"}}>
                                    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px"}}>
                                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[0].link); window.scrollTo(0, 0)}} className={"goto"}>
                                            <span style={firstPartStyle.city}>{HomeJson.sarralbe+" "+HomeJson.sarralbePostalCode}</span>
                                            <span style={firstPartStyle.street}>{HomeJson.sarralbeStreet}</span>
                                        </Box>
                                        <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarralbeTel}`)}>
                                            <span style={firstPartStyle.postalCode}>{HomeJson.sarralbeTel}</span>
                                        </Box>
                                    </Box>
                                    <FaCircle style={firstPartStyle.circles}/>
                                    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px"}}>
                                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[1].link); window.scrollTo(0, 0)}} className={"goto"}>
                                            <span style={firstPartStyle.city}>{HomeJson.sarreguemines+" "+HomeJson.sarregueminesPostalCode}</span>
                                            <span style={firstPartStyle.street}>{HomeJson.sarregueminesStreet}</span>
                                        </Box>
                                        <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarregueminesTel}`)}>
                                            <span style={firstPartStyle.postalCode}>{HomeJson.sarregueminesTel}</span>
                                        </Box>
                                    </Box>
                                    <FaCircle style={firstPartStyle.circles}/>
                                    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px"}}>
                                        <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[2].link); window.scrollTo(0, 0)}} className={"goto"}>
                                            <span style={firstPartStyle.city}>{HomeJson.metz+" "+HomeJson.metzPostalCode}</span>
                                            <span style={firstPartStyle.street}>{HomeJson.metzStreet}</span>
                                        </Box>
                                        <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.metzTel}`)}>
                                            <span style={firstPartStyle.postalCode}>{HomeJson.metzTel}</span>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Second part */}
            <Box style={{display: "flex", justifyContent: "center"}}>
                <Box style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginTop: "clamp(80px, 5vw, 200px)"}}>
                    <h2 ref={section2El1} className={inViewSection2El1 ? "sectionTitle fadeDisplayAnim1" : "sectionTitle noOpacity"}>{HomeJson.availableLanguages[props.language].sections[1].title}</h2>
                    <Box style={{display: "flex", justifyContent: "center", width: "100%", flexWrap: "wrap"}}>
                        {HeaderJson.availableLanguages[props.language]["navigation"][1].dropdown.map((item: {name: string, link: string, videoLink: string}, index: number) => {
                            return (
                                <Box ref={eval("section2El"+(index+1))} className={eval("inViewSection2El"+(index+1)) ? "videoHoverBox leftToRightDisplayAnim"+(index+1) : "videoHoverBox noOpacity"} key={index} onMouseEnter={() => {setVideoBoxHover(index)}} onMouseLeave={() => {setVideoBoxHover(-1)}} style={{width: "clamp(250px, 30vw, 1000px)", opacity: 0}} onClick={() => {navigate(item.link); window.scrollTo(0, 0)}}>
                                    <span style={{fontFamily: "Muli-Light", paddingTop: "10px", paddingBottom: "10px", fontSize: "var(--BigFontSize)", textAlign: "center", marginTop: "auto", marginBottom: "auto"}}>{item.name}</span>
                                    <HoverVideoPlayer
                                        style={{width: "100%", marginTop: "auto", borderTop: "3px solid var(--Green)"}}
                                        videoSrc={require("./../"+item.videoLink+".mp4")}
                                        hoverOverlay={
                                            <Box style={{width: "100%"}}/>
                                        }
                                        pausedOverlay={
                                            <Box style={{width: "100%"}}/>
                                        }
                                        hoverOverlayWrapperStyle={{
                                            backgroundColor: "rgba(71, 77, 94, 0.35)",
                                            boxShadow: "inset 0 0 25px rgba(0, 0, 0, 0.5)",
                                            borderRadius: "var(--ServicesRadius2)",
                                            zIndex: "0"
                                        }}
                                        pausedOverlayWrapperStyle={{
                                            backgroundColor: "rgba(71, 77, 94, 0.35)",
                                            boxShadow: "inset 0 0 25px rgba(0, 0, 0, 0.5)",
                                            borderRadius: "var(--ServicesRadius2)",
                                            zIndex: "0"
                                        }}
                                        videoStyle={{
                                            borderRadius: "var(--ServicesRadius2)",
                                            width: "100%"
                                        }}
                                        overlayTransitionDuration={0}
                                        restartOnPaused={true}
                                        loop={isMobile}
                                        focused={isMobile || index !== -1 && index === videoBoxHover}
                                        disableDefaultEventHandling={true}
                                    />
                                </Box>
                            )})}
                    </Box>
                </Box>
            </Box>

            {/* Third part */}
            <Box style={{backgroundImage: `url(${SecondSvg})`, height: "1080px", marginTop: "80px"}}>
                <Box style={{width: "100%", height: "100%"}}>
                    <Box style={{display: "flex", flexDirection: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "row" : "column", height: "100%", alignItems: "center", justifyContent: "space-evenly"}}>
                        <Box style={{display: "flex", flexDirection: "column", textAlign: (props.windowWidth > ConstantsJson.MobileScreenEndWith ? "end" : "center"), color: "var(--White)", width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "45%" : "100%", marginTop: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "-60px" : "", marginRight: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "6%" : "0"}}>
                            <Box ref={section3El2} className={inViewSection3El2 ? "fadeDisplayAnim1" : "noOpacity"} style={{marginBottom: (props.windowWidth <= ConstantsJson.MobileScreenEndWith ? "clamp(2px, 6.5vw, 70px)" : "clamp(15px, 10vw, 80px)")}}>
                                <h2 className={"sectionTitleWhite"} style={{color: "var(--White)"}}>{HomeJson.availableLanguages[props.language].sections[2].title}</h2>
                            </Box>
                            <Box ref={section3El3} className={inViewSection3El3 ? "leftToRightDisplayAnim2 goto" : "noOpacity goto"} style={{display: "flex", marginLeft: "auto", marginRight: (props.windowWidth > ConstantsJson.MobileScreenEndWith ? "" : "auto"), fontSize: "var(--BigFontSize)", color: "var(--White)"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[0].link); window.scrollTo(0, 0)}}>
                                <span style={{fontFamily: "Muli-ExtraLight"}}>{HomeJson.availableLanguages[props.language].sections[2].headOffice}&nbsp;</span>
                                <span style={{fontFamily: "Muli-Light"}}>{HomeJson.sarralbeStreet}&nbsp;</span>
                                <span>-&nbsp;{HomeJson.sarralbePostalCode}&nbsp;</span>
                                <span style={{fontFamily: "Muli-Bold"}}>{HomeJson.sarralbe}</span>
                            </Box>
                            <Box ref={section3El4} className={inViewSection3El4 ? "leftToRightDisplayAnim2 goto" : "noOpacity goto"} onClick={() => window.location.assign(`mailto:${HomeJson.mail}`)} style={{fontSize: "var(--BigFontSize)", color: "var(--White)"}}>
                                <MailIcon size={Number("var(--SmallFontSize)")} style={{verticalAlign: "middle"}}/>
                                <span style={{fontSize: "var(--BigFontSize)", justifySelf: "center"}}>&nbsp;{HomeJson.mail}</span>
                            </Box>
                            <Box ref={section3El5} className={inViewSection3El5 ? "leftToRightDisplayAnim2 goto" : "noOpacity goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarralbeTel}`)} style={{marginBottom: (props.windowWidth <= ConstantsJson.MobileScreenEndWith ? "clamp(2px, 5vw, 70px)" : "clamp(2px, 10vw, 70px)"), color: "var(--White)"}}>
                                <TelIcon size={Number("var(--BigFontSize)")} style={{verticalAlign: "middle"}}/>
                                <span style={{fontSize: "var(--BigFontSize)"}}>&nbsp;{HomeJson.sarralbeTel}</span>
                            </Box>
                            <Box ref={section3El6} className={inViewSection3El6 ? "leftToRightDisplayAnim3 goto" : "noOpacity goto"} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[1].link); window.scrollTo(0, 0)}} style={{display: "flex", marginLeft: "auto", marginRight: (props.windowWidth > ConstantsJson.MobileScreenEndWith ? "" : "auto"), fontSize: "var(--BigFontSize)", color: "var(--White)"}}>
                                <span style={{fontFamily: "Muli-Light"}}>{HomeJson.sarregueminesStreet}&nbsp;</span>
                                <span>-&nbsp;{HomeJson.sarregueminesPostalCode}&nbsp;</span>
                                <span style={{fontFamily: "Muli-Bold"}}>{HomeJson.sarreguemines}</span>
                            </Box>
                            <Box ref={section3El7} className={inViewSection3El7 ? "leftToRightDisplayAnim3 goto" : "noOpacity goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarregueminesTel}`)} style={{marginBottom: (props.windowWidth <= ConstantsJson.MobileScreenEndWith ? "clamp(2px, 5vw, 70px)" : "clamp(2px, 10vw, 70px)"), color: "var(--White)"}}>
                                <TelIcon size={Number("var(--BigFontSize)")} style={{verticalAlign: "middle"}}/>
                                <span style={{fontSize: "var(--MidFontSize)"}}>&nbsp;{HomeJson.sarregueminesTel}</span>
                            </Box>
                            <Box ref={section3El8} className={inViewSection3El8 ? "leftToRightDisplayAnim4 goto" : "noOpacity goto"} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[2].link); window.scrollTo(0, 0)}} style={{display: "flex", marginLeft: "auto", marginRight: (props.windowWidth > ConstantsJson.MobileScreenEndWith ? "" : "auto"), fontSize: "var(--BigFontSize)", color: "var(--White)"}}>
                                <span style={{fontFamily: "Muli-Light"}}>{HomeJson.metzStreet}&nbsp;</span>
                                <span>-&nbsp;{HomeJson.metzPostalCode}&nbsp;</span>
                                <span style={{fontFamily: "Muli-Bold"}}>{HomeJson.metz}</span>
                            </Box>
                            <Box ref={section3El9} className={inViewSection3El9 ? "leftToRightDisplayAnim4 goto" : "noOpacity goto"} style={{color: "var(--White)"}} onClick={() => window.location.assign(`tel:${HomeJson.metzTel}`)}>
                                <TelIcon size={Number("var(--BigFontSize)")} style={{verticalAlign: "middle"}}/>
                                <span style={{fontSize: "var(--MidFontSize)"}}>&nbsp;{HomeJson.metzTel}</span>
                            </Box>
                        </Box>
                        {props.windowWidth > ConstantsJson.MobileScreenEndWith ?
                            <Box ref={section3El1} className={inViewSection3El1 ? "notepadDisplayAnim" : "noOpacity"} style={{opacity: 0, marginLeft: "auto", marginRight: "auto"}}>
                                <img loading={"lazy"} className={"notepadImage"} src={require("../assets/picturesAndVideos/home/homePagePictures/notepad"+props.language+".webp")} alt={"notepad"} style={{width: "clamp(333px, 45vw, 750px)", height: "clamp(390px, 50vw, 897px)", pointerEvents: "none"}}/>
                            </Box> :
                            <Box ref={section3El1} className={inViewSection3El1 ? "notepadDisplayAnim" : "noOpacity"} style={{opacity: 0, marginTop: "-100px", marginLeft: "auto", marginRight: "auto"}}>
                                <img loading={"lazy"} className={"notepadImage"} src={require("../assets/picturesAndVideos/home/homePagePictures/notepad"+props.language+"Mob.webp")} alt={"notepad"} style={{width: "clamp(333px, 40vw, 750px)", height: "clamp(390px, 50vw, 780px)", pointerEvents: "none"}}/>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>

            {/* Fourth part */}
            <Box style={{marginTop: "calc(5px + 2vw)", marginBottom: "80px"}}>
                <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <h2 ref={section4El1} className={inViewSection4El1 ? "sectionTitle fadeDisplayAnim1" : "sectionTitle noOpacity"}>{HomeJson.availableLanguages[props.language].sections[3].title}</h2>
                </Box>
                <Box className={"qualitiesContainer"} style={{width: props.windowWidth > (ConstantsJson.MiddleScreenStartWidth - 50) ? "70%" : "100%", marginLeft: "auto", marginRight: "auto"}}>
                    {qualities && qualities.map((item, index) => {
                        return (
                            <Box ref={eval("section4El"+(index+1))} className={eval("inViewSection4El"+(index+1)) ? "qualities leftToRightDisplayAnim"+(index+1) : "qualities noOpacity"} key={index} style={{margin: "clamp(25px, 1vw, 50px)", maxWidth: "200px", minWidth: "120px", marginLeft: "auto", marginRight: "auto"}}>
                                <img loading={"lazy"} src={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("./../"+item.pictureLink+".webp") : require("./../"+item.pictureLink+"Mob.webp")} alt={"Quality"} style={{width: "50%", alignSelf: "center", marginBottom: "10px", pointerEvents: "none"}}/>
                                <span style={{fontFamily: "Muli-SemiBold", fontSize: "var(--MidFontSize)", textAlign: "center"}}>{(item.pictureLink.search("hourglass") !== -1 ? yearsOfExperience+" " : "")+item.text}</span>
                            </Box>
                        )
                    })}
                </Box>
            </Box>

            {/* Fifth part */}
            <Box className={"backgroundPictures"} style={{backgroundImage: props.windowWidth > ConstantsJson.MobileScreenEndWith ? `url(${ThirdSvgPicture})` : `url(${ThirdSvgPictureMob})`, height: "505px"}}>
                <Box style={{backgroundImage: `url(${ThirdSvg})`, height: "505px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Box style={{width: "90%", color: "var(--White)", fontFamily: "Muli-Light", fontSize: "var(--BigFontSize)", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>
                        <span ref={section5El1} className={inViewSection5El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{marginBottom: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "5px" : "15px"}}>{HomeJson.availableLanguages[props.language].sections[4].text1}</span>
                        <span ref={section5El2} className={inViewSection5El2 ? "fadeDisplayAnim2" : "noOpacity"} style={{marginBottom: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "5px" : "15px"}}>{HomeJson.availableLanguages[props.language].sections[4].text2}</span>
                        <span ref={section5El3} className={inViewSection5El3 ? "fadeDisplayAnim3" : "noOpacity"}>{HomeJson.availableLanguages[props.language].sections[4].text3}</span>
                    </Box>
                </Box>
            </Box>

            {/* Sixth part */}
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "100px"}}>
                <h2 ref={section6El1} className={inViewSection6El1 ? "sectionTitle fadeDisplayAnim1" : "noOpacity sectionTitle"}>{HomeJson.availableLanguages[props.language].sections[5].title}</h2>
                <Box style={{display: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "flex" : ""}}>
                    <Box ref={section6El2} className={inViewSection6El2 ? "leftToRightDisplayAnim2" : "noOpacity"}>
                        <ButtonSelected
                            defaultStyle={{
                                width: "200px",
                                fontSize: "var(--MidFontSize)",
                                margin: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0 10px 0 0" : "0 0 10px 0",
                                leftIcon: <PositionIcon size={16}/>,
                                rightIcon: <CancelIcon size={16}/>,
                                onClick: () => {setPreciseLocalisation(`, ${HomeJson.sarralbe}`)},
                                resetOnClick: () => {setPreciseLocalisation("")},
                                selected: preciseLocalisation === `, ${HomeJson.sarralbe}`,
                                text: HomeJson.sarralbe
                            }}
                        />
                    </Box>
                    <Box ref={section6El3} className={inViewSection6El3 ? "leftToRightDisplayAnim3" : "noOpacity"}>
                        <ButtonSelected
                            defaultStyle={{
                                width: "200px",
                                fontSize: "var(--MidFontSize)",
                                leftIcon: <PositionIcon size={16}/>,
                                rightIcon: <CancelIcon size={16}/>,
                                onClick: () => {setPreciseLocalisation(`, ${HomeJson.sarreguemines}`)},
                                resetOnClick: () => {setPreciseLocalisation("")},
                                selected: preciseLocalisation === `, ${HomeJson.sarreguemines}`,
                                text: HomeJson.sarreguemines
                            }}
                        />
                    </Box>
                    <Box ref={section6El4} className={inViewSection6El4 ? "leftToRightDisplayAnim4" : "noOpacity"}>
                        <ButtonSelected
                            defaultStyle={{
                                width: "200px",
                                fontSize: "var(--MidFontSize)",
                                margin: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0 0 0 10px" : "10px 0 0 0",
                                leftIcon: <PositionIcon size={16}/>,
                                rightIcon: <CancelIcon size={16}/>,
                                onClick: () => {setPreciseLocalisation(`, ${HomeJson.metz}`)},
                                resetOnClick: () => {setPreciseLocalisation("")},
                                selected: preciseLocalisation === `, ${HomeJson.metz}`,
                                text: HomeJson.metz
                            }}
                        />
                    </Box>
                </Box>
                <iframe
                    ref={section6El5}
                    className={inViewSection6El5 ? "mapDisplayAnim" : "noOpacity"}
                    title={"map"}
                    width="90%"
                    height={props.windowHeight - (props.windowWidth >= 1420 ? 220 : 200)}
                    style={{border: "4px solid var(--Grey)", borderRadius: "var(--MapRadius)", marginTop: "20px", marginBottom: "100px", maxHeight: "1080px", minHeight: "400px"}}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyCHrlOWH6CaBe5GRfwyJSHNIjM38XtcR48&q=societe+wollenschneider+${preciseLocalisation}&language=${mapLanguage[props.language]}`}>
                </iframe>
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default Home;
import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/Home";
import FootReport from "./pages/FootReport";
import Insoles from "./pages/Insoles";
import Shoes from "./pages/Shoes";
import FootLifter from "./pages/FootLifter";
import ShoeRepair from "./pages/ShoeRepair";
import Sarralbe from "./pages/Sarralbe";
import Sarreguemines from "./pages/Sarreguemines";
import Metz from "./pages/Metz";
import Pictures from "./pages/Pictures";
import Videos from "./pages/Videos";
import Articles from "./pages/Articles";
import Historical from "./pages/Historical";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import LegalNotice from "./pages/LegalNotice";
import PrivateLifeProtection from "./pages/PrivateLifeProtection";
import {AnimatePresence} from "framer-motion";
import Pathologies from "./pages/Pathologies";
import VideoLoop from "./pages/VideoLoop";
import countapi from 'countapi-js';
import Counter from "./pages/Counter";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
    setLanguage: (language: number) => void;
    isSearching: boolean;
    setIsSearching: (isSearching: boolean) => void;
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    searchResult: {string: string, link: string}[]
}

const MyRoutes = (props: Props) => {
    const location = useLocation()

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/services/foot-report" element={<FootReport windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/services/custom-orthopedic-insoles" element={<Insoles windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/services/custom-orthopedic-shoes" element={<Shoes windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/services/foot-lifter" element={<FootLifter windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/services/shoe-repair" element={<ShoeRepair windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/pathologies" element={<Pathologies windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/offices/sarralbe" element={<Sarralbe windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/offices/sarreguemines" element={<Sarreguemines windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/offices/metz" element={<Metz windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/resources/pictures" element={<Pictures windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/resources/videos" element={<Videos windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/resources/articles" element={<Articles windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/historical" element={<Historical windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/contact" element={<Contact windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/legalnotice" element={<LegalNotice windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/privatelifeprotection" element={<PrivateLifeProtection windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/intro" element={<VideoLoop windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="/counter" element={<Counter windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>}/>
                <Route path="*" element={<NotFound windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language} isSearching={props.isSearching} setIsSearching={props.setIsSearching}  searchValue={props.searchValue} setSearchValue={props.setSearchValue}/>}/>
            </Routes>
        </AnimatePresence>
    );
}

export default MyRoutes;

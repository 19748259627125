import React from "react";
import {Radio, Label, Box} from 'theme-ui'
import MyText from "./MyText";

interface Props {
    options: string[];
    value: number;
    setValue: (value: number) => void;
    onClick?: () => void;
    radioColor?: string;
    title?: string;
    style?: object;
    styleHovered?: object;
    styleLabel?: object;
    styleLabelHovered?: object;
    styleBox?: object;
    styleTitle?: object;
}

export const MyRadio = (props: Props) => {
    const [hovered, setHovered] = React.useState(-1);
    const [labelHovered, setLabelHovered] = React.useState(-1);

    return (
        <Box>
            <Box style={props.styleTitle}>
                {props.title}
            </Box>
            <Box style={props.styleBox}>
                {props.options.map((option, index) => (
                    <Label sx={{
                        width: "auto",
                        color: "myBlack",
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        fontFamily: "myRegular",
                        fontSize: "myBig",
                        "&:hover": {
                            color: "myGreen",
                        }
                    }}
                           style={labelHovered === index ? props.styleLabelHovered : props.styleLabel}
                           key={index} onMouseEnter={() => {setLabelHovered(index)}} onMouseLeave={() => {setLabelHovered(-1)}}>
                        <Radio
                            sx={{
                                color: props.radioColor ? props.radioColor : "myBlack",
                                cursor: "pointer",
                                transition: "all 0.2s ease",
                            }}
                            checked={index === props.value}
                            style={hovered ? props.styleHovered : props.style}
                            name='radio' id={option+index} value={option} onMouseEnter={() => {setHovered(index)}} onMouseLeave={() => {setHovered(-1)}} onClick={props.onClick} onChange={() => {props.setValue(index)}}/>
                        {option}
                    </Label>
                ))}
            </Box>
        </Box>
    )
};

export default MyRadio;
import React from "react";
import { Button } from 'theme-ui'

interface Props {
    value: string
    onClick?: () => void;
    style?: object;
    styleHovered?: object;
    styleSelected?: object;
}

export const MyButtonSelect = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);
    const [selected, setSelected] = React.useState(false);

    const getCustomStyles = () => {
        if (hovered && !selected)
            return props.styleHovered;
        if (selected)
            return props.styleSelected;
        return props.style;
    }

    const handleClick = () => {
        setSelected(!selected);
        if (props.onClick)
            props.onClick();
    }

    return <Button
        sx={{
            color: "myWhite",
            fontFamily: "myRegular",
            background: selected ? "myDarkGreen" : "myGreen",
            padding: "8px 25px 8px 25px",
            transition: "all 0.2s ease",
            fontSize: "myMid",
            "&:hover":
                selected ?
                    {
                        border: "none",
                        cursor: "pointer"
                    }
                    :
                    {
                        border: "2px solid myLightGreen",
                        cursor: "pointer"
                    }
        }}
        style={getCustomStyles()}
        onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} onClick={handleClick}>
        {props.value}
    </Button>
};

export default MyButtonSelect;
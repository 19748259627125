import React from 'react';
import {Box} from "@mui/material";
import SarregueminesVideo from "../assets/picturesAndVideos/offices/sarreguemines.mp4"
import MyText from "../components/MyText";
import ConstantsJson from "../assets/jsons/constants.json"
import SarregueminesJson from "../assets/jsons/sarreguemines.json"
import {useInView} from "react-intersection-observer";
import "../assets/css/constants.css"
import "../assets/css/offices.css"
import MyTextSplit from "../components/MyTextSplit";
import {Flex} from "rebass";
import Footer from "../components/Footer";
import {FaPlay as PlayIcon} from "react-icons/fa"

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const Sarreguemines = (props: Props) => {
    const qualities = SarregueminesJson.availableLanguages[props.language].qualities
    const mapLanguage = [ "en", "fr", "de"]

    const [ref1, inViewRef1] = useInView({triggerOnce: true})
    const [ref2, inViewRef2] = useInView({triggerOnce: true})
    const [ref3, inViewRef3] = useInView({triggerOnce: true})
    const [ref4, inViewRef4] = useInView({triggerOnce: true})
    const [ref5, inViewRef5] = useInView({triggerOnce: true})

    const [ref1qualities, inViewRef1qualities] = useInView({triggerOnce: true})
    const [ref2qualities, inViewRef2qualities] = useInView({triggerOnce: true})
    const [ref3qualities, inViewRef3qualities] = useInView({triggerOnce: true})

    return (
        <Box ref={ref1}>
            <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <Box className={inViewRef1 ? "fadeDisplayAnim3" : "noOpacity"} style={{width: "100%", height: "600px", objectFit: "cover", boxShadow: "0 0 16px rgba(0, 0, 0, 0.4)"}}>
                    <video src={SarregueminesVideo} style={{width: "100%", height: "100%", objectFit: "cover"}} playsInline={true} muted={true} autoPlay={true} loop={true}/>
                </Box>
                <Box className={inViewRef1 ? "fadeDisplayAnim5" : "noOpacity"} style={{position: "absolute", top: "320px", textAlign: "center", marginLeft: "15px", marginRight: "15px"}}>
                    <MyText value={SarregueminesJson.availableLanguages[props.language].title} style={{color: "var(--White)", fontSize: "44px", fontFamily: "Muli-Bold", textShadow: "0 6px 4px rgba(0, 0, 0, 0.25)"}}/>
                </Box>

                <Box className={inViewRef1 ? "fadeDisplayAnim2" : "noOpacity"} style={{width: "90%",}}>
                    <MyText value={SarregueminesJson.availableLanguages[props.language].subtitle} style={{fontFamily: "Muli-Regular", fontSize: "var(--TitleFontSize)", color: "var(--Grey)", marginTop: "110px", textAlign: "center"}}/>
                    <MyTextSplit value={SarregueminesJson.availableLanguages[props.language].textsOne![0].textsplit} boxStyle={{marginTop: "20px", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "center" : "left"}} mid={true}/>
                    <MyTextSplit value={SarregueminesJson.availableLanguages[props.language].textsOne![1].textsplit} boxStyle={{marginTop: "5px", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "center" : "left"}} mid={true}/>
                </Box>

                <Flex marginTop={"100px"} flexDirection={props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "row" : "column"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                    <iframe
                        ref={ref2}
                        className={inViewRef2 ? props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "leftToRightDisplayAnim1" : "fadeDisplayAnim1" : "noOpacity"}
                        title={"map"}
                        width={props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "50%" : "90%"}
                        height={props.windowHeight - (props.windowWidth >= 1420 ? 360 : 340)}
                        style={{border: "4px solid var(--Grey)", borderRadius: "var(--MapRadius)", minHeight: "400px", maxHeight: "1080px", marginRight: "auto", marginLeft: "auto"}}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyCHrlOWH6CaBe5GRfwyJSHNIjM38XtcR48&q=wollenschneider-katzer+sarreguemines&language=${mapLanguage[props.language]}`}>
                    </iframe>
                    <Flex marginTop={props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "0" : "100px"} width={props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "40%" : "90%"} justifyContent={"center"} alignItems={"center"} marginRight={"auto"} marginLeft={"auto"} flexDirection={"column"}>
                        <Box className={"qualitiesContainer2"} style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                            {qualities && qualities.map((item, index) => {
                                return (
                                    <Box ref={eval("ref"+(index+1)+"qualities")} className={eval("inViewRef"+(index+1)+"qualities") ? "qualities leftToRightDisplayAnim"+(index+2) : "qualities"} key={index} style={{minWidth: "120px", marginLeft: "auto", marginRight: "auto"}}>
                                        <img src={require("./../"+item.pictureLink+".webp")} alt={"Quality"} style={{width: "50%", alignSelf: "center", marginBottom: "10px", pointerEvents: "none"}}/>
                                        <text style={{fontFamily: "Muli-SemiBold", fontSize: "var(--MidFontSize)", textAlign: "center"}}>{item.text}</text>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Flex flexDirection={"column"} width={props.windowWidth > ConstantsJson.MobileScreenEndWith ? "max-content" : "90%"} alignItems={"center"} justifyContent={"center"} ref={ref3} className={inViewRef3 ? "fadeDisplayAnim3" : "noOpacity"} style={{marginTop: "50px"}}>
                            <Box>
                                <MyText value={SarregueminesJson.availableLanguages[props.language].schedules} style={{textAlign: "center", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)", textDecoration: "underline"}}/>
                                <Flex marginTop={"20px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].mondayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].monday}/>
                                </Flex>
                                <Flex marginTop={"5px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].tuesdayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].tuesday}/>
                                </Flex>
                                <Flex marginTop={"5px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].wednesdayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].wednesday}/>
                                </Flex>
                                <Flex marginTop={"5px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].thursdayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].thursday}/>
                                </Flex>
                                <Flex marginTop={"5px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].fridayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].friday}/>
                                </Flex>
                                <Flex marginTop={"5px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].saturdayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].saturday}/>
                                </Flex>
                                <Flex marginTop={"5px"}>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].sundayTitle+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--BigFontSize)", minWidth: "110px"}}/>
                                    <MyText value={SarregueminesJson.availableLanguages[props.language].sunday}/>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
                <Box ref={ref4} className={inViewRef4 ? "fadeDisplayAnim2" : "noOpacity"} style={{width: "90%"}}>
                    <MyTextSplit value={SarregueminesJson.availableLanguages[props.language].textsTwo![0].textsplit} boxStyle={{marginTop: "110px", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "center" : "left"}} mid={true}/>
                    <MyText value={SarregueminesJson.availableLanguages[props.language].textsTwo![1].text} style={{fontSize: "var(--BigFontSize)", marginTop: "5px", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "center" : "left"}}/>
                </Box>
                <Flex flexDirection={"column"} width={"100%"} alignItems={"center"} justifyContent={"center"} marginTop={"100px"} paddingTop={"70px"} paddingBottom={"70px"} backgroundColor={"var(--InputGrey)"} style={{position: "relative", zIndex: "0"}}>
                    <Box ref={ref5} className={inViewRef5 ? "fadeDisplayAnim2" : "noOpacity"}>
                        <MyText value={SarregueminesJson.availableLanguages[props.language].videoTitle} style={{color: "var(--Grey)", textAlign: "center", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)", marginBottom: "30px"}}/>
                    </Box>
                    <video poster={props.windowWidth > ConstantsJson.MobileScreenEndWith ? require("../"+"assets/picturesAndVideos/offices/previewSarreguemines"+ ".webp") : require("../"+"assets/picturesAndVideos/offices/previewSarregueminesMob"+ ".webp")} src={require("../"+"assets/picturesAndVideos/offices/sarregueminesFull"+ ".mp4")} controls={true} style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "50%" : "90%", height: "50%", borderRadius: "var(--MapRadius)"}}/>
                    <PlayIcon className={inViewRef5 ? "fadeDisplayAnim5" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "-2%", top: "45%", zIndex: "-2"}}/>
                    <PlayIcon className={inViewRef5 ? "fadeDisplayAnim6" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "8%", top: "45%", zIndex: "-2"}}/>
                    <PlayIcon className={inViewRef5 ? "fadeDisplayAnim7" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "72%", top: "45%", zIndex: "-2"}}/>
                    <PlayIcon className={inViewRef5 ? "fadeDisplayAnim8" : "noOpacity"} color={"var(--Background)"} size={"20%"} style={{position: "absolute", left: "82%", top: "45%", zIndex: "-2"}}/>
                </Flex>
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language} svgChange={true}/>
        </Box>
    );
};

export default Sarreguemines;
import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import {Flex} from "rebass";
import MyText from "../components/MyText";
import MyImage from "../components/MyImage";
import {Link} from "theme-ui";
import ImageGallery from 'react-image-gallery';
import "../assets/css/imageGallery.css"
import ShoesJson from "../assets/jsons/shoes.json"
import ContantsJson from "../assets/jsons/constants.json"
import MyTextSplit from "../components/MyTextSplit";
import {Parallax} from "react-scroll-parallax";
import {useNavigate} from "react-router-dom";
import MyLink from "../components/MyLink";
import {useInView} from "react-intersection-observer";
import Footer from "../components/Footer";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const Shoes = (props: Props) => {
    const navigate = useNavigate()
    const yarsOfExperience = new Date().getFullYear() - 1909;

    const [section1El1, inViewSection1El1] = useInView({triggerOnce: true})
    const [section1El2, inViewSection1El2] = useInView({triggerOnce: true})
    const [section1El3, inViewSection1El3] = useInView({triggerOnce: true})

    const [section2El1, inViewSection2El1] = useInView({triggerOnce: true})
    const [section2El2, inViewSection2El2] = useInView({triggerOnce: true})
    const [section2El3, inViewSection2El3] = useInView({triggerOnce: true})
    const [section2El4, inViewSection2El4] = useInView({triggerOnce: true})
    const [section2El5, inViewSection2El5] = useInView({triggerOnce: true})

    const [section3El1, inViewSection3El1] = useInView({triggerOnce: true})
    const [section3El2, inViewSection3El2] = useInView({triggerOnce: true})
    const [section3El3, inViewSection3El3] = useInView({triggerOnce: true})
    const [section3El4, inViewSection3El4] = useInView({triggerOnce: true})
    const [section3El5, inViewSection3El5] = useInView({triggerOnce: true})
    const [section3El6, inViewSection3El6] = useInView({triggerOnce: true})
    const [section3El7, inViewSection3El7] = useInView({triggerOnce: true})

    const [section4El1, inViewSection4El1] = useInView({triggerOnce: true})
    const [section4El2, inViewSection4El2] = useInView({triggerOnce: true})

    const [section5El1, inViewSection5El1] = useInView({triggerOnce: true})
    const [section5El2, inViewSection5El2] = useInView({triggerOnce: true})
    const [section5El3, inViewSection5El3] = useInView({triggerOnce: true})
    const [section5El4, inViewSection5El4] = useInView({triggerOnce: true})
    const [section5El5, inViewSection5El5] = useInView({triggerOnce: true})

    const [section6El1, inViewSection6El1] = useInView({triggerOnce: true})
    const [section6El2, inViewSection6El2] = useInView({triggerOnce: true})

    const [section7El1, inViewSection7El1] = useInView({triggerOnce: true})

    return (
        <Box>
            <PageTitle title={ShoesJson.availableLanguages[props.language].sections[0].pageTitle} backgroundPicture={ShoesJson.availableLanguages[props.language].sections[0].backgroundPicture} backgroundPositionY={"bottom"}/>
            <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} marginTop={"90px"}>
                <Box ref={section1El1} className={inViewSection1El1 ? "fadeDisplayAnim2" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: "left"}}>
                    <MyText value={ShoesJson.availableLanguages[props.language].sections[1].upTitle} style={{fontFamily: "Muli-Regular", fontSize: "var(--TitleFontSize)", color: "var(--Grey)"}}/>
                </Box>
                <Box ref={section1El2} className={inViewSection1El2 ? "fadeDisplayAnim3" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "left" : "center", marginBottom: "20px"}}>
                    <MyText as={"h1"} value={ShoesJson.availableLanguages[props.language].sections[1].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                </Box>
                <Box ref={section1El3} className={inViewSection1El3 ? "fadeDisplayAnim4" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "center" : "left"}}>
                    <MyText value={ShoesJson.availableLanguages[props.language].sections[1].text1+""+yarsOfExperience+ShoesJson.availableLanguages[props.language].sections[1].text2} style={{fontSize: "var(--BigFontSize)", fontFamily: "Muli-Light"}}/>
                </Box>
            </Flex>
            <Flex flexDirection={props.windowWidth > ContantsJson.MobileScreenEndWith ? "row" : "column"} justifyContent={"center"} alignItems={"center"} marginTop={"130px"}>
                <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ContantsJson.MobileScreenEndWith ? "50%": "90%"} marginLeft={"60px"} marginRight={"60px"}>
                    <Box ref={section2El1} className={inViewSection2El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "left" : "center", width: "100%", marginBottom: "20px"}}>
                        <MyText as={"h1"} value={ShoesJson.availableLanguages[props.language].sections[2].title} style={{width: "100%", fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                    </Box>
                    <Box ref={section2El3} className={inViewSection2El3 ? "fadeDisplayAnim3" : "noOpacity"}>
                        <MyText value={ShoesJson.availableLanguages[props.language].sections[2].texts![0].text} style={{textAlign: "left", fontFamily: "Muli-Light"}}/>
                    </Box>
                    <Box ref={section2El4} className={inViewSection2El4 ? "fadeDisplayAnim4" : "noOpacity"} style={{marginTop: "5px", marginBottom: "5px"}}>
                        <MyText value={ShoesJson.availableLanguages[props.language].sections[2].texts![1].text} style={{textAlign: "left", fontFamily: "Muli-Light"}}/>
                    </Box>
                    <Box ref={section2El5} className={inViewSection2El5 ? "fadeDisplayAnim5" : "noOpacity"} style={{textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={ShoesJson.availableLanguages[props.language].sections[2].texts![2].textsplit}/>
                    </Box>
                </Flex>
                <Box ref={section2El2} className={inViewSection2El2 ? "rightToLeftDisplayAnim2" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "45%" : "90%", marginRight: props.windowWidth > ContantsJson.MobileScreenEndWith ? "60px" : "auto", marginLeft: props.windowWidth > ContantsJson.MobileScreenEndWith ? "0" : "auto", marginTop: props.windowWidth > ContantsJson.MobileScreenEndWith ? "0": "50px"}}>
                    <Parallax speed={10}>
                        <MyImage src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+ShoesJson.availableLanguages[props.language].sections[2].pictureLink+".webp") : require("../"+ShoesJson.availableLanguages[props.language].sections[2].pictureLinkMob+".webp")} style={{boxShadow: "0 0 16px rgba(0, 0, 0, 0.2)", position: "relative", bottom: "-40px"}}/>
                    </Parallax>
                </Box>
            </Flex>
            <Flex flexDirection={props.windowWidth > ContantsJson.MobileScreenEndWith ? "row" : "column"}  justifyContent={"center"} alignItems={"center"} marginTop={"100px"}>
                {props.windowWidth > ContantsJson.MobileScreenEndWith && <Box ref={section3El2} className={inViewSection3El2 ? "leftToRightDisplayAnim2" : "noOpacity"} style={{width: "45%", marginLeft: "60px"}}>
                    <Parallax speed={10}>
                        <MyImage src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+ShoesJson.availableLanguages[props.language].sections[3].pictureLink+".webp") : require("../"+ShoesJson.availableLanguages[props.language].sections[3].pictureLinkMob+".webp")} style={{boxShadow: "0 0 16px rgba(0, 0, 0, 0.2)", position: "relative", bottom: "-40px"}}/>
                    </Parallax>
                </Box>}
                <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ContantsJson.MobileScreenEndWith ? "50%" : "90%"} marginLeft={"60px"} marginRight={"60px"}>
                    <Box ref={section3El1} className={inViewSection3El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "left" : "center", width: "100%", marginBottom: "20px"}}>
                        <MyText as={"h1"} value={ShoesJson.availableLanguages[props.language].sections[3].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                    </Box>
                    <Box ref={section3El3} className={inViewSection3El3 ? "fadeDisplayAnim3" : "noOpacity"}>
                        <MyText value={ShoesJson.availableLanguages[props.language].sections[3].texts![0].text} style={{fontFamily: "Muli-Light"}}/>
                    </Box>
                    <Box ref={section3El4} className={inViewSection3El4 ? "fadeDisplayAnim3" : "noOpacity"} style={{textAlign: "left", width: "100%"}}>
                        <MyTextSplit boxStyle={{marginTop: "5px", marginBottom: "5px"}} value={ShoesJson.availableLanguages[props.language].sections[3].texts![1].textsplit}/>
                    </Box>
                    <Box ref={section3El5} className={inViewSection3El5 ? "fadeDisplayAnim3" : "noOpacity"} style={{textAlign: "left", width: "100%", marginTop: "5px", marginBottom: "5px"}}>
                        <MyText value={ShoesJson.availableLanguages[props.language].sections[3].texts![2].text} style={{fontFamily: "Muli-Light"}}/>
                    </Box>
                    <Box ref={section3El6} className={inViewSection3El6 ? "fadeDisplayAnim3" : "noOpacity"}>
                        <MyTextSplit value={ShoesJson.availableLanguages[props.language].sections[3].texts![3].textsplit}/>
                    </Box>
                    <Box ref={section3El7} className={inViewSection3El7 ? "fadeDisplayAnim3" : "noOpacity"} style={{textAlign: "left", width: "100%"}}>
                        <MyTextSplit boxStyle={{marginTop: "5px"}} value={ShoesJson.availableLanguages[props.language].sections[3].texts![4].textsplit}/>
                        <MyTextSplit boxStyle={{marginTop: "5px"}} value={ShoesJson.availableLanguages[props.language].sections[3].texts![5].textsplit}/>
                    </Box>
                </Flex>
                {props.windowWidth < ContantsJson.MobileScreenEndWith && <Box ref={section3El2} className={inViewSection3El2 ? "leftToRightDisplayAnim2" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "45%" : "90%", marginRight: props.windowWidth > ContantsJson.MobileScreenEndWith ? "60px" : "auto", marginLeft: props.windowWidth > ContantsJson.MobileScreenEndWith ? "0" : "auto", marginTop: props.windowWidth > ContantsJson.MobileScreenEndWith ? "0": "50px"}}>
                    <Parallax speed={10}>
                        <MyImage src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+ShoesJson.availableLanguages[props.language].sections[3].pictureLink+".webp") : require("../"+ShoesJson.availableLanguages[props.language].sections[3].pictureLinkMob+".webp")} style={{boxShadow: "0 0 16px rgba(0, 0, 0, 0.2)", position: "relative", bottom: "-40px"}}/>
                    </Parallax>
                </Box>}
            </Flex>
            <Flex alignItems={"center"} ml={"auto"} mr={"auto"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%"} marginTop={"120px"}>
                <Box>
                    <Box ref={section4El1} className={inViewSection4El1 ? "fadeDisplayAnim1" : "noOpacity"}>
                        <MyTextSplit value={ShoesJson.availableLanguages[props.language].sections[4].texts![0].textsplit} big={true}/>
                    </Box>
                    <Box ref={section4El2} className={inViewSection4El2 ? "fadeDisplayAnim2" : "noOpacity"}>
                        <MyTextSplit boxStyle={{marginTop: "10px"}} value={ShoesJson.availableLanguages[props.language].sections[4].texts![1].textsplit} big={true}/>
                    </Box>
                </Box>
            </Flex>
            <Flex flexDirection={props.windowWidth > ContantsJson.MobileScreenEndWith ? "row" : "column"} backgroundColor={"var(--LightGreen)"} justifyContent={"center"} marginTop={"100px"}>
                <Flex ref={section5El2} className={inViewSection5El2 ? "leftToRightDisplayAnim2" : "noOpacity"} alignItems={"center"} justifyContent={"end"} marginRight={props.windowWidth > ContantsJson.MobileScreenEndWith ? "60px" : "auto"} marginLeft={props.windowWidth > ContantsJson.MobileScreenEndWith ? "0" : "auto"} marginTop={props.windowWidth > ContantsJson.MobileScreenEndWith ? "0" : "60px"}>
                    <img loading={"lazy"} className={"infiniteScaleAnim"} src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+ShoesJson.availableLanguages[props.language].sections[5].pictureLink+".webp") : require("../"+ShoesJson.availableLanguages[props.language].sections[5].pictureLinkMob+".webp")} style={{width: "75%", marginLeft: "auto", marginRight: "auto"}}/>
                </Flex>
                <Flex flexDirection={"column"} width={props.windowWidth > ContantsJson.MobileScreenEndWith ? "70%" : "90%"} paddingTop={props.windowWidth > ContantsJson.MobileScreenEndWith ? "150px" : "60px"} paddingBottom={"150px"} marginLeft={props.windowWidth > ContantsJson.MobileScreenEndWith ? "0": "auto"} marginRight={props.windowWidth > ContantsJson.MobileScreenEndWith ? "0" : "auto"}>
                    <Box ref={section5El1} className={inViewSection5El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{width: "100%", textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "left" : "center", marginBottom: "40px"}}>
                        <MyText as={"h1"} value={ShoesJson.availableLanguages[props.language].sections[5].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)", color: "var(--Green)"}}/>
                    </Box>
                    <Box ref={section5El3} className={inViewSection5El3 ? "fadeDisplayAnim3" : "noOpacity"}>
                        <MyTextSplit value={ShoesJson.availableLanguages[props.language].sections[5].texts![0].textsplit}/>
                    </Box>
                    <Box ref={section5El4} className={inViewSection5El4 ? "fadeDisplayAnim3" : "noOpacity"} style={{marginTop: "15px", marginLeft: "25px"}}>
                        <Link style={{fontFamily: "Muli-Bold", whiteSpace: "pre-wrap", fontSize: "var(--MidFontSize)"}}>{ShoesJson.availableLanguages[props.language].sections[5].texts![1].text}</Link>
                    </Box>
                    <Box ref={section5El5} className={inViewSection5El5 ? "fadeDisplayAnim3" : "noOpacity"} style={{marginTop: "15px", marginLeft: "25px"}}>
                        <Link style={{fontFamily: "Muli-Bold", whiteSpace: "pre-wrap", fontSize: "var(--MidFontSize)"}}>{ShoesJson.availableLanguages[props.language].sections[5].texts![2].text}</Link>
                    </Box>
                </Flex>
            </Flex>
            <Flex flexDirection={"column"} alignItems={"center"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? props.windowWidth > ContantsJson.MiddleScreenStartWidth ? "50%" : "75%" : "100%", marginLeft: "auto", marginRight: "auto", marginTop: "100px"}}>
                <Box ref={section6El1} className={inViewSection6El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{width: "100%", textAlign: "center", marginBottom: "60px"}}>
                    <MyText as={"h1"} value={ShoesJson.availableLanguages[props.language].sections[6].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                </Box>
                <Box ref={section6El2} className={inViewSection6El2 ? "opacityDisplayAnim2" : "noOpacity"}>
                    <ImageGallery items={ShoesJson.availableLanguages[props.language].sections[6].picturesLinks!.map((item) => (
                        {original: props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+item+".webp") : require("../"+item+"Mob.webp")}
                    ))} showBullets={true} showThumbnails={false} autoPlay={true} lazyLoad={true} showFullscreenButton={false}/>
                </Box>
            </Flex>
            <Box style={{marginTop: "70px", marginBottom: "70px"}}>
                <Flex ref={section7El1} className={inViewSection7El1 ? "opacityDisplayAnim1" : "noOpacity"} alignItems={"center"} justifyContent={"center"} style={{marginLeft: "auto", marginRight: "auto", width: "95%", paddingTop: "50px", paddingBottom: "50px"}}>
                    <MyImage src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+ShoesJson.availableLanguages[props.language].sections[7].pictureLink+".webp") : require("../"+ShoesJson.availableLanguages[props.language].sections[7].pictureLinkMob+".webp")}/>
                </Flex>
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default Shoes;
import React from 'react';
import {Box} from "@mui/material";
import "../assets/css/notFound.css";
import MyImage from "../components/MyImage";
import RobotPicture from "../assets/picturesAndVideos/notfound/robot.webp"
import MyButton from "../components/MyButton";
import {useNavigate} from "react-router-dom";
import MyText from "../components/MyText";
import {useInView} from "react-intersection-observer";
import Footer from "../components/Footer";
import PageTitle from "../components/PageTitle";

interface Props {
    language: number;
    isSearching: boolean;
    setIsSearching: (isSearching: boolean) => void;
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    windowWidth: number;
    windowHeight: number;
}

const NotFound = (props: Props) => {
    const navigate = useNavigate()

    const [ref1, inViewRef1] = useInView({triggerOnce: true})
    const [ref2, inViewRef2] = useInView({triggerOnce: true})
    const [ref3, inViewRef3] = useInView({triggerOnce: true})

    return (
        <Box>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "100px"}}>
                <Box ref={ref1} className={inViewRef1 ? "fadeDisplayAnim1" : ""} style={{display :"flex", justifyContent: "center"}}>
                    <Box className={"infiniteScaleBounceAnim"} style={{display :"flex", justifyContent: "center"}}>
                        <MyImage src={RobotPicture} style={{width: "15%", minWidth: "200px", marginTop: "100px"}}/>
                    </Box>
                </Box>
                <Box ref={ref1} className={inViewRef1 ? "fadeDisplayAnim1" : ""} style={{display :"flex", justifyContent: "center"}}>
                    <MyText value={"Error 404"} style={{fontFamily: "Muli-ExtraLight", color: "var(--Grey)"}}/>
                </Box>
                <Box ref={ref2} className={inViewRef2 ? "fadeDisplayAnim2" : ""} style={{display: "flex", flexDirection: props.windowWidth > 1250 ? "row" : "column", alignItems: "center", width: "100%", marginTop: "30px"}}>
                    <Box style={{width: props.windowWidth > 1250 ? "35vw" : ""}}>
                        <h1 style={{fontSize: "clamp(16px, 1.2vw, 22px)", textAlign: "center"}}>La page que vous recherchez n'existe pas.</h1>
                    </Box>
                    <Box style={{height: props.windowWidth > 1250 ? "5vw" : "0.2vw", width: props.windowWidth > 1250 ? "0.2vw" : "5vw", background: "var(--Black)", borderRadius: "var(--Radius)", marginTop: props.windowWidth > 1250 ? "" : "10px", marginBottom: props.windowWidth > 1250 ? "" : "10px"}}/>
                    <Box style={{width: props.windowWidth > 1250 ? "35vw" : ""}}>
                        <h1 style={{fontSize: "clamp(16px, 1.2vw, 22px)", textAlign: "center"}}>The page you’re looking for does not exist.</h1>
                    </Box>
                    <Box style={{height: props.windowWidth > 1250 ? "5vw" : "0.2vw", width: props.windowWidth > 1250 ? "0.2vw" : "5vw", background: "var(--Black)", borderRadius: "var(--Radius)", marginTop: props.windowWidth > 1250 ? "" : "10px", marginBottom: props.windowWidth > 1250 ? "" : "10px"}}/>
                    <Box style={{width: props.windowWidth > 1250 ? "35vw" : ""}}>
                        <h1 style={{fontSize: "clamp(16px, 1.2vw, 22px)", textAlign: "center"}}>Die gesuchte Seite existiert leider nicht.</h1>
                    </Box>
                </Box>
                <Box ref={ref3} className={inViewRef3 ? "fadeDisplayAnim3" : ""} style={{display :"flex", justifyContent: "center"}}>
                    {props.language === 0 && <MyButton value={"Back to home"} onClick={() => {navigate("/")}} style={{marginTop: "75px"}} styleHovered={{marginTop: "75px"}}/>}
                    {props.language === 1 && <MyButton value={"Retourner à l'accueil"} onClick={() => {navigate("/")}} style={{marginTop: "75px"}} styleHovered={{marginTop: "75px"}}/>}
                    {props.language === 2 && <MyButton value={"Zurück zur Startseite"} onClick={() => {navigate("/")}} style={{marginTop: "75px"}} styleHovered={{marginTop: "75px"}}/>}
                </Box>
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default NotFound;
import React from "react";
import {Checkbox, Label, Box} from 'theme-ui'
import ContactJson from "../assets/jsons/contact.json";

interface Props {
    options: string[];
    values: {value: string, index: number, checked: boolean}[];
    setValues: (value: {value: string, index: number, checked: boolean}[]) => void;
    onClick?: () => void;
    checkboxColor?: string;
    title?: string;
    style?: object;
    styleHovered?: object;
    styleLabel?: object;
    styleLabelHovered?: object;
    styleBox?: object;
    styleTitle?: object;
    getInFr?: boolean;
}

export const MyCheckbox = (props: Props) => {
    const [hovered, setHovered] = React.useState(-1);
    const [labelHovered, setLabelHovered] = React.useState(-1);

    const getValue = (value: string) : string => {
        if (ContactJson.availableLanguages[0].subjectOptions.findIndex((e) => e === value) !== -1)
            return ContactJson.availableLanguages[1].subjectOptions[ContactJson.availableLanguages[0].subjectOptions.findIndex((e) => e === value)]
        if (ContactJson.availableLanguages[1].subjectOptions.findIndex((e) => e === value) !== -1)
            return ContactJson.availableLanguages[1].subjectOptions[ContactJson.availableLanguages[1].subjectOptions.findIndex((e) => e === value)]
        if (ContactJson.availableLanguages[2].subjectOptions.findIndex((e) => e === value) !== -1)
            return ContactJson.availableLanguages[1].subjectOptions[ContactJson.availableLanguages[2].subjectOptions.findIndex((e) => e === value)]
        return "Error"
    }

    const handleChange = (e: any, index: number) => {
        const {value, checked} = e.target;
        const selectedTmp = props.values;

        if (props.getInFr) {
            if (checked)
                props.setValues([...props.values, {value: getValue(props.options[value]), index: index, checked: true}]);
            else
                props.setValues(selectedTmp.filter((e) => e.value !== getValue(props.options[value])));
        } else {
            if (checked)
                props.setValues([...props.values, {value: props.options[value], index: index, checked: true}]);
            else
                props.setValues(selectedTmp.filter((e) => e.value !== props.options[value]));
        }
    }

    const getCheckedStatus = (index: number) : boolean => {
        if (props.values.find((e) => e.index === index) !== undefined)
            if (props.values.find((e) => e.index === index)!.checked)
                return true
        return false
    }

    return (
        <Box>
            <Box style={props.styleTitle}>
                {props.title}
            </Box>
            <Box style={props.styleBox}>
                {props.options.map((option, index) => (
                    <Label
                        sx={{
                            width: "auto",
                            color: "myBlack",
                            cursor: "pointer",
                            transition: "all 0.2s ease",
                            fontFamily: "myRegular",
                            fontSize: "myBig",
                            "&:hover": {
                                color: "myGreen",
                            }
                        }}
                        style={labelHovered === index ? props.styleLabelHovered : props.styleLabel}
                        key={index} onMouseEnter={() => {setLabelHovered(index)}} onMouseLeave={() => {setLabelHovered(-1)}}>
                        <Checkbox
                            sx={{
                                color: props.checkboxColor ? props.checkboxColor : "myBlack",
                                cursor: "pointer",
                                transition: "all 0.2s ease",
                            }}
                            style={hovered ? props.styleHovered : props.style}
                            checked={getCheckedStatus(index)}
                            name='checkbox' id={option+index} value={index} onMouseEnter={() => {setHovered(index)}} onMouseLeave={() => {setHovered(-1)}} onClick={props.onClick} onChange={(e) => handleChange(e, index)}/>
                            {option}
                    </Label>
                ))}
            </Box>
        </Box>
    )
};

export default MyCheckbox;
import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import {Flex} from "rebass";
import MyText from "../components/MyText";
import MyImage from "../components/MyImage";
import FootReportJson from "../assets/jsons/footReport.json"
import ContantsJson from "../assets/jsons/constants.json"
import MyTextSplit from "../components/MyTextSplit";
import {Parallax} from "react-scroll-parallax";
import {useInView} from "react-intersection-observer";
import Footer from "../components/Footer";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const FootReport = (props: Props) => {
    const [section1El1, inViewSection1El1] = useInView({triggerOnce: true})
    const [section1El2, inViewSection1El2] = useInView({triggerOnce: true})

    const [section2El1, inViewSection2El1] = useInView({triggerOnce: true})
    const [section2El2, inViewSection2El2] = useInView({triggerOnce: true})
    const [section2El3, inViewSection2El3] = useInView({triggerOnce: true})
    const [section2El4, inViewSection2El4] = useInView({triggerOnce: true})

    const [section3El1, inViewSection3El1] = useInView({triggerOnce: true})

    return (
        <Box>
            <PageTitle title={FootReportJson.availableLanguages[props.language].sections[0].pageTitle} backgroundPicture={props.windowWidth > ContantsJson.MobileScreenEndWith ? FootReportJson.availableLanguages[props.language].sections[0].backgroundPicture : FootReportJson.availableLanguages[props.language].sections[0].backgroundPictureMob} backgroundPositionY={"top"}/>
            <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} marginTop={"90px"}>
                <Box ref={section1El1} className={inViewSection1El1 ? "fadeDisplayAnim3" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "left" : "center", marginBottom: "20px"}}>
                    <MyText as={"h1"} value={FootReportJson.availableLanguages[props.language].sections[1].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                </Box>
                <Box ref={section1El2} className={inViewSection1El2 ? "fadeDisplayAnim4" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: "left"}}>
                    <MyTextSplit value={FootReportJson.availableLanguages[props.language].sections[1].textsplit} boxStyle={{fontSize: "var(--BigFontSize)", fontFamily: "Muli-Light"}}/>
                </Box>
            </Flex>
            <Flex flexDirection={props.windowWidth > ContantsJson.MobileScreenEndWith ? "row" : "column"} justifyContent={"center"} alignItems={"center"} marginTop={"130px"}>
                <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ContantsJson.MobileScreenEndWith ? "50%": "90%"} marginLeft={"60px"} marginRight={"60px"}>
                    <Box ref={section2El1} className={inViewSection2El1 ? "fadeDisplayAnim1" : "noOpacity"} style={{textAlign: props.windowWidth > ContantsJson.MobileScreenEndWith ? "left" : "center", width: "100%", marginBottom: "20px"}}>
                        <MyText as={"h1"} value={FootReportJson.availableLanguages[props.language].sections[2].title} style={{width: "100%", fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                    </Box>
                    <Box ref={section2El3} className={inViewSection2El3 ? "fadeDisplayAnim3" : "noOpacity"} style={{textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootReportJson.availableLanguages[props.language].sections[2].texts![0].textsplit}/>
                    </Box>
                    <Box ref={section2El4} className={inViewSection2El4 ? "fadeDisplayAnim4" : "noOpacity"} style={{marginTop: "5px", textAlign: "left", width: "100%"}}>
                        <MyTextSplit value={FootReportJson.availableLanguages[props.language].sections[2].texts![1].textsplit}/>
                    </Box>
                </Flex>
                <Box ref={section2El2} className={inViewSection2El2 ? "rightToLeftDisplayAnim2" : "noOpacity"} style={{width: props.windowWidth > ContantsJson.MobileScreenEndWith ? "45%" : "90%", marginRight: props.windowWidth > ContantsJson.MobileScreenEndWith ? "60px" : "auto", marginLeft: props.windowWidth > ContantsJson.MobileScreenEndWith ? "0" : "auto", marginTop: props.windowWidth > ContantsJson.MobileScreenEndWith ? "0": "50px"}}>
                    <Parallax speed={8} style={{marginLeft: "2%", marginTop: props.windowWidth > 1000 ? "" : "30px"}}>
                        <Box style={{position: "relative"}}>
                            <MyImage src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+FootReportJson.availableLanguages[props.language].sections[2].pictureLink1+".webp") : require("../"+FootReportJson.availableLanguages[props.language].sections[2].pictureLink1+"Mob.webp")} style={{scale: "1.1", width: "100%"}}/>
                            <Parallax speed={20} style={{position: "absolute", left: "13%", top: "20%"}}>
                                <MyImage src={props.windowWidth > ContantsJson.MobileScreenEndWith ? require("../"+FootReportJson.availableLanguages[props.language].sections[2].pictureLink2+".webp") : require("../"+FootReportJson.availableLanguages[props.language].sections[2].pictureLink2+"Mob.webp")}/>
                            </Parallax>
                        </Box>
                    </Parallax>
                </Box>
            </Flex>
            <Flex alignItems={"center"} ml={"auto"} mr={"auto"} marginBottom={"100px"} flexDirection={"column"} wrap={"wrap"} width={props.windowWidth > ContantsJson.MobileScreenEndWith ? "75%" : "90%"} marginTop={"120px"}>
                <Box>
                    <Box ref={section3El1} className={inViewSection3El1 ? "fadeDisplayAnim1" : "noOpacity"}>
                        <MyText value={FootReportJson.availableLanguages[props.language].sections[3].text} style={{fontFamily: "Muli-Light", whiteSpace: "pre-wrap", fontSize: "var(--TitleFontSize)"}}/>
                    </Box>
                </Box>
            </Flex>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default FootReport;
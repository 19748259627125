import React, {useState} from "react";
import "../assets/css/constants.css"
import { Box } from "@mui/material";
import { MdCancel as BaseRightIcon} from "react-icons/md";
import Select from "react-select";

interface Props {
    backgroundColor?: string;
    borderBottom?: string;
    borderRadius?: string;
    onClick?: () => void;
    borderRight?: string;
    fontFamily?: string;
    borderLeft?: string;
    borderTop?: string;
    minHeight?: string;
    fontSize?: string;
    minWidth?: string;
    padding?: string;
    opacity?: number;
    height?: string;
    margin?: string;
    cursor?: string;
    color?: string;
    width?: string;
    options: {value: any, label: string}[];

    hover?: {
        backgroundColor?: string;
        borderBottom?: string;
        borderRadius?: string;
        borderRight?: string;
        fontFamily?: string;
        borderLeft?: string;
        borderTop?: string;
        minHeight?: string;
        fontSize?: string;
        minWidth?: string;
        padding?: string;
        opacity?: number;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
    }

    leftIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick?: () => void;
    };

    rightIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick?: () => void;
    };

    rightIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };

    leftIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };
}

const MySelect = (props: Props) => {
    const [rightIconHover, setRightIconHover] = useState(false);
    const [leftIconHover, setLeftIconHover] = useState(false);

    const rightIconStyle = {
        backgroundColor: (props.rightIcon && props.rightIcon.backgroundColor) || "transparent",
        padding: (props.rightIcon && props.rightIcon.padding) || "0",
        cursor: (props.rightIcon && props.rightIcon.cursor) || "pointer",
        color: (props.rightIcon && props.rightIcon.color) || "var(--Grey)",
        size: (props.rightIcon && props.rightIcon.size) || "inherit",
        minHeight: (props.rightIcon && props.rightIcon.minHeight) || "auto",
        minWidth: (props.rightIcon && props.rightIcon.minWidth) || "auto",
        height: (props.rightIcon && props.rightIcon.height) || "auto",
        margin: (props.rightIcon && props.rightIcon.margin) || "0 0 -2 0",
        width: (props.rightIcon && props.rightIcon.width) || "auto",
        opacity: (props.rightIcon && props.rightIcon.opacity) || 1,
        visibility: (props.rightIcon && props.rightIcon.icon) ? "visible" : "hidden",
        outline: "none"
    };

    const rightIconHoverStyle = {
        backgroundColor: (props.rightIconHover && props.rightIconHover.backgroundColor) || "transparent",
        padding: (props.rightIconHover && props.rightIconHover.padding) || "0",
        cursor: (props.rightIconHover && props.rightIconHover.cursor) || "pointer",
        color: (props.rightIconHover && props.rightIconHover.color) || "var(--DarkGrey)",
        size: (props.rightIconHover && props.rightIconHover.size) || "inherit",
        minHeight: (props.rightIconHover && props.rightIconHover.minHeight) || "auto",
        minWidth: (props.rightIconHover && props.rightIconHover.minWidth) || "auto",
        height: (props.rightIconHover && props.rightIconHover.height) || "auto",
        margin: (props.rightIconHover && props.rightIconHover.margin) || "0 0 -2 0",
        width: (props.rightIconHover && props.rightIconHover.width) || "auto",
        opacity: (props.rightIconHover && props.rightIconHover.opacity) || 1,
        outline: "none"
    };

    const leftIconStyle = {
        backgroundColor: (props.leftIcon && props.leftIcon.backgroundColor) || "transparent",
        padding: (props.leftIcon && props.leftIcon.padding) || "0",
        cursor: (props.leftIcon && props.leftIcon.cursor) || "pointer",
        color: (props.leftIcon && props.leftIcon.color) || "var(--Grey)",
        size: (props.leftIcon && props.leftIcon.size) || "inherit",
        minHeight: (props.leftIcon && props.leftIcon.minHeight) || "auto",
        minWidth: (props.leftIcon && props.leftIcon.minWidth) || "auto",
        height: (props.leftIcon && props.leftIcon.height) || "auto",
        margin: (props.leftIcon && props.leftIcon.margin) || "0 0 -2 0",
        width: (props.leftIcon && props.leftIcon.width) || "auto",
        opacity: (props.leftIcon && props.leftIcon.opacity) || 1,
        visibility: (props.leftIcon && props.leftIcon.icon) ? "visible" : "hidden",
        position: "relative" as "relative",
        zIndex: "1",
        outline: "none",
    };

    const leftIconHoverStyle = {
        backgroundColor: (props.leftIconHover && props.leftIconHover.backgroundColor) || "transparent",
        padding: (props.leftIconHover && props.leftIconHover.padding) || "0",
        cursor: (props.leftIconHover && props.leftIconHover.cursor) || "pointer",
        color: (props.leftIconHover && props.leftIconHover.color) || "var(--DarkGrey)",
        size: (props.leftIconHover && props.leftIconHover.size) || "inherit",
        minHeight: (props.leftIconHover && props.leftIconHover.minHeight) || "auto",
        minWidth: (props.leftIconHover && props.leftIconHover.minWidth) || "auto",
        height: (props.leftIconHover && props.leftIconHover.height) || "auto",
        margin: (props.leftIconHover && props.leftIconHover.margin) || "0 0 -2 0",
        width: (props.leftIconHover && props.leftIconHover.width) || "auto",
        opacity: (props.leftIconHover && props.leftIconHover.opacity) || 1,
        position: "relative" as "relative",
        zIndex: "1",
        outline: "none"
    };

    const LeftIcon = (props.leftIcon && props.leftIcon.icon) || BaseRightIcon;
    const RightIcon = (props.rightIcon && props.rightIcon.icon) || BaseRightIcon;

    const rightIcon = <RightIcon style={rightIconHover ? rightIconHoverStyle : rightIconStyle} onMouseEnter={() => setRightIconHover(true)} onMouseLeave={() => setRightIconHover(false)} onClick={() => {props.rightIcon?.onClick && props.rightIcon.onClick()}}/>
    const leftIcon = <LeftIcon style={leftIconHover ? leftIconHoverStyle : leftIconStyle} onMouseEnter={() => setLeftIconHover(true)} onMouseLeave={() => setLeftIconHover(false)} onClick={() => {props.leftIcon?.onClick && props.leftIcon.onClick()}}/>;

    const styles = {
        menu: (base: any) => ({
            ...base,
            width: "max-content",
            minWidth: "100%"
        }),
        control: () => ({
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
        }),
        option: (provided: any, state: any) => {
            return {
                ...provided,
                color: state.isSelected ? "var(--Green)" : "var(--Grey)",
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                cursor: 'pointer',
                backgroundColor: state.isFocused ? "var(--InputGrey)" : null,
            };
        }
    };

    return (
        <Box style={{display: "flex"}}>
            {leftIcon}
            <Select onChange={props.onClick} components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }} isSearchable={false} options={props.options} styles={styles} defaultValue={props.options[1]}/>
            {rightIcon}
        </Box>
    )
};

export default MySelect;
// import React from "react"
// import emailjs from '@emailjs/browser';
// import ToastSuccess from "./ToastSuccess";
// import ToastError from "./ToastError";
// import ConstantsJson from "../assets/jsons/constants.json";
//
// const serviceId = process.env.REACT_APP_SERVICE_ID!
// const templateId = process.env.REACT_APP_TEMPLATE_ID!
// const apiKey = process.env.REACT_APP_API_KEY!
//
// const sendEmail = async (patientStatus: string, subject: string, concernedOffices: string, firstname: string, lastname: string, email: string, phone: string, message: string, errormessage: string, successmessage: string, setState: any, windowWidth: number) => {
//     try {
//         const response = await emailjs.send(
//             serviceId,
//             templateId,
//             { patientStatus, subject, concernedOffices, firstname, lastname, email, phone, message },
//             apiKey
//         );
//
//         if (response.status === 200) {
//             setState("sendsuccess")
//             ToastSuccess(successmessage, windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
//         }
//     } catch (error) {
//         setState("senderror")
//         ToastError(errormessage, windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
//         console.error("Failed to send email. Error: ", error);
//     }
// };
//
// export default sendEmail;

import React from "react"
// import { SMTPClient } from 'emailjs';
import ToastSuccess from "./ToastSuccess";
import ToastError from "./ToastError";
import ConstantsJson from "../assets/jsons/constants.json";
import axios from "axios";

const serviceId = process.env.REACT_APP_SERVICE_ID!
const templateId = process.env.REACT_APP_TEMPLATE_ID!
const apiKey = process.env.REACT_APP_API_KEY!

const sendEmail = async (patientStatus: string, subject: string, concernedOffices: string, firstname: string, lastname: string, email: string, phone: string, message: string, errormessage: string, successmessage: string, setState: any, windowWidth: number) => {
    var data = {
        service_id: serviceId,
        template_id: templateId,
        user_id: apiKey,
        template_params: {
            'patientStatus': patientStatus,
            'subject': subject,
            'concernedOffices': concernedOffices,
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
            'phone': phone,
            'message': message,
        }
    };
    try {
        setState("sending")
        axios.post('https://api.emailjs.com/api/v1.0/email/send', data).then((response) => {
            if (response.status === 200) {
                setState("sendsuccess")
                ToastSuccess(successmessage, windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
            }
        }).catch((error) => {
            setState("senderror")
            ToastError(errormessage, windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
            console.error("Failed to send email. Error: ", error);
        })
        // const response = await emailjs.send(
        //     serviceId,
        //     templateId,
        //     { patientStatus, subject, concernedOffices, firstname, lastname, email, phone, message },
        //     apiKey
        // );
        //
    } catch (error) {
        setState("senderror")
        ToastError(errormessage, windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
        console.error("Failed to send email. Error: ", error);
    }
};

export default sendEmail;
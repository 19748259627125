import React from "react";
import {Box, Flex} from "rebass";
import MyText from "./MyText";
import "../assets/css/constants.css"
import InfosJson from "../assets/MaximeCanChange/infos.json"
import { MdOutlineCancel as CancelIcon } from 'react-icons/md';

interface Props {
    onClose: () => void
    language: number
}

export const MyDialog = (props: Props) => {
    return (
        <Flex style={{position: "fixed", width: "100%", zIndex: "1", height: "90%", cursor: "pointer"}} alignItems={"center"} justifyContent={"center"} onClick={props.onClose}>
            <Flex backgroundColor={"var(--White)"} width={"80%"} height={"80%"} style={{borderRadius: "15px", border: "4px solid var(--Green)"}} alignItems={"center"} justifyContent={"center"}>
                <Flex flexDirection={"column"} width={"100%"} height={"100%"} style={{overflow: "auto", position: "relative"}}>
                    <Box style={{marginLeft: "auto", marginRight: "auto", width: "max-content", marginBottom: "30px", marginTop: "10px"}}>
                        <MyText value={InfosJson.availableLanguages[props.language].pageTitle} style={{fontSize: "var(--PageTitleFontSize)"}}/>
                        <CancelIcon size={30} className={"buttonImageCancelInfos"} style={{position: "absolute"}}/>
                    </Box>
                    {InfosJson.availableLanguages[props.language].infos.map((item) => (
                        <Box style={{marginLeft: "auto", marginRight: "auto", marginBottom: "50px", width: "80%"}}>
                            <MyText value={item.title} style={{fontSize: "var(--TitleFontSize)", marginBottom: "20px", textDecoration: "underline", textUnderlineOffset: "4px"}}/>
                            <MyText value={item.descrition} style={{fontSize: "var(--BigFontSize)", marginLeft: "40px"}}/>
                        </Box>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    )
};

export default MyDialog;
import React from "react";

interface Props {
    src: string
    style?: object;
    autoplay?: boolean
    loop?: boolean
    muted?: boolean
    playsInline?: boolean
}

export const MyVideo = (props: Props) => {
    return <video
        src={props.src}
        style={props.style}
        autoPlay={props.autoplay ? props.autoplay : true}
        loop={props.loop ? props.loop : true}
        muted={props.muted ? props.muted : true}
        playsInline={props.playsInline}
    />
};

export default MyVideo;
import React from "react";
import {Slider, Label, Box} from 'theme-ui'

interface Props {
    value: number;
    setValue: (value: number) => void;
    defaultValue?: number;
    label?: string;
    onChange?: () => void;
    style?: object;
    styleHovered?: object;
    styleLabel?: object;
    styleLabelHovered?: object;
}

export const MySlider = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);
    const [labelHovered, setLabelHovered] = React.useState(false);

    const handleChange = (e: any) => {
        props.setValue(e.target.value);
    }

    return (
        <Box>
            <Label
                sx={{
                    color: "myBlack",
                    cursor: "pointer",
                    transition: "all 0.2s ease",
                }}
                style={labelHovered ? props.styleLabelHovered : props.styleLabel}
                onMouseEnter={() => {setLabelHovered(true)}} onMouseLeave={() => {setLabelHovered(false)}}>
                {props.label} ({props.value})
            </Label>
            <Slider
                sx={{
                    background: "myBlack",
                    color: "myGreen",
                    cursor: "pointer",
                    transition: "all 0.2s ease",
                }}
                style={hovered ? props.styleHovered : props.style}
                name='slider' id={"1"} defaultValue={props.defaultValue} onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} onChange={props.onChange ? props.onChange : (e) => {handleChange(e)}}/>
        </Box>
    )
};

export default MySlider;
import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import PathologiesJson from "../assets/jsons/pathologies.json";
import ConstantsJson from "../assets/jsons/constants.json"
import {Flex} from "rebass";
import MyText from "../components/MyText";
import MyImage from "../components/MyImage";
import {Parallax} from "react-scroll-parallax";
import Footer from "../components/Footer";
import MyTextSplit from "../components/MyTextSplit";
import {useInView} from "react-intersection-observer";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

interface PathologiesCardProps {
    title: string,
    subtitle: string,
    texts: {textsplit: {text: string, bold: boolean}[]}[] | undefined,
    picture1: string,
    picture2: string,
    position?: string,
    language: number,
    marginTop: string,
    marginBottom: string
    windowWidth: number
}

const PathologiesCard = (props: PathologiesCardProps) => {
    const [ref1, inViewRef1] = useInView({triggerOnce: true})
    const [ref2, inViewRef2] = useInView({triggerOnce: true})
    const [ref3, inViewRef3] = useInView({triggerOnce: true})
    const [ref4, inViewRef4] = useInView({triggerOnce: true})
    const [ref5, inViewRef5] = useInView({triggerOnce: true})

    return (
        <Flex alignItems={"center"} justifyContent={"center"} flexDirection={props.windowWidth > 1000 ? "row" : "column"} marginTop={props.marginTop} marginBottom={props.marginBottom}>
            {(props.position === "right" && props.windowWidth > 1000) && <Box ref={ref2} className={inViewRef2 ? "leftToRightDisplayAnim3" : "noOpacity"} style={{width: props.windowWidth > 1000 ? "40%" : "90%"}}><Parallax speed={8} style={{marginRight: "2%"}}>
                {(props.picture1 && !props.picture2) && <MyImage src={require("../"+props.picture1+".webp")}/>}
                {(props.picture1 && props.picture2) && <Box style={{position: "relative"}}>
                    <MyImage src={require("../"+props.picture1+".webp")} style={{width: "85%"}}/>
                    <Parallax speed={20} style={{scale: "0.8", position: "absolute", right: "25%", top: "30%"}}>
                        <MyImage src={require("../"+props.picture2+".webp")}/>
                    </Parallax>
                </Box>}
            </Parallax></Box>}
            <Flex flexDirection={"column"} alignItems={!props.picture1 && (props.position === "center") ? "center" : props.position === "right" ? "end" : ""} width={!props.picture1 || props.windowWidth <= 1000 ? "92%" : props.position === "right" ? "50%" : "45%"}>
                <Box>
                    <Flex ref={ref1} className={inViewRef1 ? "fadeDisplayAnim2" : "noOpacity"} justifyContent={!props.picture1 && (props.position === "center") ? "center" : "center"}>
                        <MyText value={props.title+":"} style={{fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)", textDecoration: "underline", textDecorationColor: "var(--Black)", textUnderlineOffset: "6px"}}/>
                        {props.windowWidth > 575 && <MyText value={props.subtitle ? "  ("+props.subtitle+")" : ""} style={{fontFamily: "Muli-SemiBold", fontSize: "var(--BigFontSize)", color: "grey", marginTop: "auto", marginBottom: "auto"}}/>}
                    </Flex>
                    <Flex ref={ref3} className={inViewRef3 ? "fadeDisplayAnim4" : "noOpacity"}>
                        <Box>
                            <MyText value={PathologiesJson.availableLanguages[props.language].def+" "} style={{fontSize: "var(--BigFontSize)", marginTop: "20px", color: "grey", width: "115px"}}/>
                        </Box>
                        <MyTextSplit value={props.texts![0].textsplit} boxStyle={{marginTop: "20px"}}/>
                    </Flex>
                    <Flex ref={ref4} className={inViewRef4 ? "fadeDisplayAnim5" : "noOpacity"} marginTop={"15px"}>
                        <Box>
                            <MyText value={PathologiesJson.availableLanguages[props.language].origin+" "} style={{fontSize: "var(--BigFontSize)", width: "115px", color: "grey"}}/>
                        </Box>
                        <MyTextSplit value={props.texts![1].textsplit}/>
                    </Flex>
                    <Flex ref={ref5} className={inViewRef5 ? "fadeDisplayAnim6" : "noOpacity"} marginTop={"15px"}>
                        <Box>
                            <MyText value={PathologiesJson.availableLanguages[props.language].treatment+" "} style={{fontSize: "var(--BigFontSize)", width: "115px", color: "grey"}}/>
                        </Box>
                        <MyTextSplit value={props.texts![2].textsplit}/>
                    </Flex>
                </Box>
            </Flex>
            {(props.position === "left" || props.windowWidth <= 1000) && <Box ref={ref2} className={inViewRef2 ? "rightToLeftDisplayAnim3" : "noOpacity"} style={{width: props.windowWidth > 1000 ? "45%" : "90%"}}><Parallax speed={8} style={{marginLeft: "2%", marginTop: props.windowWidth > 1000 ? "" : "30px"}}>
                {(props.picture1 && !props.picture2) && <MyImage src={require("../"+props.picture1+".webp")}/>}
                {(props.picture1 && props.picture2) && <Box style={{position: "relative"}}>
                    <MyImage src={require("../"+props.picture1+".webp")} style={{width: "85%"}}/>
                    <Parallax speed={20} style={{scale: "0.8", position: "absolute", left: "13%", top: "20%"}}>
                        <MyImage src={require("../"+props.picture2+".webp")}/>
                    </Parallax>
                </Box>}
            </Parallax></Box>}
        </Flex>
    )
}

const Pathologies = (props: Props) => {
    return (
        <Box>
            <PageTitle title={PathologiesJson.availableLanguages[props.language].title} backgroundPicture={props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? PathologiesJson.availableLanguages[props.language].titleBackground1 : PathologiesJson.availableLanguages[props.language].titleBackground2} backgroundPositionY={"Top"}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].footCondition} subtitle={""} texts={PathologiesJson.availableLanguages[props.language].footConditionTexts} picture1={PathologiesJson.availableLanguages[props.language].footConditionPicture1} picture2={PathologiesJson.availableLanguages[props.language].footConditionPicture2} position={"left"} marginTop={"55px"} marginBottom={"100px"} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].diabethicFoot} subtitle={""} texts={PathologiesJson.availableLanguages[props.language].diabethicFootTexts} picture1={PathologiesJson.availableLanguages[props.language].diabethicFootPicture1} picture2={PathologiesJson.availableLanguages[props.language].diabethicFootPicture2} position={"center"} marginTop={"150px"} marginBottom={"100px"} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].halluxValgus} subtitle={""} texts={PathologiesJson.availableLanguages[props.language].halluxValgusTexts} picture1={PathologiesJson.availableLanguages[props.language].halluxValgusPicture1} picture2={PathologiesJson.availableLanguages[props.language].halluxValgusPicture2} position={"right"} marginTop={"150px"} marginBottom={"100px"} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].ilmi} subtitle={""} texts={PathologiesJson.availableLanguages[props.language].ilmiTexts} picture1={PathologiesJson.availableLanguages[props.language].ilmiPicture1} picture2={PathologiesJson.availableLanguages[props.language].ilmiPicture2} position={"center"} marginTop={"150px"} marginBottom={"100px"} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].valgusVarus} subtitle={""} texts={PathologiesJson.availableLanguages[props.language].valgusVarusTexts} picture1={PathologiesJson.availableLanguages[props.language].valgusVarusPicture1} picture2={PathologiesJson.availableLanguages[props.language].valgusVarusPicture2} position={"left"} marginTop={"150px"} marginBottom={"100px"} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].deformedToes} subtitle={PathologiesJson.availableLanguages[props.language].deformedToesSubtitle} texts={PathologiesJson.availableLanguages[props.language].deformedToesTexts} picture1={PathologiesJson.availableLanguages[props.language].deformedToesPicture1+props.language} picture2={PathologiesJson.availableLanguages[props.language].deformedToesPicture2} position={"right"} marginTop={"150px"} marginBottom={""} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].paralysis} subtitle={PathologiesJson.availableLanguages[props.language].paralysisSubtitle} texts={PathologiesJson.availableLanguages[props.language].paralysisTexts} picture1={PathologiesJson.availableLanguages[props.language].paralysisPicture1} picture2={PathologiesJson.availableLanguages[props.language].paralysisPicture2} position={"center"} marginTop={"150px"} marginBottom={"100px"} language={props.language} windowWidth={props.windowWidth}/>
            <PathologiesCard title={PathologiesJson.availableLanguages[props.language].amputation} subtitle={PathologiesJson.availableLanguages[props.language].amputationSubtitle} texts={PathologiesJson.availableLanguages[props.language].amputationTexts} picture1={PathologiesJson.availableLanguages[props.language].amputationPicture1} picture2={PathologiesJson.availableLanguages[props.language].amputationPicture2} position={"left"} marginTop={"150px"} marginBottom={"55px"} language={props.language} windowWidth={props.windowWidth}/>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default Pathologies;
import React from "react";
import {Box, Link} from 'theme-ui'
import {useNavigate} from "react-router-dom";

interface Props {
    value: {text: string, bold: boolean, link?: string}[] | undefined
    boxStyle?: object
    mid?: boolean
    big?: boolean
    as?: string
}

export const MyTextSplit = (props: Props) => {
    const navigate = useNavigate()

    const styles = {
        bold: {
            fontFamily: "Muli-Bold",
            whiteSpace: "pre-wrap" as "pre-wrap",
            fontSize: "var(--MidFontSize)",
            textJustify: "inter-word" as "inter-word"
        },
        notbold: {
            fontFamily: "Muli-Light",
            whiteSpace: "pre-wrap" as "pre-wrap",
            fontSize: "var(--MidFontSize)",
            textJustify: "inter-word" as "inter-word"
        },
        boldMid: {
            fontFamily: "Muli-Bold",
            whiteSpace: "pre-wrap" as "pre-wrap",
            fontSize: "var(--BigFontSize)",
            textJustify: "inter-word" as "inter-word"
        },
        notboldMid: {
            fontFamily: "Muli-Light",
            whiteSpace: "pre-wrap" as "pre-wrap",
            fontSize: "var(--BigFontSize)",
            textJustify: "inter-word" as "inter-word"
        },
        boldBig: {
            fontFamily: "Muli-Bold",
            whiteSpace: "pre-wrap" as "pre-wrap",
            fontSize: "var(--TitleFontSize)",
            textJustify: "inter-word" as "inter-word"
        },
        notboldBig: {
            fontFamily: "Muli-Light",
            whiteSpace: "pre-wrap" as "pre-wrap",
            fontSize: "var(--TitleFontSize)",
            textJustify: "inter-word" as "inter-word"
        }
    }

    return (
        <Box as={props.as ? props.as as React.ElementType : "p" as React.ElementType} style={props.boxStyle}>
            {props.value?.map((item) => (
                <Link onClick={item.link ? () => {window.scrollTo(0, 0); navigate("/"+item.link!)} : () => {}} className={item.link ? "goto" : ""} style={item.bold ? props.big ? styles.boldBig : props.mid ? styles.boldMid : styles.bold : props.big ? styles.notboldBig : props.mid ? styles.notboldMid : styles.notbold}>{item.text}</Link>
            ))}
        </Box>
    )
};

export default MyTextSplit;
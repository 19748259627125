import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import "../assets/css/gallery.css"
import {IoClose as CloseIcon} from "react-icons/io5"
import {BsFillCaretLeftFill as LeftIcon} from "react-icons/bs"
import {BsFillCaretRightFill as RightIcon} from "react-icons/bs"
import { motion, Variants, AnimatePresence } from "framer-motion"
import NumberArtJson from "../assets/MaximeCanChange/articles/numberArticles.json"
import ArtDetailsJson from "../assets/MaximeCanChange/articles/infos.json"
import ArticlesJson from "../assets/jsons/articles.json"
import MyText from "../components/MyText";
import {Flex} from "rebass";
import Footer from "../components/Footer";
import MySelect from "../components/MySelect";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const Articles = (props: Props) => {
    const [fullscreen, setFullscreen] = useState(false)
    const [filter, setFilter] = useState(ArtDetailsJson.availableLanguages[props.language].categories[0].name)
    const [filtered, setFiltered] = useState([] as number[])
    const [[page, direction], setPage] = useState([0, -1]);
    const artNumber = NumberArtJson.numberArticles
    const directoryPath = "assets/MaximeCanChange/articles/" as string
    const extension = ".webp" as string

    useEffect(() => {
        let tmp = [] as number[]

        articles.map((item, index) => {
            if ((getCategorieNumber(filter) === ArtDetailsJson.availableLanguages[props.language].elements[index].catergorie) || filter === ArtDetailsJson.availableLanguages[props.language].categories[0].name)
                tmp.push(index)
        })
        setFiltered(tmp)
    }, [filter])

    const getArticles = () => {
        let art = [] as { src: string, width: number, height: number }[]

        for (var i = 1; i <= artNumber; i++)
            art.push({src: require("../"+ directoryPath + i + extension), width: 1920, height: 1080})

        return art
    }

    const getCategorieNumber = (str: string) : string => {
        let res = ""

        ArtDetailsJson.availableLanguages[props.language].categories.map((item) => {
            if (item.name === str)
                res = item.number
        })
        return res
    }

    let articles = getArticles()

    const getArt = (nb: number) => {
        setPage([nb, 1])
        setFullscreen(true)
    }

    const cardVariants: Variants = {
        offscreen: {
            rotate: 3,
            y: 50,
            opacity: 0
        },
        onscreen: {
            rotate: 0,
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 1.2
            }
        }
    };

    useEffect(() => {
        if (page !== 0)
            document.body.style.overflow = "hidden"
        else
            document.body.style.overflow = "visible"
    }, [page])

    const variants = {
        enter: () => {
            return {
                x: direction > 0 ? 1000 : -1000,
                opacity: 0,
                background: "white"
            };
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
            background: "white"
        },
        exit: () => {
            return {
                zIndex: 0,
                x: direction < 0 ? 1000 : -1000,
                opacity: 0,
                background: "white"
            };
        }
    };

    const pageChange = (what: string) => {
        const getPrevCategorieNb = () => {
            let nb = 0
            let catChange = false

            if (filter !== ArtDetailsJson.availableLanguages[props.language].categories[0].name) {
                if (filtered.indexOf(page-1) > 0 && page > 1)
                    nb = filtered[(filtered.indexOf(page - 1))-1]+1
                else
                    nb = filtered[filtered.length-1]+1
            } else if (filter === ArtDetailsJson.availableLanguages[props.language].categories[0].name && page > 1)
                nb = page-1
            else
                nb = articles.length
            return nb
        }

        const getNextCategorieNb = () => {
            let nb = 0
            let catChange = false

            if (page < (artNumber)) {
                nb = page+1
                if (filter !== ArtDetailsJson.availableLanguages[props.language].categories[0].name) {
                    filtered.map((item) => {
                        if (item >= page && !catChange) {
                            nb = item+1
                            catChange = true
                            return
                        }
                    })
                    if (!catChange)
                        nb = filtered[0]+1
                }
            } else if (filter === ArtDetailsJson.availableLanguages[props.language].categories[0].name)
                nb = 1
            else
                nb = filtered[0]+1
            return nb
        }

        switch (what) {
            case "close":
                setPage([0, -1])
                break
            case "left":
                setPage([getPrevCategorieNb(), -1])
                break
            case "right":
                setPage([getNextCategorieNb(), 1])
                break
        }
    }

    return (
        <Box>
            <PageTitle title={ArticlesJson.availableLanguages[props.language].title} backgroundPicture={ArticlesJson.availableLanguages[props.language].backgroundLink}/>
            {page !== 0 &&
                <Box className={fullscreen ? "fullscreen open" : "fullscreen"}>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div
                            key={page}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                x: { type: "spring", stiffness: 1600, damping: 120 },
                                opacity: { duration: 0.2 }
                            }}
                        >
                            <img style={{width: props.windowWidth, height: props.windowHeight - 100, objectFit: "scale-down", marginTop: "2%"}} src={require("../" + directoryPath + page + extension)} loading={"lazy"}/>
                        </motion.div>
                    </AnimatePresence>
                    <MyText style={{position: "absolute", top: "10px", fontSize: "var(--PageTitleFontSize)", zIndex: "1"}} value={ArtDetailsJson.availableLanguages[props.language].elements[page-1].title}/>
                    <CloseIcon className={"close"} onClick={() => {setFullscreen(false); pageChange("close")}}/>
                    <LeftIcon className={"left"} onClick={() => pageChange("left")}/>
                    <RightIcon className={"right"} onClick={() => pageChange("right")}/>
                </Box>
            }
            {(articles.length !== 0 && ArtDetailsJson.availableLanguages[props.language].categories.length > 2) && <MySelect options={ArtDetailsJson.availableLanguages[props.language].categories.map((item) => {
                return item.name
            })} value={filter} setValue={setFilter} boxStyle={{width: "30%", marginLeft: "auto", marginRight: "5%"}}/>}
            <Box className={articles.length === 0 ? "" : "gallery"}>
                {articles.length === 0 && <Flex alignItems={"center"} justifyContent={"center"} marginTop={"300px"} marginBottom={"300px"}>
                    <MyText value={ArticlesJson.availableLanguages[props.language].noElem} style={{fontSize: "var(--TitleFontSize)"}}/>
                </Flex>}
                {articles.length > 0 && filtered.length === 0 && <Flex alignItems={"center"} justifyContent={"center"} marginTop={"300px"} marginBottom={"300px"}>
                    <MyText value={ArticlesJson.availableLanguages[props.language].noElemInCategory} style={{fontSize: "var(--TitleFontSize)"}}/>
                </Flex>}
                {filtered.map((item) => {
                    return (
                        <motion.div
                            className="card-container"
                            initial={filter !== ArtDetailsJson.availableLanguages[props.language].categories[0].name ? "" : "offscreen"}
                            whileInView="onscreen"
                            key={item}
                            viewport={{ once: false, amount: 0.1 }}
                            variants={cardVariants}>
                            <div className={"pics"} key={item} style={{position: "relative"}}>
                                <img src={articles[item].src} loading={"lazy"} style={{width: "100%"}} onClick={() => {getArt(item+1)}}/>
                                <MyText style={{position: "absolute", left: "20px", bottom: "20px"}} value={ArtDetailsJson.availableLanguages[props.language].elements[item].title}/>
                            </div>
                        </motion.div>
                    )
                })}
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default Articles;
import React from "react";
import { Textarea, Box, Label } from 'theme-ui'

interface Props {
    value: string;
    setValue: (e: any) => void;
    label?: string;
    placeholder?: string;
    onChange?: any;
    style?: object;
    boxStyle?: object;
    labelStyle?: object;
    numbersOnly?: boolean;
    charactersOnly?: boolean;
    required?: boolean;
    maxLength?: number;
    regex?: RegExp;
    status?: number;
    focus?: number;
    setFocus?: any;
    autoFocus?: boolean;
}

export const MyInputTextArea = (props: Props) => {
    const numbersOnly = new RegExp(
        '^[0-9\n ]*$'
    );

    const charactersOnly = new RegExp(
        '^[a-zA-Z\n ]*$'
    );

    const handleChange = (e: any) => {
        if (props.onChange)
            props.onChange(e)
        else {
            if (props.regex) {
                if (e.target.value.match(props.regex))
                    props.setValue(e.target.value)
            } else {
                if (props.numbersOnly !== props.charactersOnly) {
                    if (props.numbersOnly && e.target.value.match(numbersOnly))
                        props.setValue(e.target.value)
                    if (props.charactersOnly && e.target.value.match(charactersOnly))
                        props.setValue(e.target.value)
                } else if (!props.numbersOnly && !props.charactersOnly && !props.regex)
                    props.setValue(e.target.value)
            }
        }
    }

    const getResult = (type: string) : any => {
        if (type === "label") {
            if (props.status === 1)
                return "orange"
            if (props.status === 2)
                return "red"
            if (props.status === 3)
                return "var(--Green)"
            return "inherit"
        }
        if (type === "inputBorderBottom") {
            if (props.status === 1)
                return "1px solid orange"
            if (props.status === 2)
                return "1px solid red"
            if (props.status === 3)
                return "1px solid var(--Green)"
            return "1px solid grey"
        }
    }

    return (
        <Box style={props.boxStyle}>
            {props.label &&
                <Label
                    sx={{
                        color: getResult("label"),
                        marginLeft: "5px",
                        marginBottom: "2px",
                        fontSize: "myTitle",
                        fontFamily: "myRegular",
                        transition: "all 0.2s ease",
                    }}
                    style={props.labelStyle}>
                    {props.label}</Label>
            }
            <Textarea
                required={props.required}
                sx={{
                    color: "myGrey",
                    background: "myInputGrey",
                    outline: "none",
                    border: "none",
                    borderBottom: getResult("inputBorderBottom"),
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    fontFamily: "myRegular",
                    fontSize: "myBig",
                    transition: "all 0.2s ease",
                    maxWidth: "100%"
                }}
                maxLength={props.maxLength}
                style={props.style}
                autoFocus={props.autoFocus}
                onBlur={() => props.setFocus(2)}
                onFocus={() => props.setFocus(1)}
                value={props.value} onChange={(e) => handleChange(e)} placeholder={props.placeholder || ""}/>
        </Box>
    )
};

export default MyInputTextArea;
import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import {Flex} from "rebass";
import MyText from "../components/MyText";
import ShoeRepairJson from "../assets/jsons/shoeRepair.json"
import ConstantsJson from "../assets/jsons/constants.json"
import MyTextSplit from "../components/MyTextSplit";
import {useInView} from "react-intersection-observer";
import Footer from "../components/Footer";
import {motion, Variants} from "framer-motion";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const ShoeRepair = (props: Props) => {
    const [section1El1, inViewSection1El1] = useInView({triggerOnce: true})
    const [section1El2, inViewSection1El2] = useInView({triggerOnce: true})
    const [section1El3, inViewSection1El3] = useInView({triggerOnce: true})

    const cardVariants: Variants = {
        offscreen: {
            rotate: 3,
            y: 50,
            opacity: 0
        },
        onscreen: {
            rotate: 0,
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 1.2
            }
        }
    };

    return (
        <Box>
            <PageTitle title={ShoeRepairJson.availableLanguages[props.language].sections[0].pageTitle} backgroundPicture={props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? ShoeRepairJson.availableLanguages[props.language].sections[0].backgroundPicture1 : ShoeRepairJson.availableLanguages[props.language].sections[0].backgroundPicture2} backgroundPositionY={"top"}/>
            <Flex alignItems={"center"} flexDirection={"column"} wrap={"wrap"} marginTop={"90px"} marginBottom={"50px"}>
                <Box ref={section1El1} className={inViewSection1El1 ? "fadeDisplayAnim3" : "noOpacity"} style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "left" : "center", marginBottom: "20px"}}>
                    <MyText as={"h1"} value={ShoeRepairJson.availableLanguages[props.language].sections[1].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--PageTitleFontSize)"}}/>
                </Box>
                <Box ref={section1El2} className={inViewSection1El2 ? "fadeDisplayAnim4" : "noOpacity"} style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: "left"}}>
                    <MyTextSplit value={ShoeRepairJson.availableLanguages[props.language].sections[1].textsplit} boxStyle={{fontSize: "var(--BigFontSize)", fontFamily: "Muli-Light"}}/>
                </Box>
            </Flex>
            <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", marginBottom: "50px"}}>
                <motion.div
                    initial={"offscreen"}
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.1 }}
                    variants={cardVariants}
                    style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "49%" : "96%", paddingLeft: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingRight: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingBottom: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "1%"}}>
                    <img src={require("../"+ShoeRepairJson.availableLanguages[props.language].sections[2].picture1+".webp")} loading={"lazy"} style={{width: "100%"}}/>
                </motion.div>
                <motion.div
                    initial={"offscreen"}
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.1 }}
                    variants={cardVariants}
                    style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "49%" : "96%", paddingLeft: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingRight: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingBottom: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "1%"}}>
                    <img src={require("../"+ShoeRepairJson.availableLanguages[props.language].sections[2].picture2+".webp")} loading={"lazy"} style={{width: "100%"}}/>
                </motion.div>
                <motion.div
                    initial={"offscreen"}
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.1 }}
                    variants={cardVariants}
                    style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "49%" : "96%", paddingLeft: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingRight: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingBottom: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "1%"}}>
                    <img src={require("../"+ShoeRepairJson.availableLanguages[props.language].sections[2].picture3+".webp")} loading={"lazy"} style={{width: "100%"}}/>
                </motion.div>
                <motion.div
                    initial={"offscreen"}
                    whileInView="onscreen"
                    viewport={{ once: false, amount: 0.1 }}
                    variants={cardVariants}
                    style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "49%" : "96%", paddingLeft: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingRight: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "", paddingBottom: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "0.5%" : "1%"}}>
                    <img src={require("../"+ShoeRepairJson.availableLanguages[props.language].sections[2].picture4+".webp")} loading={"lazy"} style={{width: "100%"}}/>
                </motion.div>
            </div>
            <Box ref={section1El3} className={inViewSection1El3 ? "fadeDisplayAnim2" : "noOpacity"} style={{width: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "75%" : "90%", textAlign: "center", marginLeft: "auto", marginRight: "auto", marginBottom: "100px"}}>
                <MyText value={ShoeRepairJson.availableLanguages[props.language].sections[1].text} style={{fontSize: "var(--TitleFontSize)", fontFamily: "Muli-Light", textAlign: props.windowWidth > ConstantsJson.MobileScreenEndWith ? "center" : "left"}}/>
            </Box>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default ShoeRepair;
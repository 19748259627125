import React from "react";
import { Image } from 'theme-ui'

interface Props {
    src: string
    style?: object;
    onClick?: () => void
}

export const MyImage = (props: Props) => {
    return <Image
        alt={"Image"}
        src={props.src}
        sx={{
            borderRadius: 8,
            transition: "all 0.2s ease",
            pointerEvents: "none"
        }}
        onClick={props.onClick}
        loading={"lazy"}
        style={props.style}
    />
};

export default MyImage;
import React, {useState} from 'react';
import {Box} from "@mui/material";
import "../assets/css/historycal.css"
import BackgroundImage2 from "../assets/picturesAndVideos/historical/svg.svg"
import {useInView} from "react-intersection-observer";
import PageTitle from "../components/PageTitle";
import HistoryJson from "../assets/jsons/historical.json";
import Footer from "../components/Footer";
import MyImage from "../components/MyImage";
import {Parallax} from "react-scroll-parallax";
import CountUp from 'react-countup';
import {Flex} from "rebass";
import MyText from "../components/MyText";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number
}

const Historical = (props: Props) => {
    let cards = HistoryJson.availableLanguages[props.language].sections[1].cards || [];
    const pictures2Settings = [
        {width: "65%", bottom: "-100px", positionLeftOrRight: "5%"},
        {width: "60%", bottom: "-150px", positionLeftOrRight: "45%"},
        {width: "60%", bottom: "-150px", positionLeftOrRight: "5%"},
        {width: "75%", bottom: "-100px", positionLeftOrRight: "65%"},
        {width: "85%", bottom: "-130px", positionLeftOrRight: "55%"},
        {width: "90%", bottom: "-50px", positionLeftOrRight: "40%"},
        {width: "50%", bottom: "-90px", positionLeftOrRight: "55%"},
        {width: "30%", bottom: "-50px", positionLeftOrRight: "5%"}]
    const yearsOfExperience = new Date().getFullYear() - 1909;

    return (
        <Box>
            <PageTitle title={HistoryJson.availableLanguages[props.language].sections[0].pageTitle} backgroundPicture={HistoryJson.availableLanguages[props.language].sections[0].backgroundPicture}/>
            <Box style={{backgroundImage: `url(${BackgroundImage2})`, backgroundPositionX: "center", backgroundSize: "cover", marginTop: "-9px", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "center", flexDirection: "column", zIndex: -1}}>
                <Flex alignItems={"center"} justifyContent={"center"} marginTop={"100px"} marginBottom={"150px"}>
                    <CountUp
                        start={0}
                        end={yearsOfExperience}
                        duration={3.2}
                        useEasing={true}
                        delay={0.5}
                        style={{fontFamily: "Muli-Bold", fontSize: "var(--GiantFontSize)", width: props.windowWidth > 550 ? "12%" : "16%"}}
                    />
                    <MyText value={" "+HistoryJson.availableLanguages[props.language].sections[0].yearsExp} style={{fontSize: "var(--TitleFontSize)", marginTop: "3%", marginRight: "35%"}}/>
                </Flex>
                {cards.map((card, index) => {
                    return (
                        props.windowWidth > 1000 ? index % 2 === 0 ?
                                <CardLeft first={index === 0} end={index === cards.length} date={card.date} text={card.text} picture1={card.pictureLink1} picture2={card.pictureLink2} windowHeight={props.windowHeight} windowWidth={props.windowWidth} language={props.language} pictures2Settings={pictures2Settings[index]}/>
                                :
                                <CardRight first={index === 0} end={index === cards.length} date={card.date} text={card.text} picture1={card.pictureLink1} picture2={card.pictureLink2} windowHeight={props.windowHeight} windowWidth={props.windowWidth} language={props.language} pictures2Settings={pictures2Settings[index]}/>
                            :
                                <MobileCard first={index === 0} end={index === cards.length} date={card.date} text={card.text} picture1={card.pictureLink1} picture2={card.pictureLink2} windowHeight={props.windowHeight} windowWidth={props.windowWidth} language={props.language} pictures2Settings={pictures2Settings[index]}/>

                    )
                })}
            </Box>
            <MyText value={HistoryJson.availableLanguages[props.language].sections[0].continuing} style={{fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)", textAlign: "center", marginBottom: "200px", letterSpacing: "4px", marginTop: "150px"}}/>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

interface CardProps {
    date: string;
    text: string;
    picture1: string;
    picture2: string;
    first: boolean;
    end: boolean;
    windowWidth: number;
    windowHeight: number
    language: number;
    pictures2Settings: {width: string, bottom: string, positionLeftOrRight: string}
}

const CardLeft = (props: CardProps) => {
    const [refImg1, inViewRefImg1] = useInView({triggerOnce: true, delay: 80})
    const [refImg2, inViewRefImg2] = useInView({triggerOnce: true, delay: 80})
    const [reversed, setReversed] = useState(false)

    return (
        <Box style={{display: "flex", width: "100%", height: "700px", alignItems: "center", justifyContent: "center", marginBottom: props.end ? "0" : "20px", marginTop: props.first ? "0" : "20px"}}>
            <Box className={inViewRefImg1 ? "downToUpDisplayAnim1History3" : ""} style={{display: "flex", flexDirection: "column", width: "25%"}}>
                <Box className={"historyItemTitle"} style={{height: "5%", display: "flex", justifyContent: "end", alignItems: "center"}}>
                    <text>{props.date}</text>
                </Box>
                <Box style={{display: "flex", height: "5%", justifyContent: "end", alignItems: "center", marginTop: "15px"}}>
                    <Box style={{width: "8%", height: "2px", background: "black"}}/>
                </Box>
                <Box className={"historyItemText"} style={{display: "flex", justifyContent: "end", alignItems: "start", textAlign: "end"}}>
                    {props.text}
                </Box>
            </Box>
            <Box style={{width: "30px", height: "100%"}}/>
            <Box style={{position: "relative", width: "35%", height: "90%"}}>
                <Parallax speed={8} style={{position: "absolute", top: "15%", zIndex: 0, cursor: "pointer"}} onClick={() => setReversed(!reversed)}>
                    <Box ref={refImg1} className={inViewRefImg1 ? "downToUpDisplayAnim1History" : ""}>
                        <MyImage src={require('../'+props.picture1+'.webp')} style={{maxHeight: "600px"}}/>
                    </Box>
                    {props.picture2 && <Parallax speed={10} style={{width: props.pictures2Settings.width, position: "absolute", bottom: props.pictures2Settings.bottom, left: props.pictures2Settings.positionLeftOrRight, zIndex: reversed ? -1 : 0}}>
                        <Box ref={refImg2} className={inViewRefImg2 ? "downToUpDisplayAnim1History2" : ""}>
                            <MyImage src={require('../'+props.picture2+'.webp')} style={{maxHeight: "350px"}}/>
                        </Box>
                    </Parallax>}
                </Parallax>
            </Box>
        </Box>
    )
}

const CardRight = (props: CardProps) => {
    const [refImg1, inViewRefImg1] = useInView({triggerOnce: true, delay: 80})
    const [refImg2, inViewRefImg2] = useInView({triggerOnce: true, delay: 80})
    const [reversed, setReversed] = useState(false)

    return (
        <Box style={{display: "flex", flexWrap: "wrap", width: "100%", height: "700px", alignItems: "center", justifyContent: "center", marginBottom: props.end ? "0" : "20px"}}>
            <Box style={{position: "relative", width: "35%", height: "90%"}}>
                <Parallax speed={8} style={{position: "absolute", top: "15%", zIndex: 0, cursor: "pointer"}} onClick={() => setReversed(!reversed)}>
                    <Box ref={refImg1} className={inViewRefImg1 ? "downToUpDisplayAnim1History" : ""}>
                        <MyImage src={require("../"+props.picture1+".webp")} style={{maxHeight: "600px"}}/>
                    </Box>
                    {props.picture2 && <Parallax speed={10} style={{width: props.pictures2Settings.width, position: "absolute", bottom: props.pictures2Settings.bottom, right: props.pictures2Settings.positionLeftOrRight, zIndex: reversed ? -1 : 0}}>
                        <Box ref={refImg2} className={inViewRefImg2 ? "downToUpDisplayAnim1History2" : ""}>
                            <MyImage src={require("../"+props.picture2+".webp")} style={{maxHeight: "350px"}}/>
                        </Box>
                    </Parallax>}
                </Parallax>
            </Box>
            <Box style={{width: "30px", height: "100%"}}/>
            <Box className={inViewRefImg1 ? "downToUpDisplayAnim1History3" : ""} style={{display: "flex", flexDirection: "column", width: "25%"}}>
                <Box className={"historyItemTitle"} style={{height: "5%", display: "flex", justifyContent: "start", alignItems: "center"}}>
                    {props.date}
                </Box>
                <Box style={{display: "flex", height: "5%", justifyContent: "start", alignItems: "center", marginTop: "15px"}}>
                    <Box style={{width: "8%", height: "2px", background: "black"}}/>
                </Box>
                <Box className={"historyItemText"} style={{display: "flex", justifyContent: "start", alignItems: "start", textAlign: "start"}}>
                    {props.text}
                </Box>
            </Box>
        </Box>
    )
}

const MobileCard = (props: CardProps) => {
    const [refImg1, inViewRefImg1] = useInView({triggerOnce: true, delay: 80})
    const [reversed, setReversed] = useState(false)

    return (
        <Box style={{display: "flex", flexWrap: "wrap", width: "100%", minHeight: "150px", alignItems: "center", justifyContent: "center", marginBottom: props.end ? "0" : "20px", marginTop: props.first ? "40px" : "10px"}}>
            <Box className={inViewRefImg1 ? "opacityDisplayAnim1" : "noOpacity"} style={{display: "flex", flexDirection: "column", width: "90%"}}>
                <Box className={"historyItemTitle"} style={{height: "5%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    {props.date}
                </Box>
                <Box style={{display: "flex", height: "5%", justifyContent: "center", alignItems: "center", marginTop: "15px"}}>
                    <Box style={{width: "8%", height: "2px", background: "black"}}/>
                </Box>
                <Box className={"historyItemText"} style={{display: "flex", justifyContent: "center", alignItems: "start"}}>
                    {props.text}
                </Box>
            </Box>
            <Box style={{position: "relative", width: "90%", height: "90%", marginTop: "25px", marginBottom: "10px"}}>
                <Parallax speed={8} style={{position: "relative", top: "15%", zIndex: 0, cursor: "pointer"}} onClick={() => setReversed(!reversed)}>
                    <Box ref={refImg1} className={inViewRefImg1 ? "opacityDisplayAnim2" : "noOpacity"}>
                        <MyImage src={require('../'+props.picture1+'.webp')} style={{maxHeight: "600px"}}/>
                    </Box>
                    {props.picture2 && <Parallax speed={props.picture2 === "assets/picturesAndVideos/historical/doctolib" ? 2 : 10} style={{width: props.picture2 === "assets/picturesAndVideos/historical/doctolib" ? "30%" : "65%", position: "relative", bottom: props.picture2 === "assets/picturesAndVideos/historical/doctolib" ? "0px" : "80px", left: "5%", zIndex: reversed ? -1 : 0}}>
                        <Box className={inViewRefImg1 ? "opacityDisplayAnim3" : "noOpacity"}>
                            <MyImage src={require('../'+props.picture2+'.webp')} style={{maxHeight: "350px"}}/>
                        </Box>
                    </Parallax>}
                </Parallax>
            </Box>
        </Box>
    )
}

export default Historical;
import React, {useState} from "react";
import "../assets/css/constants.css"
import { Box } from "@mui/material";
import { MdCancel as BaseRightIcon} from "react-icons/md";

interface Props {
    setInput: (text: string) => void;
    placeholderColor?: string;
    backgroundColor?: string;
    borderBottom?: string;
    borderRadius?: string;
    borderRight?: string;
    fontFamily?: string;
    placeholder?: string;
    borderLeft?: string;
    borderTop?: string;
    minHeight?: string;
    fontSize?: string;
    minWidth?: string;
    padding?: string;
    opacity?: number;
    height?: string;
    margin?: string;
    cursor?: string;
    color?: string;
    width?: string;
    input: string;

    leftIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick?: () => void;
    };

    rightIcon?: {
        icon?: any;
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        noDefaultStyles?: boolean;
        onClick?: () => void;
    };

    rightIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };

    leftIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };
}

export const TextInput = (props: Props) => {
    const [rightIconHover, setRightIconHover] = useState(false);
    const [leftIconHover, setLeftIconHover] = useState(false);

    const style = {
        backgroundColor: props.backgroundColor || "var(--InputGrey)",
        borderRadius: props.borderRadius || "8px 8px 0 0",
        borderBottom: props.borderBottom || "1px solid var(--InputDarkGrey)",
        borderRight: props.borderRight || "none",
        borderLeft: props.borderLeft || "none",
        borderTop: props.borderTop || "none",
        padding: props.padding || (props.leftIcon && props.leftIcon.icon ? "10px 30px 10px 30px" : "10px 30px 10px 20px"),
        cursor: props.cursor || "text",
        color: props.color || "var(--InputDarkGrey)",
        placeholderColor: props.placeholderColor || "inherit",
        fontFamily: props.fontFamily || "inherit",
        fontSize: props.fontSize || "inherit",
        minHeight: props.minHeight || "auto",
        minWidth: props.minWidth || "auto",
        height: props.height || "auto",
        margin: props.margin || "0",
        width: props.width || "auto",
        outline: "none",
    };

    const rightIconStyle = {
        backgroundColor: (props.rightIcon && props.rightIcon.backgroundColor) || "transparent",
        padding: (props.rightIcon && props.rightIcon.padding) || "0",
        cursor: (props.rightIcon && props.rightIcon.cursor) || "pointer",
        color: (props.rightIcon && props.rightIcon.color) || "var(--InputDarkGrey)",
        size: (props.rightIcon && props.rightIcon.size) || "inherit",
        minHeight: (props.rightIcon && props.rightIcon.minHeight) || "auto",
        minWidth: (props.rightIcon && props.rightIcon.minWidth) || "auto",
        height: (props.rightIcon && props.rightIcon.height) || "auto",
        margin: (props.rightIcon && props.rightIcon.margin) || "0 0 -2 -25",
        width: (props.rightIcon && props.rightIcon.width) || "auto",
        opacity: (props.rightIcon && props.rightIcon.opacity) || 1,
        visibility: props.rightIcon && props.rightIcon.icon ? props.rightIcon?.noDefaultStyles ? "visible" : props.input !== "" ? "visible" : "hidden" : props.rightIcon?.noDefaultStyles ? "hidden" : props.input !== "" ? "visible" : "hidden",
        outline: "none"
    };

    const rightIconHoverStyle = {
        backgroundColor: (props.rightIconHover && props.rightIconHover.backgroundColor) || "transparent",
        padding: (props.rightIconHover && props.rightIconHover.padding) || "0",
        cursor: (props.rightIconHover && props.rightIconHover.cursor) || "pointer",
        color: (props.rightIconHover && props.rightIconHover.color) || props.rightIcon?.noDefaultStyles ? "var(--InputDarkGrey)" : "red",
        size: (props.rightIconHover && props.rightIconHover.size) || "inherit",
        minHeight: (props.rightIconHover && props.rightIconHover.minHeight) || "auto",
        minWidth: (props.rightIconHover && props.rightIconHover.minWidth) || "auto",
        height: (props.rightIconHover && props.rightIconHover.height) || "auto",
        margin: (props.rightIconHover && props.rightIconHover.margin) || "0 0 -2 -25",
        width: (props.rightIconHover && props.rightIconHover.width) || "auto",
        opacity: (props.rightIconHover && props.rightIconHover.opacity) || 1,
        visibility: props.rightIcon && props.rightIcon.icon ? props.rightIcon?.noDefaultStyles ? "visible" : props.input !== "" ? "visible" : "hidden" : props.rightIcon?.noDefaultStyles ? "hidden" : props.input !== "" ? "visible" : "hidden",
        outline: "none"
    };

    const leftIconStyle = {
        backgroundColor: (props.leftIcon && props.leftIcon.backgroundColor) || "transparent",
        padding: (props.leftIcon && props.leftIcon.padding) || "0",
        cursor: (props.leftIcon && props.leftIcon.cursor) || "pointer",
        color: (props.leftIcon && props.leftIcon.color) || "var(--InputDarkGrey)",
        size: (props.leftIcon && props.leftIcon.size) || "inherit",
        minHeight: (props.leftIcon && props.leftIcon.minHeight) || "auto",
        minWidth: (props.leftIcon && props.leftIcon.minWidth) || "auto",
        height: (props.leftIcon && props.leftIcon.height) || "auto",
        margin: (props.leftIcon && props.leftIcon.margin) || "0 -25px -2 0",
        width: (props.leftIcon && props.leftIcon.width) || "auto",
        opacity: (props.leftIcon && props.leftIcon.opacity) || 1,
        visibility: (props.leftIcon && props.leftIcon.icon) ? "visible" : "hidden",
        position: "relative" as "relative",
        zIndex: "1",
        outline: "none",
    };

    const leftIconHoverStyle = {
        backgroundColor: (props.leftIconHover && props.leftIconHover.backgroundColor) || "transparent",
        padding: (props.leftIconHover && props.leftIconHover.padding) || "0",
        cursor: (props.leftIconHover && props.leftIconHover.cursor) || "pointer",
        color: (props.leftIconHover && props.leftIconHover.color) || "var(--InputDarkGrey)",
        size: (props.leftIconHover && props.leftIconHover.size) || "inherit",
        minHeight: (props.leftIconHover && props.leftIconHover.minHeight) || "auto",
        minWidth: (props.leftIconHover && props.leftIconHover.minWidth) || "auto",
        height: (props.leftIconHover && props.leftIconHover.height) || "auto",
        margin: (props.leftIconHover && props.leftIconHover.margin) || "0 -25px -2 0",
        width: (props.leftIconHover && props.leftIconHover.width) || "auto",
        opacity: (props.leftIconHover && props.leftIconHover.opacity) || 1,
        position: "relative" as "relative",
        zIndex: "1",
        outline: "none"
    };

    const LeftIcon = (props.leftIcon && props.leftIcon.icon) || BaseRightIcon;
    const RightIcon = (props.rightIcon && props.rightIcon.icon) || BaseRightIcon;

    const rightIcon = <RightIcon style={rightIconHover ? rightIconHoverStyle : rightIconStyle} onMouseEnter={() => setRightIconHover(true)} onMouseLeave={() => setRightIconHover(false)} onClick={() => {if (props.rightIcon?.onClick) props.rightIcon.onClick(); else if (!props.rightIcon?.noDefaultStyles) props.setInput("")}}/>
    const leftIcon = <LeftIcon style={leftIconHover ? leftIconHoverStyle : leftIconStyle} onMouseEnter={() => setLeftIconHover(true)} onMouseLeave={() => setLeftIconHover(false)} onClick={() => {props.leftIcon?.onClick && props.leftIcon.onClick()}}/>;

    return (
        <Box>
            {leftIcon}
            <input value={props.input} style={style} placeholder={props.placeholder} onChange={(e) => {props.setInput(e.target.value)}}/>
            {rightIcon}
        </Box>
    )
};

export default TextInput;
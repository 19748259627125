import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { wait } from "@testing-library/user-event/dist/utils";
import { push as BurgerMenu } from "react-burger-menu";
import { BsCaretDownFill as CarretDownIcon, BsCaretRightFill as CarretRightIcon } from 'react-icons/bs';
import { FiMenu as BurgerIcon } from 'react-icons/fi';
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import { MdOutlineCancel as CancelIcon } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';
import "../assets/css/header.css"
import WollenLogo from "../assets/picturesAndVideos/icons/carte_wollen.webp";
import DoctolibLogo from "../assets/picturesAndVideos/icons/doctolib.webp";
import SpaceLine from "../assets/picturesAndVideos/icons/line_header.webp";
import GermanFlagLogo from "../assets/picturesAndVideos/icons/allemagne.webp";
import FrenchFlagLogo from "../assets/picturesAndVideos/icons/france.webp";
import EnglishFlagLogo from "../assets/picturesAndVideos/icons/angleterre.webp";
import InfoFlagLogo from "../assets/picturesAndVideos/icons/info.webp";
import HeaderJson from "../assets/jsons/header.json"
import ConstantsJson from "../assets/jsons/constants.json"
import InfosJson from "../assets/MaximeCanChange/infos.json"
import {toast} from "react-toastify";
import MyText from "./MyText";
import {Flex} from "rebass";
import toastSuccess from "./ToastSuccess";
import toastInfo from "./ToastInfo";
import MyDialog from "./MyDialog";
import moment from 'moment'

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
    setLanguage: (language: number) => void;
    isSearching: boolean;
    setIsSearching: (isSearching: boolean) => void;
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    searchResult: {string: string, link: string}[]
}

const Header = (props: Props) => {
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
    const [closeAllSubmenus, setCloseAllSubmenus] = useState(false);
    const [overlay, setOverlay] = useState(InfosJson.displayByDefault === "yes");
    const navigation = HeaderJson.availableLanguages[props.language]["navigation"];
    const [activeMenu, setActiveMenu] = useState('none');
    const [elementHeight, setElementHeight] = useState(0)
    const [infoOpen, setInfoOpen] = useState(InfosJson.displayByDefault === "yes")
    const navigate = useNavigate();
    const date = new Date()

    const closePrecedentToasts = () => {
        toast.dismiss();
    }

    const delay = (ms: number) => {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    const dateCheck = (start: string, end: string) : boolean => {
        const defaultDateFormat = 'DD-MM-YYYY';
        const dateFormat = 'YYYY-MM-DD';
        const newEnd = moment(end, defaultDateFormat).format(dateFormat)
        const newStart = moment(start, defaultDateFormat).format(dateFormat)
        const today = moment(date, defaultDateFormat).format(dateFormat)

        return moment(today).isBetween(newStart, newEnd, undefined, "[]");
    }

    useEffect(() => {
        printIsInHolliday()
    }, [])

    const printIsInHolliday = async () => {
        if (InfosJson.hollidaysStart !== "" && InfosJson.hollidaysEnd !== "" && InfosJson.display === "yes" && dateCheck(InfosJson.hollidaysStart, InfosJson.hollidaysEnd)) {
            await delay(2000)
            alert(InfosJson.availableLanguages[props.language].hollidaysInfo1+" "+InfosJson.hollidaysEnd+" "+InfosJson.availableLanguages[props.language].hollidaysInfo2)
        }
    }

    useEffect(() => {
        if (!overlay)
            setInfoOpen(false)
    }, [overlay]);

    useEffect(() => {
        if (infoOpen)
            document.body.style.overflow = "hidden"
        else
            document.body.style.overflow = "visible"
    }, [infoOpen])

    useEffect(() => {
        if (props.windowWidth > ConstantsJson.MiddleScreenStartWidth) {
            setIsBurgerMenuOpen(false);
        }
    }, [props.windowWidth]);

    useEffect(() => {
        props.searchValue === "" ? props.setIsSearching(false) : props.setIsSearching(true);
        if (document!.getElementById('myID') !== null)
            setElementHeight(document!.getElementById('myID')!.offsetHeight)
        else
            setElementHeight(0)
    }, [props, props.searchValue]);

    const closeAllSubmenusFunction = () => {
        setCloseAllSubmenus(true);
    }

    const resetCloseAllSubmenusFunction = () => {
        setCloseAllSubmenus(false);
    }

    const showOverlay = () => {
        setOverlay(true);
    }

    const notShowOverlay = () => {
        setOverlay(false)
    }

    return (
        <Box>
            {/* Top Space Box */}
            <Box className={"topSpaceBox"}/>
            {/* Screen Overlay */}
            {overlay ? <Box className={"overlay"} sx={{height: props.windowHeight, width: props.windowWidth, cursor: "pointer", opacity: "60%", zIndex: "1"}} onClick={() => {if (isBurgerMenuOpen) setIsBurgerMenuOpen(false); notShowOverlay(); closeAllSubmenusFunction(); props.setSearchValue(""); setInfoOpen(false)}}/> : null}
            {/* Language Flags */}
            <Box className={"languageFlagsBox"}>
                <img loading={"lazy"} src={EnglishFlagLogo} alt={"English Logo"} style={{left: "10px"}} className={"languageFlag"} onClick={() => {props.setLanguage(0); closePrecedentToasts(); if (props.language === 0) toastInfo("English language is already set", "bottom-right", 2000, true, true, true); else toastSuccess("The language has been set to English", "bottom-right", 2000, true, true, true)}}/>
                <img loading={"lazy"} src={FrenchFlagLogo} alt={"French Logo"} style={{left: "40px"}} className={"languageFlag"} onClick={() => {props.setLanguage(1); closePrecedentToasts(); if (props.language === 1) toastInfo("La langue est déjà en français", "bottom-right", 2000, true, true, true); else toastSuccess("La langue Française est appliquée", "bottom-right", 2000, true, true, true)}}/>
                <img loading={"lazy"} src={GermanFlagLogo} alt={"German Logo"} style={{left: "70px"}} className={"languageFlag"} onClick={() => {props.setLanguage(2); closePrecedentToasts(); if (props.language === 2) toastInfo("Die Sprache is bereits auf Deutsch", "bottom-right", 2000, true, true, true); else toastSuccess("Die Sprache wurde auf Deutsch eingestellt", "bottom-right", 2000, true, true, true)}}/>
            </Box>
            {InfosJson.availableLanguages[props.language].infos.length !== 0 && InfosJson.display === "yes" && !isBurgerMenuOpen && <Flex style={{position: "fixed", zIndex: 16, right: "45px", top: "100px"}}>
                <img loading={"lazy"} src={InfoFlagLogo} alt={"Info Logo"} className={"languageFlag2"} onClick={() => {setInfoOpen(!infoOpen); setOverlay(!infoOpen)}}/>
                <MyText value={InfosJson.availableLanguages[props.language].message} className={"availableInfosAnim noOpacity"} style={{position: "absolute", right: "10px", width: "max-content", top: "2px", color: "var(--LightBlue)", fontFamily: "Muli-SemiBold"}}/>
            </Flex>}
            {infoOpen && overlay && <MyDialog onClose={() => {setInfoOpen(false); setOverlay(false)}} language={props.language}/>}
            {/* Header part */}
            <Box className={"header headerShadow"} sx={{position: "fixed", width: "100%", top: "0"}}>
                {/* Wollenschneider Logo */}
                <img loading={"lazy"} src={WollenLogo} alt={"Logo"} className={"header wollenLogo leftToRightDisplayAnim1"} onClick={() => {navigate(HeaderJson.wollenLogoLink); setIsBurgerMenuOpen(false)}}/>
                {/* Navigation part Mobile and Standard */}
                {(props.windowWidth <= ConstantsJson.MiddleScreenStartWidth) && (
                        <Box>
                            {/* Menu button */}
                            {props.windowWidth >= 375 && !isBurgerMenuOpen ? <img loading={"lazy"} src={DoctolibLogo} alt={"Doctolib Logo"} className={"doctolibLogo"} style={isBurgerMenuOpen ? {right: "260px"} : {right: "100px"}} onClick={() => {window.location.assign(HeaderJson.doctolibLink)}}/> : null}
                            <Box className={"menuButtonBox"} onClick={() => {setIsBurgerMenuOpen(true); showOverlay(); setActiveMenu("tempNone")}}>
                                <text className={"menuButton rightToLeftDisplayAnim1"}>Menu</text>
                                <BurgerIcon className={"burgerMenuIcon rightToLeftDisplayAnim1"}/>
                            </Box>
                            {/* Burger Menu */}
                            <BurgerMenu width={'250px'} right={true} noOverlay={true} isOpen={isBurgerMenuOpen} onOpen={() => {setIsBurgerMenuOpen(true); showOverlay()}} onClose={() => {setIsBurgerMenuOpen(false); notShowOverlay(); props.setSearchValue("")}} styles={stylesBurgerMenu}>
                                <Box onClick={closeAllSubmenusFunction} className={"burgerMenu"} style={{paddingBottom: props.windowHeight}}>
                                    <text className={"menuButtonInBurgerMenu"}>Menu</text>
                                    <Box className={"searchBox"}>
                                        <SearchIcon className={"buttonImageSearch"}/>
                                        <input className={"search"} placeholder={HeaderJson.availableLanguages[props.language].searchPlaceHolder} value={props.searchValue} onChange={element => props.setSearchValue(element.target.value)}/>
                                        <CancelIcon style={props.isSearching ? {opacity: "100%", cursor: "pointer"} : {opacity: "0%", cursor: "default"}} className={"buttonImageCancel"} onClick={() => {props.setIsSearching(false); props.setSearchValue("")}}/>
                                        {props.searchValue !== "" && <Box id={"myID"} style={{position: "absolute", background: "white", borderLeft: "1px solid var(--Grey)", borderRight: "1px solid var(--Grey)", borderBottom: "1px solid var(--Grey)", width: "45%", padding: "8px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", left: "18%"}}>
                                            {props.searchResult.map((item, index) => (
                                                <MyText onClick={() => {if (item.string === "Doctolib") window.location.assign(HeaderJson.doctolibLink); else if (item.link === "infos") {setInfoOpen(true); setOverlay(true)} else {navigate(item.link); if (item.link !== "infos") notShowOverlay()} props.setSearchValue(""); setIsBurgerMenuOpen(false)}} value={item.string} className={"goto"} style={index === 0 ? {} : {marginTop: "5px"}}/>
                                            ))}
                                        </Box>}
                                    </Box>
                                    {props.windowWidth < 375 ? <img loading={"lazy"} src={DoctolibLogo} alt={"Doctolib Logo"} className={"doctolibLogoMinScreenSize"} onClick={() => {window.location.assign(HeaderJson.doctolibLink)}} style={{marginTop: "20px"}}/> : null}
                                    <DropdownMenuMobile items={navigation} closeAllSubmenusFunction={closeAllSubmenusFunction} middleScreenStartWidth={ConstantsJson.MiddleScreenStartWidth} windowWidth={props.windowWidth} notShowOverlay={notShowOverlay} activeMenu={activeMenu} setActiveMenu={setActiveMenu} elementHeight={elementHeight} setIsBurgerMenuOpen={setIsBurgerMenuOpen}/>
                                </Box>
                            </BurgerMenu>
                        </Box>
                    ) || (
                        <Box className={"header"}>
                            <Box className={"header"}>
                                {navigation.map((button, index) => {
                                    return (
                                        <MenuItem key={index} button={button} spaceLine={true} windowWidth={props.windowWidth} language={props.language} middleScreenStartWidth={ConstantsJson.MiddleScreenStartWidth} close={closeAllSubmenus} closeAllSubmenusFunction={closeAllSubmenusFunction} resetCloseAllSubmenusFunction={resetCloseAllSubmenusFunction} showOverlay={showOverlay} notShowOverlay={notShowOverlay} index={index} setIsBurgerMenuOpen={setIsBurgerMenuOpen}/>
                                    )
                                })}
                                <img loading={"lazy"} src={DoctolibLogo} alt={"Doctolib Logo"} className={"header doctolibLogo"} onClick={() => {window.location.assign(HeaderJson.doctolibLink)}}/>
                            </Box>
                            <Flex flexDirection={"column"}>
                                <Box className={"searchBox rightToLeftDisplayAnim1"}>
                                    <SearchIcon className={"buttonImageSearch"}/>
                                    <input className={"search"} placeholder={HeaderJson.availableLanguages[props.language].searchPlaceHolder} value={props.searchValue} onChange={element => props.setSearchValue(element.target.value)}/>
                                    <CancelIcon style={props.isSearching ? {opacity: "100%", cursor: "pointer"} : {opacity: "0%", cursor: "default"}} className={"buttonImageCancel"} onClick={() => {props.setIsSearching(false); props.setSearchValue("")}}/>
                                    {props.searchValue !== "" && <Box style={{position: "absolute", background: "white", borderLeft: "1px solid var(--Grey)", borderRight: "1px solid var(--Grey)", borderBottom: "1px solid var(--Grey)", width: "85%", padding: "8px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", left: "5.5%", top: "95%"}}>
                                        {props.searchResult.map((item, index) => (
                                            <MyText onClick={() => {if (item.string === "Doctolib") window.location.assign(HeaderJson.doctolibLink); else if (item.link === "infos") {setInfoOpen(true); setOverlay(true)} else {navigate(item.link); notShowOverlay()} props.setSearchValue(""); setIsBurgerMenuOpen(false); if (item.link !== "infos") notShowOverlay()}} value={item.string} className={"goto"} style={index === 0 ? {} : {marginTop: "5px"}}/>
                                        ))}
                                    </Box>}
                                </Box>
                            </Flex>
                        </Box>
                )}
            </Box>
        </Box>
    );
};

type MenuItemProps = {
    button: {name: string, link: string, dropdown: {name: string, link: string}[]},
    spaceLine: boolean
    windowWidth: number
    language: number
    middleScreenStartWidth: number
    close: boolean
    closeAllSubmenusFunction: () => void
    resetCloseAllSubmenusFunction: () => void
    showOverlay: () => void
    notShowOverlay: () => void
    index: number
    setIsBurgerMenuOpen: any
};

type DropdownMenuProps = {
    items: {name: string, link: string}[]
    closeAllSubmenusFunction: () => void;
    middleScreenStartWidth: number
    windowWidth: number
    notShowOverlay: () => void
    setIsBurgerMenuOpen: any
};

type DropdownMenuMobileProps = {
    items: {name: string, link: string, dropdown: {name: string, link: string, videoLink: string}[]}[]
    closeAllSubmenusFunction: () => void;
    middleScreenStartWidth: number
    windowWidth: number
    notShowOverlay: () => void
    activeMenu: string
    setActiveMenu: (menu: string) => void
    elementHeight: number
    setIsBurgerMenuOpen: any
};

function MenuItem(props: MenuItemProps) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(props.close);
    const Scroll = require('react-scroll');
    const scroll = Scroll.animateScroll;
    const windowLocation = (window.location.pathname.split("/").length - 1) === 1 ? window.location.pathname : window.location.pathname.substring(0, window.location.pathname.indexOf("/", 1));

    if (props.close && open)
        setOpen(false);

    props.resetCloseAllSubmenusFunction()

    return (
        <Box className={"header itemBox opacityDisplayAnim"+(props.index+2)}>
            <Box style={props.button.link === windowLocation ? {background: "var(--HeaderSelection)", color: "var(--Green)", height: "var(--HeaderHeight)", transition: "0.2s ease"} : {}}>
                <Box style={props.button.link === windowLocation ? {background: "var(--Green)", height: "5px", borderRadius: "0 0 5px 5px"} : {}}>
                    <Box style={{display: "flex", alignItems: "center", height: "var(--HeaderHeight)", transition: "0.2s ease"}}>
                        <MyText value={props.button.name} className={(props.button.name === "Contact" || props.button.name === "Kontact") ? "contact" : "item"} style={(props.button.name === "Contact" || props.button.name === "Kontact") ? {marginLeft: "10px", marginRight: "10px", width: "max-content"} : {height: "100%", lineHeight: "80px", width: "max-content"}} onClick={() => {if (!props.close) {props.closeAllSubmenusFunction(); props.notShowOverlay()} if (props.button.dropdown.length !== 0) {open ? props.notShowOverlay() : props.showOverlay(); wait(2).then(() => setOpen(!open))} else {navigate(props.button.link); props.button.link === windowLocation ? scroll.scrollToTop() : window.scrollTo(0, 0)}}}/>
                        {props.button.dropdown.length !== 0 ? <CarretDownIcon className={"carretDownLogo"}/> : null}
                    </Box>
                </Box>
            </Box>
            {props.button.name !== HeaderJson.availableLanguages[props.language]["navigation"][5].name && props.button.name !== HeaderJson.availableLanguages[props.language]["navigation"][6].name && props.spaceLine && <img loading={"lazy"} src={SpaceLine} alt={"Space Line"} className={"spaceLine"} style={{pointerEvents: "none"}}/>}

            {open && props.button.dropdown.length !== 0 && <DropdownMenu items={props.button.dropdown} closeAllSubmenusFunction={props.closeAllSubmenusFunction}  windowWidth={props.windowWidth} middleScreenStartWidth={props.middleScreenStartWidth} notShowOverlay={props.notShowOverlay} setIsBurgerMenuOpen={props.setIsBurgerMenuOpen}/>}
        </Box>
    );
}

function DropdownMenu(props: DropdownMenuProps) {
    const activeMenu = 'main';
    const navigate = useNavigate();
    const Scroll = require('react-scroll');
    const scroll = Scroll.animateScroll;
    const windowLocation = window.location.pathname;

    return (
        <div className="dropdown">
            <CSSTransition
                in={activeMenu === 'main'}
                timeout={500}
                unmountOnExit>
                <Box>
                    {props.items.map((item) => {
                        return (
                            <a className="menu-item" onClick={() => {navigate(item.link); item.link === windowLocation ? scroll.scrollToTop() : window.scrollTo(0, 0); if (props.windowWidth > props.middleScreenStartWidth) props.notShowOverlay(); props.closeAllSubmenusFunction()}}>{item.name}<CarretRightIcon style={{marginLeft: "1px", marginTop: "1px"}} className={"carretDownLogo"}/></a>
                        )
                    })}
                </Box>
            </CSSTransition>
        </div>
    );
}

function DropdownMenuMobile(props: DropdownMenuMobileProps) {
    const navigate = useNavigate();
    const Scroll = require('react-scroll');
    const scroll = Scroll.animateScroll;
    const windowLocation = window.location.pathname;
    const [menuHeight, setMenuHeight] = useState(0);
    const dropdownRef = useRef(null);
    const [secondaryItems, setSecondaryItems] = useState([] as {name: string, link: string, videoLink: string}[]);

    function calcHeight(el: { offsetHeight: any; }) {
        const height = el.offsetHeight;
        setMenuHeight(height+50);
    }

    return (
        <div className="dropdown" style={{ height: menuHeight, marginTop: props.elementHeight }} ref={dropdownRef}>
            <CSSTransition
                in={props.activeMenu === 'tempNone'}
                timeout={500}
                unmountOnExit
                onEnter={() => props.setActiveMenu("main")}>
                <Box>
                </Box>
            </CSSTransition>

            <CSSTransition
                in={props.activeMenu === 'main'}
                timeout={500}
                unmountOnExit
                onEnter={calcHeight}>
                <Box>
                    {props.items.map((item, index) => {
                        return (
                            <a className="menu-item" onClick={() => {if (props.items[index].dropdown.length !== 0) {props.setActiveMenu("secondary"); setSecondaryItems(props.items[index].dropdown)} else {navigate(item.link); props.setIsBurgerMenuOpen(false); props.notShowOverlay()} item.name === windowLocation ? scroll.scrollToTop() : window.scrollTo(0, 0); if (props.windowWidth > props.middleScreenStartWidth) props.notShowOverlay(); props.closeAllSubmenusFunction()}}>{item.name}{props.items[index].dropdown.length !== 0 && <CarretRightIcon style={{marginLeft: "1px", marginTop: "1px"}} className={"carretDownLogo"}/>}</a>
                        )
                    })}
                </Box>
            </CSSTransition>

            <CSSTransition
                in={props.activeMenu === 'secondary'}
                timeout={500}
                unmountOnExit
                onEnter={calcHeight}>
                <Box>
                    <a className="menu-item" style={{transform: "translateX(-13px)"}} onClick={() => {props.setActiveMenu("main")}}><CarretRightIcon style={{marginLeft: "1px", marginTop: "1px", rotate: "180deg"}} className={"carretDownLogo"}/>Retour</a>
                    {secondaryItems.map((item) => {
                        return (
                            <a className="menu-item" onClick={() => {navigate(item.link); props.notShowOverlay(); props.setIsBurgerMenuOpen(false); item.link === windowLocation ? scroll.scrollToTop() : window.scrollTo(0, 0); if (props.windowWidth > props.middleScreenStartWidth) props.closeAllSubmenusFunction()}}>{item.name}</a>
                        )
                    })}
                </Box>
            </CSSTransition>
            <img loading={"lazy"} src={DoctolibLogo} alt={"Doctolib Logo"} className={"doctolibLogoMinScreenSize"} style={{marginLeft: "12px", marginTop: "15px"}} onClick={() => {window.location.assign(HeaderJson.doctolibLink)}}/>
        </div>
    );
}

const stylesBurgerMenu = {
    bmBurgerButton: {
        position: "fixed",
        width: "36px",
        height: "36px",
        right: "10px",
        top: "-160px"
    },
    bmCrossButton: {
        marginTop: "15px",
        marginRight: "30px",
    },
    bmCross: {
        marginRight: "30px",
        background: "black"
    },
    bmMenuWrap: {
        position: "fixed",
        top: "0",
        boxShadow: "0px 0 16px 0px rgba(0, 0, 0, 0.2)",
        background: "var(--White)",
    },
    bmMenu: {
        position: "fixed",
        top: "0",
        background: "var(--White)",
    },
    bmMorphShape: {
        fill: "var(--White)",
        outline: "none"
    },
}

export default Header;

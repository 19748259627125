import React from "react";
import { Button } from 'theme-ui'

interface Props {
    value: string
    onClick?: () => void;
    style?: object;
    styleHovered?: object;
}

export const MyButton = (props: Props) => {
    const [hovered, setHovered] = React.useState(false);

    return <Button
        sx={{
            color: "myWhite",
            background: "myGreen",
            padding: "8px 25px 8px 25px",
            transition: "all 0.2s ease",
            fontFamily: "myRegular",
            fontSize: "myMid",
            border: "2px solid #01ac10",
            borderRadius: "50px",
            "&:hover": {
                border: "2px solid lightGreen",
                cursor: "pointer"
            }
        }}
        style={hovered ? props.styleHovered : props.style}
        onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} onClick={props.onClick}>
        {props.value}
    </Button>
};

export default MyButton;
import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import sendEmail from "../components/SendMail";
import PageTitle from "../components/PageTitle";
import MyInputText from "../components/MyInputText";
import MyInputTextArea from "../components/MyInputTextArea";
import "../assets/css/contact.css"
import "../assets/css/constants.css"
import toastError from "../components/ToastError";
import {toast} from "react-toastify";
import toastWarning from "../components/ToastWarning";
import MyCheckbox from "../components/MyCheckbox";
import MyRadio from "../components/MyRadio";
import ContactJson from "../assets/jsons/contact.json"
import ConstantsJson from "../assets/jsons/constants.json"
import HomeJson from "../assets/jsons/home.json"
import HeaderJson from "../assets/jsons/header.json"
import {Checkbox, Link} from "theme-ui";
import {Flex} from "rebass"
import {useNavigate} from "react-router-dom";
import SendButton from "../components/SendButton";
import MyText from "../components/MyText";
import {useInView} from "react-intersection-observer";
import WireImg from "../assets/picturesAndVideos/contact/wire.webp"
import FootImg from "../assets/picturesAndVideos/contact/foot.webp"
import { GrMail as MailIcon } from "react-icons/gr";
import { BsTelephoneFill as TelIcon } from "react-icons/bs";
import Footer from "../components/Footer";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const Contact = (props: Props) => {
    const [firstName, setFirstName] = React.useState("");
    const [firstNameStatus, setFirstNameStatus] = React.useState(0);
    const [firstNameFocus, setFirstNameFocus] = React.useState(0);
    const [lastName, setLastName] = React.useState("");
    const [lastNameStatus, setLastNameStatus] = React.useState(0);
    const [lastNameFocus, setLastNameFocus] = React.useState(0);
    const [phone, setPhone] = React.useState("");
    const [phoneStatus, setPhoneStatus] = React.useState(0);
    const [phoneFocus, setPhoneFocus] = React.useState(0);
    const [email, setEmail] = React.useState("");
    const [emailStatus, setEmailStatus] = React.useState(0);
    const [emailFocus, setEmailFocus] = React.useState(0);
    const [message, setMessage] = React.useState("");
    const [messageStatus, setMessageStatus] = React.useState(0);
    const [messageFocus, setMessageFocus] = React.useState(0);
    const [patientStatus, setPatientStatus] = React.useState(-1);
    const [patientStatusStatus, setPatientStatusStatus] = React.useState(0);
    const [subject, setSubject] = React.useState([] as {value: string, index: number, checked: boolean}[]);
    const [subjectStatus, setSubjectStatus] = React.useState(0);
    const [concernedOffice, setConcernedOffice] = React.useState([] as {value: string, index: number, checked: boolean}[])
    const [concernedOfficeStatus, setConcernedOfficeStatus] = React.useState(0)
    const [consent, setConsent] = React.useState(false)
    const [consentStatus, setConsentStatus] = React.useState(0)
    const [state, setState] = useState("initial")
    const toastId = React.useRef(null);
    const navigate = useNavigate()
    const Scroll = require("react-scroll")
    const scroller = Scroll.scroller
    const mapLanguage = [ "en", "fr", "de"]

    const [refDeco, inViewRefDeco] = useInView({triggerOnce: true})

    const [ref11, inViewRef11] = useInView({triggerOnce: true})
    const [ref22, inViewRef22] = useInView({triggerOnce: true})
    const [ref33, inViewRef33] = useInView({triggerOnce: true})
    const [ref44, inViewRef44] = useInView({triggerOnce: true})

    const [ref0, inViewRef0] = useInView({triggerOnce: true})
    const [ref1, inViewRef1] = useInView({triggerOnce: true})
    const [ref2, inViewRef2] = useInView({triggerOnce: true})
    const [ref3, inViewRef3] = useInView({triggerOnce: true})
    const [ref4, inViewRef4] = useInView({triggerOnce: true})
    const [ref5, inViewRef5] = useInView({triggerOnce: true})
    const [ref6, inViewRef6] = useInView({triggerOnce: true})
    const [ref7, inViewRef7] = useInView({triggerOnce: true})
    const [ref8, inViewRef8] = useInView({triggerOnce: true})
    const [ref9, inViewRef9] = useInView({triggerOnce: true})

    useEffect(() => {
        if (firstName && firstName.length !== 0)
            setFirstName(firstName.at(0)!.toUpperCase() + firstName.slice(1));
        if (lastName && lastName.length !== 0)
            setLastName(lastName.at(0)!.toUpperCase() + lastName.slice(1))
        if (message && message.length !== 0)
            setMessage(message.at(0)!.toUpperCase() + message.slice(1))
    }, [firstName, lastName, message]);

    useEffect(() => {
        setFirstNameStatus(0)
        setLastNameStatus(0)
        setEmailStatus(0)
        setPhoneStatus(0)
        setMessageStatus(0)
    }, []);

    useEffect(() => {
        setPatientStatusStatus(0)
        check("patientStatus", toastId, false)
    }, [patientStatus]);

    useEffect(() => {
        setConcernedOfficeStatus(0)
        check("concernedOffice", toastId, false)
    }, [concernedOffice]);

    useEffect(() => {
        setSubjectStatus(0)
        check("subject", toastId, false)
    }, [subject]);

    useEffect(() => {
        if (firstNameFocus !== 0)
            check("firstname", toastId, false)
    }, [firstNameFocus]);

    useEffect(() => {
        if (lastNameFocus !== 0)
            check("lastname", toastId, false)
    }, [lastNameFocus]);

    useEffect(() => {
        if (emailFocus !== 0)
            check("email", toastId, false)
    }, [emailFocus]);

    useEffect(() => {
        if (phoneFocus !== 0)
            check("phone", toastId, false)
    }, [phoneFocus]);

    useEffect(() => {
        if (messageFocus !== 0)
            check("message", toastId, false)
    }, [messageFocus]);

    useEffect(() => {
        setConsentStatus(0)
        check("consent", toastId, false)
    }, [consent]);

    const closePrecedentToasts = (toastId: any) => {
        toast.dismiss(toastId.current);
    }

    const check = (check: string, toastId: any, display: boolean) => {
        if (check === "all" && patientStatus === -1 && subject.length === 0 && concernedOffice.length === 0 && firstName === "" && lastName === "" && phone === "" && email === "" && message === "" && !consent) {
            closePrecedentToasts(toastId)
            if (display)
                toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorAll, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center")
            setFirstNameStatus(1);
            setLastNameStatus(1);
            setPhoneStatus(1);
            setMessageStatus(1);
            setConcernedOfficeStatus(1)
            setSubjectStatus(1)
            setPatientStatusStatus(1)
            setConsentStatus(1)
            scroller.scrollTo("patientStatus", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -100,
            })
            return false;
        }

        if (check === "all" || check === "patientStatus") {
            if (patientStatus === -1) {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorPatientStatus, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                if (check === "all") {
                    setPatientStatusStatus(1);
                    scroller.scrollTo("patientStatus", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                }
                return false;
            } else if (patientStatus !== -1)
                setPatientStatusStatus(3)
        }

        if (check === "all" || check === "concernedOffice") {
            if (concernedOffice.length === 0) {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorConcernedOffice, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                if (check === "all") {
                    setConcernedOfficeStatus(1);
                    scroller.scrollTo("concernedOffice", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -70,
                    })
                }
                return false;
            } else if (concernedOffice.length !== 0)
                setConcernedOfficeStatus(3)
        }

        if (check === "all" || check === "subject") {
            if (subject.length === 0) {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorSubject, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                if (check === "all") {
                    setSubjectStatus(1);
                    scroller.scrollTo("subject", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -60,
                    })
                }
                return false;
            } else if (subject.length !== 0)
                setSubjectStatus(3)
        }

        if (check === "all" || check === "firstname") {
            if ((firstNameFocus === 2 || check === "all") && firstName === "") {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorFirstName, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                setFirstNameStatus(1);
                if (check === "all")
                    scroller.scrollTo("firstName", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                return false;
            } else if (firstNameFocus === 2 && firstName !== "")
                setFirstNameStatus(3)
            else
                setFirstNameStatus(0)
        }

        if (check === "all" || check === "lastname") {
            if ((lastNameFocus === 2 || check === "all") && lastName === "") {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorLastName, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                setLastNameStatus(1);
                if (check === "all")
                    scroller.scrollTo("lastName", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                return false;
            } else if (lastNameFocus === 2 && lastName !== "")
                setLastNameStatus(3)
            else
                setLastNameStatus(0)
        }

        if (check === "all" || check === "phone") {
            if ((phoneFocus === 2 || check === "all") && phone === "") {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorPhone, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                setPhoneStatus(1);
                if (check === "all")
                    scroller.scrollTo("phone", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                return false;
            } else if (lastNameFocus === 2 && lastName !== "")
                setPhoneStatus(3)
            else
                setPhoneStatus(0)
        }

        if (check === "all" || check === "phone") {
            if (phoneFocus === 2 && phone !== "" && !phone.replaceAll(" ", "").match("^((\\+)33|0)[1-9](\\d{2}){4}$")) {
                closePrecedentToasts(toastId)
                toastId.current = toastError(ContactJson.availableLanguages[props.language].errorPhoneValid, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                setPhoneStatus(2);
                if (check === "all")
                    scroller.scrollTo("phone", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                return false;
            } else if (phoneFocus === 2 && phone !== "" && phone.replaceAll(" ", "").match("^((\\+)33|0)[1-9](\\d{2}){4}$"))
                setPhoneStatus(3)
            else
                setPhoneStatus(0)
        }

        if (check === "all" || check === "email") {
            if (emailFocus === 2 && email !== "" && !email.match("(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])")) {
                closePrecedentToasts(toastId)
                toastId.current = toastError(ContactJson.availableLanguages[props.language].errorEmailValid, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                setEmailStatus(2);
                if (check === "all")
                    scroller.scrollTo("email", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                return false;
            } else if (emailFocus === 2 && email !== "" && email.match("(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"))
                setEmailStatus(3)
            else
                setEmailStatus(0);
        }

        if (check === "all" || check === "message") {
            if ((messageFocus === 2 || check === "all") && message === "") {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorMessage, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                setMessageStatus(1);
                if (check === "all")
                    scroller.scrollTo("message", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -85,
                    })
                return false;
            } else if (messageFocus === 2 && message !== "")
                setMessageStatus(3)
            else
                setMessageStatus(0)
        }

        if (check === "all" || check === "consent") {
            if (!consent) {
                closePrecedentToasts(toastId)
                if (display)
                    toastId.current = toastWarning(ContactJson.availableLanguages[props.language].errorConsent, props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "top-right" : "top-center");
                if (check === "all") {
                    setConsentStatus(2);
                    scroller.scrollTo("consent", {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -100,
                    })
                }
                return false;
            }
        }
        return true;
    }

    const handleClick = () => {
        const PatientStatus = patientStatus === 0 ? "Non" : "Oui"
        const Mail = email === "" ? "Pas de mail renseigné" : email
        let Subject = ""
        let ConcernedOffice = ""

        const getIndex = (item: { value: any; index?: number; checked?: boolean; }) => {
            if (ContactJson.availableLanguages[0].subjectOptions.findIndex((e) => e === item.value) !== -1)
                return ContactJson.availableLanguages[0].subjectOptions.findIndex((e) => e === item.value)
            if (ContactJson.availableLanguages[1].subjectOptions.findIndex((e) => e === item.value) !== -1)
                return ContactJson.availableLanguages[1].subjectOptions.findIndex((e) => e === item.value)
            if (ContactJson.availableLanguages[2].subjectOptions.findIndex((e) => e === item.value) !== -1)
                return ContactJson.availableLanguages[2].subjectOptions.findIndex((e) => e === item.value)
            return -1
        }

        subject.map((item: {value: string, index: number, checked: boolean}, index) => {
            Subject += ContactJson.availableLanguages[1].subjectOptions.at(getIndex(item)) + (index < subject.length-1 ? ", " : "")
        })
        concernedOffice.map((item: {value: string, index: number, checked: boolean}, index) => {
            ConcernedOffice += item.value + (index < concernedOffice.length-1 ? ", " : "")
        })
        closePrecedentToasts(toastId)
        if (check("all", toastId, true)) {
            sendEmail(PatientStatus, Subject, ConcernedOffice, firstName, lastName, Mail, phone, message, ContactJson.availableLanguages[props.language].mailError, ContactJson.availableLanguages[props.language].mailSuccess, setState, props.windowWidth).then(r => {
                setPatientStatus(-1)
                setSubject([] as {value: string, index: number, checked: boolean}[])
                setConcernedOffice([] as {value: string, index: number, checked: boolean}[])
                setFirstName("");
                setPhone("");
                setEmail("");
                setMessage("");
                setConsent(false)
                setFirstNameStatus(0)
                setFirstNameFocus(0)
                setLastNameStatus(0)
                setLastNameFocus(0)
                setEmailStatus(0)
                setEmailFocus(0)
                setPhoneStatus(0)
                setPhoneFocus(0)
                setMessageStatus(0)
                setMessageFocus(0)
                setLastName("")
                return true
            })
        }
        return false
    }

    const getContactFormWidth = () => {
        if (props.windowWidth > ConstantsJson.MiddleScreenStartWidth)
            return "85%"
        if (props.windowWidth > ConstantsJson.MobileScreenEndWith)
            return "90%"
        return "90%"
    }

    return (
        <Box>
            <PageTitle title={ContactJson.availableLanguages[props.language].pageTitle} backgroundPicture={props.windowWidth > ConstantsJson.MobileScreenEndWith ? ContactJson.availableLanguages[props.language].backgroundPicture : ContactJson.availableLanguages[props.language].backgroundPictureMob} backgroundPositionY={"bottom"}/>
            <Flex flexDirection={props.windowWidth > 980 ? "row" : "column"}>
                <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px", marginBottom: props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "100px" : "0", width: props.windowWidth > 980 ? "50%" : "100%", position: "relative", left: props.windowWidth > 980 ? "20px" : "0"}}>
                    <Box style={{width: getContactFormWidth(), textAlign: "center", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column"}}>
                        <Box ref={ref11} className={inViewRef11 ? "fadeDisplayAnim1" : "noOpacity"} style={{textAlign: "center", marginBottom: "60px"}}>
                            <MyText as={"h2"} value={HomeJson.availableLanguages[props.language].sections[2].title} style={{fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}/>
                        </Box>
                        <Box ref={ref22} className={inViewRef22 ? "rightToLeftDisplayAnim2" : "noOpacity"} style={{borderRadius: "30px", background: "var(--LightGreen)", display: "flex", flexDirection: "column", color: "var(--Black)"}}>
                            <MyText value={HomeJson.sarralbe} style={{fontFamily: "Muli-Bold", fontSize: "var(--BiggerFontSize)", marginTop: "10px", color: "var(--Green)"}}/>
                            <Box className={"goto"} style={{marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "flex", fontSize: "var(--BigFontSize)"}} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[0].link); window.scrollTo(0, 0)}}>
                                <MyText as={"h3"} value={HomeJson.availableLanguages[props.language].sections[2].headOffice+" "} style={{fontFamily: "Muli-ExtraLight"}}/>
                                <MyText value={HomeJson.sarralbeStreet+" "} style={{fontFamily: "Muli-Light"}}/>
                                <MyText value={HomeJson.sarralbePostalCode+" "}/>
                                <MyText value={HomeJson.sarralbe} style={{fontFamily: "Muli-Bold"}}/>
                            </Box>
                            <Box className={"goto"} onClick={() => window.location.assign(`mailto:${HomeJson.mail}`)} style={{width: "max-content", marginLeft: "auto", marginRight: "auto", fontSize: "var(--BigFontSize)"}}>
                                <MailIcon size={Number("var(--SmallFontSize)")} style={{verticalAlign: "middle"}}/>
                                <MyText className={"goto"} value={HomeJson.mail} style={{marginLeft: "3px", fontSize: "var(--BigFontSize)", display: "inline-block"}}/>
                            </Box>
                            <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarralbeTel}`)} style={{width: "max-content", marginLeft: "auto", marginRight: "auto"}}>
                                <TelIcon size={Number("var(--BigFontSize)")} style={{verticalAlign: "middle"}}/>
                                <MyText className={"goto"} value={HomeJson.sarralbeTel} style={{marginLeft: "3px", fontSize: "var(--BigFontSize)", display: "inline-block"}}/>
                            </Box>
                            <iframe
                                title={"map"}
                                width="80%"
                                height={400}
                                style={{border: "4px solid var(--Grey)", borderRadius: "var(--MapRadius)", marginTop: "20px", marginBottom: "20px", maxHeight: "1080px", marginLeft: "auto", marginRight: "auto"}}
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyCHrlOWH6CaBe5GRfwyJSHNIjM38XtcR48&q=societe+wollenschneider+sarralbe&language=${mapLanguage[props.language]}`}>
                            </iframe>
                        </Box>
                        <Box ref={ref33} className={inViewRef33 ? "rightToLeftDisplayAnim2" : "noOpacity"}  style={{borderRadius: "30px", marginTop: "40px", background: "var(--LightGreen)", display: "flex", flexDirection: "column", color: "var(--Black)"}}>
                            <MyText as={"h3"} value={HomeJson.sarreguemines} style={{fontFamily: "Muli-Bold", fontSize: "var(--BiggerFontSize)", marginTop: "10px", color: "var(--Green)"}}/>
                            <Box className={"goto"} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[1].link); window.scrollTo(0, 0)}} style={{marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "flex", fontSize: "var(--BigFontSize)"}}>
                                <MyText value={HomeJson.sarregueminesStreet+" "} style={{fontFamily: "Muli-Light"}}/>
                                <MyText value={HomeJson.sarregueminesPostalCode+" "}/>
                                <MyText value={HomeJson.sarreguemines} style={{fontFamily: "Muli-Bold"}}/>
                            </Box>
                            <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.sarregueminesTel}`)} style={{width: "max-content", marginLeft: "auto", marginRight: "auto", }}>
                                <TelIcon size={Number("var(--BigFontSize)")} style={{verticalAlign: "middle"}}/>
                                <MyText className={"goto"} value={HomeJson.sarregueminesTel} style={{marginLeft: "3px", fontSize: "var(--MidFontSize)", display: "inline-block"}}/>
                            </Box>
                            <iframe
                                title={"map"}
                                width="80%"
                                height={400}
                                style={{border: "4px solid var(--Grey)", borderRadius: "var(--MapRadius)", marginTop: "20px", marginBottom: "20px", maxHeight: "1080px", marginLeft: "auto", marginRight: "auto"}}
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyCHrlOWH6CaBe5GRfwyJSHNIjM38XtcR48&q=societe+wollenschneider+sarreguemines&language=${mapLanguage[props.language]}`}>
                            </iframe>
                        </Box>
                        <Box ref={ref44} className={inViewRef44 ? "rightToLeftDisplayAnim2" : "noOpacity"}  style={{borderRadius: "30px", marginTop: "40px", background: "var(--LightGreen)", display: "flex", flexDirection: "column", color: "var(--Black)"}}>
                            <MyText as={"h3"} value={HomeJson.metz} style={{fontFamily: "Muli-Bold", fontSize: "var(--BiggerFontSize)", marginTop: "10px", color: "var(--Green)"}}/>
                            <Box className={"goto"} onClick={() => {navigate(HeaderJson.availableLanguages[props.language]["navigation"][3].dropdown[2].link); window.scrollTo(0, 0)}} style={{marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "flex", fontSize: "var(--BigFontSize)"}}>
                                <MyText value={HomeJson.metzStreet+" "} style={{fontFamily: "Muli-Light"}}/>
                                <MyText value={HomeJson.metzPostalCode+" "}/>
                                <MyText value={HomeJson.metz} style={{fontFamily: "Muli-Bold"}}/>
                            </Box>
                            <Box className={"goto"} onClick={() => window.location.assign(`tel:${HomeJson.metzTel}`)} style={{width: "max-content", marginLeft: "auto", marginRight: "auto", }}>
                                <TelIcon size={Number("var(--BigFontSize)")} style={{verticalAlign: "middle"}}/>
                                <MyText className={"goto"} value={HomeJson.metzTel} style={{marginLeft: "3px", fontSize: "var(--MidFontSize)", display: "inline-block"}}/>
                            </Box>
                            <iframe
                                title={"map"}
                                width="80%"
                                height={400}
                                style={{border: "4px solid var(--Grey)", borderRadius: "var(--MapRadius)", marginTop: "20px", marginBottom: "20px", maxHeight: "1080px", marginLeft: "auto", marginRight: "auto"}}
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyCHrlOWH6CaBe5GRfwyJSHNIjM38XtcR48&q=societe+wollenschneider+metz&language=${mapLanguage[props.language]}`}>
                            </iframe>
                        </Box>
                    </Box>
                </Box>
                {props.windowWidth > 980 && <img loading={"lazy"} src={WireImg} alt={"Zip image"} style={{width: "170px", height: "1900px", marginTop: props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "60px" : "0px"}}/>}
                <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px", marginBottom: "100px", width: props.windowWidth > 980 ? "50%" : "100%", position: "relative", left: props.windowWidth > 980 ? "-20px" : "0"}}>
                    <Box style={{width: getContactFormWidth(), marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", marginBottom: "100px"}}>
                        <Box ref={ref0} className={inViewRef0 ? "fadeDisplayAnim2" : ""} style={{textAlign: "center", marginBottom: "60px"}}>
                            <MyText value={ContactJson.availableLanguages[props.language].subtitleForm} style={{fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}/>
                        </Box>
                        <Box ref={ref1} className={inViewRef1 ? "fadeDisplayAnim2" : ""} style={{display: "flex", marginLeft: "auto", marginRight: "auto", marginBottom: "45px"}}>
                            <Box style={{display: "flex"}}>
                                <Box style={{width: "15px", height: "15px", background: "red", borderRadius: "50px", marginTop: "auto", marginBottom: "auto", marginLeft: "5px", marginRight: "5px"}}/>
                                <MyText value={ContactJson.availableLanguages[props.language].descriptionError} style={{color: "grey"}}/>
                            </Box>
                            <Box style={{display: "flex", marginLeft: "10px", marginRight: "10px"}}>
                                <Box style={{width: "15px", height: "15px", background: "orange", borderRadius: "50px", marginTop: "auto", marginBottom: "auto", marginLeft: "5px", marginRight: "5px"}}/>
                                <MyText value={ContactJson.availableLanguages[props.language].descriptionWarning + " (*)"} style={{color: "grey"}}/>
                            </Box>
                            <Box style={{display: "flex"}}>
                                <Box style={{width: "15px", height: "15px", background: "var(--Green)", borderRadius: "50px", marginTop: "auto", marginBottom: "auto", marginLeft: "5px", marginRight: "5px"}}/>
                                <MyText value={ContactJson.availableLanguages[props.language].descriptionGood} style={{color: "grey"}}/>
                            </Box>
                        </Box>
                        <Box ref={ref2} className={inViewRef2 ? "fadeDisplayAnim2 patientStatus" : "patientStatus"} style={{width: "40%", marginLeft: "auto", marginRight: "auto"}}>
                            <MyRadio options={ContactJson.availableLanguages[props.language].patientStatusOptions} title={ContactJson.availableLanguages[props.language].patientStatusTitle + " *"} styleTitle={{textAlign: "center", fontSize: "var(--BigFontSize)", color: patientStatusStatus === 1 ? "orange" : patientStatusStatus === 3 ? "var(--Green)" : "var(--Black)"}} value={patientStatus} setValue={setPatientStatus} styleBox={{display: "flex", marginTop: "10px", justifyContent: "center", flexWrap: "wrap"}} styleLabel={{margin: "10px", marginTop: "0", minWidth: "170px", color: "grey"}} styleLabelHovered={{margin: "10px", marginTop: "0", minWidth: "170px"}} radioColor={patientStatusStatus === 1 ? "orange" : "grey"}/>
                        </Box>
                        <Box ref={ref3} className={inViewRef3 ? "fadeDisplayAnim2 concernedOffice" : "concernedOffice"} style={{width: "40%", marginLeft: "auto", marginRight: "auto"}}>
                            <MyCheckbox options={ContactJson.availableLanguages[props.language].concernedOfficeOptions} title={ContactJson.availableLanguages[props.language].concernedOfficeTitle + " *"} styleTitle={{textAlign: "center", marginTop: "45px", fontSize: "var(--BigFontSize)", color: concernedOfficeStatus === 1 ? "orange" : concernedOfficeStatus === 3 ? "var(--Green)" : "var(--Black)"}} values={concernedOffice} setValues={setConcernedOffice} styleBox={{display: "flex", marginTop: "10px", justifyContent: "center", flexWrap: "wrap"}} styleLabel={{margin: "10px", marginTop: "0", minWidth: "160px", color: "grey"}} styleLabelHovered={{margin: "10px", marginTop: "0", minWidth: "160px"}} checkboxColor={concernedOfficeStatus === 1 ? "orange" : "grey"} getInFr={false}/>
                        </Box>
                        <Box ref={ref4} className={inViewRef4 ? "fadeDisplayAnim2 subject" : "subject"}>
                            <MyCheckbox options={ContactJson.availableLanguages[props.language].subjectOptions} title={ContactJson.availableLanguages[props.language].subjectTitle + " *"} styleTitle={{textAlign: "center", marginTop: "45px", fontSize: "var(--BigFontSize)", color: subjectStatus === 1 ? "orange" : subjectStatus === 3 ? "var(--Green)" : "var(--Black)"}} values={subject} setValues={setSubject} styleBox={{display: "flex", marginTop: "10px", justifyContent: "center", flexWrap: "wrap"}} styleLabel={{margin: "10px", marginTop: "0", minWidth: "260px", color: "grey"}} styleLabelHovered={{margin: "10px", marginTop: "0", minWidth: "260px"}} checkboxColor={subjectStatus === 1 ? "orange" : "grey"} getInFr={true}/>
                        </Box>
                        <Box ref={ref5} className={inViewRef5 ? "fadeDisplayAnim2" : ""} style={{display: "flex", flexDirection: "column", marginTop: "25px"}}>
                            <Box className={"firstName"} style={{width: "100%", marginTop: "20px"}}>
                                <MyInputText value={firstName} setValue={setFirstName} label={ContactJson.availableLanguages[props.language].firstName + " *"} boxStyle={{width: "100%"}} labelStyle={{fontSize: "var(--BigFontSize)"}} style={{fontSize: "var(--MidFontSize)"}} charactersOnly={true} maxLength={30} status={firstNameStatus} focus={firstNameFocus} setFocus={setFirstNameFocus}/>
                            </Box>
                            <Box className={"lastName"} style={{width: "100%", marginTop: "20px"}}>
                                <MyInputText value={lastName} setValue={setLastName} label={ContactJson.availableLanguages[props.language].lastName + " *"} boxStyle={{width: "100%", marginLeft: "auto"}} labelStyle={{fontSize: "var(--BigFontSize)"}} style={{fontSize: "var(--MidFontSize)"}} charactersOnly={true} maxLength={30} status={lastNameStatus} focus={lastNameFocus} setFocus={setLastNameFocus}/>
                            </Box>
                        </Box>
                        <Box ref={ref6} className={inViewRef6 ? "fadeDisplayAnim2" : ""} style={{display: "flex", flexDirection: "column"}}>
                            <Box className={"phone tel"} style={{width: "100%", marginTop: "20px"}}>
                                <MyInputText value={phone} setValue={setPhone} label={ContactJson.availableLanguages[props.language].phone + " *"} boxStyle={{width: "100%"}} labelStyle={{fontSize: "var(--BigFontSize)"}} style={{fontSize: "var(--MidFontSize)"}} type={"tel"} maxLength={30} status={phoneStatus} focus={phoneFocus} setFocus={setPhoneFocus}/>
                            </Box>
                            <Box className={"email"} style={{width: "100%", marginTop: "20px"}}>
                                <MyInputText value={email} setValue={setEmail} label={ContactJson.availableLanguages[props.language].email + " " + ContactJson.availableLanguages[props.language].emailRight} boxStyle={{width: "100%", marginLeft: "auto"}} labelStyle={{fontSize: "var(--BigFontSize)"}} style={{fontSize: "var(--MidFontSize)"}} type={"mail"} maxLength={50} status={emailStatus} focus={emailFocus} setFocus={setEmailFocus}/>
                            </Box>
                        </Box>
                        <Box ref={ref7} className={inViewRef7 ? "fadeDisplayAnim2 message" : "message"}>
                            <MyInputTextArea value={message} setValue={setMessage} label={ContactJson.availableLanguages[props.language].message + " *"} boxStyle={{marginTop: "20px"}} labelStyle={{fontSize: "var(--BigFontSize)"}} style={{fontSize: "var(--MidFontSize)", minHeight: "450px"}} maxLength={600} status={messageStatus} focus={messageFocus} setFocus={setMessageFocus}/>
                        </Box>
                        <Box ref={ref8} className={inViewRef8 ? "fadeDisplayAnim2 consent" : "consent"} style={{marginTop: "20px", display: "flex", alignItems: "center", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                            <Checkbox
                                sx={{
                                    color: consentStatus === 1 ? "orange" : "var(--Black)",
                                    cursor: "pointer",
                                    transition: "all 0.2s ease",
                                }}
                                name='checkbox' checked={consent}/>
                            <button style={{width: "30px", height: "30px", position: "absolute", background: "transparent", border: "none", cursor: "pointer"}} onClick={() => setConsent(!consent)}/>
                            <Box>
                                <Link
                                    sx={{
                                        color: "var(--Black)",
                                        fontSize: "myBig",
                                        fontFamily: "myRegular",
                                        cursor: "pointer",
                                        transition: "all 0.2s ease",
                                        "&:hover": {
                                            color: "myGreen",
                                        }
                                    }} onClick={() => setConsent(!consent)}>
                                    {ContactJson.availableLanguages[props.language].consent}
                                </Link>
                                <Link
                                    sx={{
                                        color: "var(--Black)",
                                        fontSize: "myBig",
                                        fontFamily: "myRegular",
                                        cursor: "pointer",
                                        transition: "all 0.2s ease",
                                        textDecoration: "underline",
                                        textUnderlineOffset: "4px",
                                        "&:hover": {
                                            color: "myGreen",
                                        }
                                    }} onClick={() => navigate("/privatelifeprotection")}>
                                    {ContactJson.availableLanguages[props.language].consentLink}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    <Box ref={ref9} className={inViewRef9 ? "fadeDisplayAnim2 " : "noOpacity"}>
                        <SendButton onClick={() => handleClick()} state={state} setState={setState} initialText={ContactJson.availableLanguages[props.language].sendInitial} sendingText={ContactJson.availableLanguages[props.language].sendSending} sendErrorText={ContactJson.availableLanguages[props.language].sendError} sendSuccessText={ContactJson.availableLanguages[props.language].sendSuccess}/>
                    </Box>
                    {/*{props.windowWidth > 980 && <img loading={"lazy"} ref={refDeco} className={inViewRefDeco ? "rightToLeftDisplayAnim5" : "noOpacity"} src={FootImg} alt={"Foot image"} style={{height: "400px", marginTop: props.windowWidth > ConstantsJson.MiddleScreenStartWidth ? "60px" : "0px"}}/>}*/}
                </Box>
            </Flex>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default Contact;
import React, {useState} from "react";
import { Select, Box, Label } from 'theme-ui'

interface Props {
    options: string[];
    value: string
    setValue: (e: any) => void;
    defaultValue?: string;
    label?: string;
    onChange?: () => void;
    style?: object;
    boxStyle?: object;
    labelStyle?: object;
    firstSelectValue?: string;
}

export const MySelect = (props: Props) => {
    const [firstselect, setFirstSelect] = useState(false)
    const options = props.firstSelectValue && !props.defaultValue ? [props.firstSelectValue, ...props.options] : props.options
    const defaultValue = props.defaultValue ? props.defaultValue : props.firstSelectValue

    return (
        <Box style={props.boxStyle}>
            {props.label &&
                <Label
                    sx={{
                        marginLeft: "5px",
                        marginBottom: "2px",
                        transition: "all 0.2s ease",
                        fontSize: "myBig",
                    }}
                    style={props.labelStyle}>
                    {props.label}</Label>
            }
            <Select
                sx={{
                    color: "myGrey",
                    background: "myInputGrey",
                    outline: "none",
                    border: "1px solid myGrey",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontFamily: "myRegular",
                    fontSize: "myBig",
                    paddingLeft: "12px",
                    paddingRight: "12px"
                }}
                style={props.style}
                value={props.value === "" && defaultValue ? defaultValue : props.value} onChange={props.onChange ? () => {setFirstSelect(true); if (props.onChange) props.onChange()} : (e) => {setFirstSelect(true); props.setValue(e.target.value)}}>
                {!firstselect && options.map((option, index) => (
                    <option key={index}>
                        {option}
                    </option>
                ))}
                {firstselect && props.options.map((option, index) => (
                    <option key={index}>
                        {option}
                    </option>
                ))}
            </Select>
        </Box>
    )
};

export default MySelect;
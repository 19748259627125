import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import MyText from "../components/MyText";
import VideoLoopJson from "../assets/jsons/videoLoop.json"
import "../assets/css/constants.css"
import "../assets/css/offices.css"
import Footer from "../components/Footer";
import PageTitle from "../components/PageTitle";
import {Flex} from "rebass";
import CountUp from "react-countup";
import countapi from "countapi-js";
import MyButton from "../components/MyButton";
import MyConfirmationDialog from "../components/MyConfirmationDialog";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const Counter = (props: Props) => {
    const [count, setCount] = useState(0);
    const [countTotal, setCountTotal] = useState(0);
    const [open, setOpen] = useState(false)

    function getValues(){
        countapi.get('wollenschneider.fr', '4a91bc2d-458b-4c68-8aaa-51981e5bd08a').then((result) => { setCount(result.value) });
        countapi.get('wollenschneider.frtotal', 'a28f08b9-96e5-49c9-94f1-83e2b1737d8b').then((result) => { setCountTotal(result.value) });
    }

    setInterval(function(){
        getValues()
    }, 30000)

    useEffect(()=> {
        getValues();
    },[])

    return (
        <Box>
            {open && <MyConfirmationDialog onClose={() => setOpen(false)} onConfirm={() => {countapi.set('wollenschneider.fr', '4a91bc2d-458b-4c68-8aaa-51981e5bd08a', 0).then((result) => { alert("La valeur à bien été remise à 0") }); setCount(0)}} language={props.language}/>}
            <PageTitle title={"Compteur"} backgroundPicture={"assets/picturesAndVideos/stats/bg"} backgroundPositionY={"top"}/>
            <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} marginTop={"100px"} marginBottom={"150px"}>
                <CountUp
                    start={0}
                    end={countTotal}
                    duration={3.2}
                    useEasing={true}
                    delay={0.5}
                    style={{fontFamily: "Muli-Bold", fontSize: "var(--GiantFontSize)"}}
                />
                <MyText value={"Au total"} style={{fontSize: "var(--TitleFontSize)", fontFamily: "Muli-Bold", marginTop: "20px"}}/>
            </Flex>
            <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} marginTop={"100px"} marginBottom={"150px"}>
                <CountUp
                    start={0}
                    end={count}
                    duration={3.2}
                    useEasing={true}
                    delay={0.5}
                    style={{fontFamily: "Muli-Bold", fontSize: "var(--GiantFontSize)"}}
                />
                <MyText value={"Depuis remise à 0"} style={{fontSize: "var(--TitleFontSize)", fontFamily: "Muli-Bold", marginTop: "20px", marginBottom: "10px"}}/>
                <MyButton value={"Remise à 0"} style={{width: "250px", marginLeft: "auto", marginRight: "auto"}} styleHovered={{width: "250px", marginLeft: "auto", marginRight: "auto"}} onClick={() => {setOpen(true)}}/>
            </Flex>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default Counter;
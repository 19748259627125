import React from 'react';
import {Box} from "@mui/material";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import ProtectionJson from "../assets/jsons/privateLife.json";
import MyText from "../components/MyText";
import {Flex} from "rebass";

interface Props {
    windowWidth: number;
    windowHeight: number;
    language: number;
}

const PrivateLifeProtection = (props: Props) => {
    return (
        <Box>
            <PageTitle title={ProtectionJson.availableLanguages[props.language].title} backgroundPicture={ProtectionJson.availableLanguages[props.language].backgroundLink}/>
            <Flex flexDirection={"column"} width={"75%"} alignItems={"center"} justifyContent={"center"} marginTop={"120px"} marginBottom={"120px"} marginLeft={"auto"} marginRight={"auto"}>
                <MyText value={ProtectionJson.availableLanguages[props.language].title1} style={{marginBottom: "30px", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text11} style={{marginBottom: "5px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text12} style={{marginBottom: "20px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text13} style={{marginBottom: "100px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].title2} style={{marginBottom: "30px", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text21} style={{marginBottom: "5px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text22} style={{marginBottom: "5px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text23} style={{marginBottom: "100px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].title3} style={{marginBottom: "30px", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text31} style={{marginBottom: "100px"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].title4} style={{marginBottom: "30px", fontFamily: "Muli-Bold", fontSize: "var(--TitleFontSize)"}}/>
                <MyText value={ProtectionJson.availableLanguages[props.language].text41}/>
            </Flex>
            <Footer windowWidth={props.windowWidth} windowHeight={props.windowHeight} language={props.language}/>
        </Box>
    );
};

export default PrivateLifeProtection;
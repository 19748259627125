import React, {useState} from "react";
import "../assets/css/constants.css"
import { Box } from "@mui/material";
import { MdCancel as BaseRightIcon} from "react-icons/md";

interface Props {
    backgroundColor?: string;
    borderBottom?: string;
    borderRadius?: string;
    borderRight?: string;
    fontFamily?: string;
    placeholder?: string;
    borderLeft?: string;
    borderTop?: string;
    minHeight?: string;
    maxHeight?: string;
    fontSize?: string;
    minWidth?: string;
    padding?: string;
    opacity?: number;
    height?: string;
    margin?: string;
    cursor?: string;
    color?: string;
    width?: string;
    maxWidth?: string;
    textAlign?: string;
    position?: string;
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    text: string;

    leftIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        maxHeight?: string;
        size?: string;
        minWidth?: string;
        maxWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        position?: string;
        top?: string;
        left?: string;
        right?: string;
        bottom?: string;
        onClick?: () => void;
    };

    rightIcon?: {
        icon: any;
        backgroundColor?: string;
        minHeight?: string;
        maxHeight?: string;
        size?: string;
        minWidth?: string;
        maxWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        position?: string;
        top?: string;
        left?: string;
        right?: string;
        bottom?: string;
        onClick?: () => void;
    };

    rightIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        maxHeight?: string;
        size?: string;
        minWidth?: string;
        maxWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        position?: string;
        top?: string;
        left?: string;
        right?: string;
        bottom?: string;
        opacity?: number;
    };

    leftIconHover?: {
        backgroundColor?: string;
        minHeight?: string;
        maxHeight?: string;
        size?: string;
        minWidth?: string;
        maxWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        position?: string;
        top?: string;
        left?: string;
        right?: string;
        bottom?: string;
        opacity?: number;
    };

    box?: {
        flexDirection?: string;
        justifyContent?: string;
        alignItems?: string;
    };
}

export const Text = (props: Props) => {
    const [rightIconHover, setRightIconHover] = useState(false);
    const [leftIconHover, setLeftIconHover] = useState(false);
    const isRightIcon = props.rightIcon?.icon;
    const isLeftIcon = props.leftIcon?.icon;
    const isLeftAndRightIcon = isRightIcon && isLeftIcon;

    const getPadding = () => {
        if (isLeftAndRightIcon)
            return "0 30px 0 30px"
        else if (isLeftIcon)
            return "0 0 0 30px"
        else if (isRightIcon)
            return "0 30px 0 0"
        else
            return "0 0 0 0"
    }

    const style = {
        backgroundColor: props.backgroundColor || "transparent",
        borderRadius: props.borderRadius || "0",
        borderBottom: props.borderBottom || "none",
        borderRight: props.borderRight || "none",
        borderLeft: props.borderLeft || "none",
        borderTop: props.borderTop || "none",
        padding: props.padding || getPadding(),
        cursor: props.cursor || "text",
        color: props.color || "var(--DarkGrey)",
        fontFamily: props.fontFamily || "inherit",
        fontSize: props.fontSize || "inherit",
        minHeight: props.minHeight || "auto",
        maxHeight: props.maxHeight || "auto",
        minWidth: props.minWidth || "auto",
        maxWidth: props.maxWidth || "auto",
        height: props.height || "auto",
        margin: props.margin || "0",
        width: props.width || "auto",
        lineHeight: props.height || "auto",
        textAlign: props.textAlign as "center" | "left" | "right" | "justify" | "initial" | "inherit" || "center",
        position: props.position as "relative" | "absolute" | "fixed" | "static" | "sticky" | "inherit" | "initial" | "unset" || "static",
        top: props.top || "auto",
        left: props.left || "auto",
        right: props.right || "auto",
        bottom: props.bottom || "auto",
        outline: "none",
    };

    const rightIconStyle = {
        backgroundColor: (props.rightIcon && props.rightIcon.backgroundColor) || "transparent",
        padding: (props.rightIcon && props.rightIcon.padding) || "0",
        cursor: (props.rightIcon && props.rightIcon.cursor) || "pointer",
        color: (props.rightIcon && props.rightIcon.color) || "var(--DarkGrey)",
        size: (props.rightIcon && props.rightIcon.size) || "inherit",
        minHeight: (props.rightIcon && props.rightIcon.minHeight) || "auto",
        maxHeight: (props.rightIcon && props.rightIcon.maxHeight) || "auto",
        minWidth: (props.rightIcon && props.rightIcon.minWidth) || "auto",
        maxWidth: (props.rightIcon && props.rightIcon.maxWidth) || "auto",
        height: (props.rightIcon && props.rightIcon.height) || "auto",
        margin: (props.rightIcon && props.rightIcon.margin) || "0 0 -2 -25",
        width: (props.rightIcon && props.rightIcon.width) || "auto",
        opacity: (props.rightIcon && props.rightIcon.opacity) || 1,
        visibility: (props.rightIcon && props.rightIcon.icon) ? "visible" : "hidden",
        position: (props.rightIcon && props.rightIcon.position) as "relative" | "absolute" | "fixed" | "static" | "sticky" | "inherit" | "initial" | "unset" || "static",
        top: (props.rightIcon && props.rightIcon.top) || "auto",
        left: (props.rightIcon && props.rightIcon.left) || "auto",
        right: (props.rightIcon && props.rightIcon.right) || "auto",
        bottom: (props.rightIcon && props.rightIcon.bottom) || "auto",
        outline: "none"
    };

    const rightIconHoverStyle = {
        backgroundColor: (props.rightIconHover && props.rightIconHover.backgroundColor) || "transparent",
        padding: (props.rightIconHover && props.rightIconHover.padding) || "0",
        cursor: (props.rightIconHover && props.rightIconHover.cursor) || "pointer",
        color: (props.rightIconHover && props.rightIconHover.color) || "var(--DarkGrey)",
        size: (props.rightIconHover && props.rightIconHover.size) || "inherit",
        minHeight: (props.rightIconHover && props.rightIconHover.minHeight) || "auto",
        maxHeight: (props.rightIconHover && props.rightIconHover.maxHeight) || "auto",
        minWidth: (props.rightIconHover && props.rightIconHover.minWidth) || "auto",
        maxWidth: (props.rightIconHover && props.rightIconHover.maxWidth) || "auto",
        height: (props.rightIconHover && props.rightIconHover.height) || "auto",
        margin: (props.rightIconHover && props.rightIconHover.margin) || "0 0 -2 -25",
        width: (props.rightIconHover && props.rightIconHover.width) || "auto",
        opacity: (props.rightIconHover && props.rightIconHover.opacity) || 1,
        position: (props.rightIconHover && props.rightIconHover.position) as "relative" | "absolute" | "fixed" | "static" | "sticky" | "inherit" | "initial" | "unset" || "static",
        top: (props.rightIconHover && props.rightIconHover.top) || "auto",
        left: (props.rightIconHover && props.rightIconHover.left) || "auto",
        right: (props.rightIconHover && props.rightIconHover.right) || "auto",
        bottom: (props.rightIconHover && props.rightIconHover.bottom) || "auto",
        outline: "none"
    };

    const leftIconStyle = {
        backgroundColor: (props.leftIcon && props.leftIcon.backgroundColor) || "transparent",
        padding: (props.leftIcon && props.leftIcon.padding) || "0",
        cursor: (props.leftIcon && props.leftIcon.cursor) || "pointer",
        color: (props.leftIcon && props.leftIcon.color) || "var(--DarkGrey)",
        size: (props.leftIcon && props.leftIcon.size) || "inherit",
        minHeight: (props.leftIcon && props.leftIcon.minHeight) || "auto",
        maxHeight: (props.leftIcon && props.leftIcon.maxHeight) || "auto",
        minWidth: (props.leftIcon && props.leftIcon.minWidth) || "auto",
        maxWidth: (props.leftIcon && props.leftIcon.maxWidth) || "auto",
        height: (props.leftIcon && props.leftIcon.height) || "auto",
        margin: (props.leftIcon && props.leftIcon.margin) || "0 -25px -2 0",
        width: (props.leftIcon && props.leftIcon.width) || "auto",
        opacity: (props.leftIcon && props.leftIcon.opacity) || 1,
        visibility: (props.leftIcon && props.leftIcon.icon) ? "visible" : "hidden",
        position: (props.leftIcon && props.leftIcon.position) as "relative" | "absolute" | "fixed" | "static" | "sticky" | "inherit" | "initial" | "unset" || "static",
        top: (props.leftIcon && props.leftIcon.top) || "auto",
        left: (props.leftIcon && props.leftIcon.left) || "auto",
        right: (props.leftIcon && props.leftIcon.right) || "auto",
        bottom: (props.leftIcon && props.leftIcon.bottom) || "auto",
        zIndex: "1",
        outline: "none",
    };

    const leftIconHoverStyle = {
        backgroundColor: (props.leftIconHover && props.leftIconHover.backgroundColor) || "transparent",
        padding: (props.leftIconHover && props.leftIconHover.padding) || "0",
        cursor: (props.leftIconHover && props.leftIconHover.cursor) || "pointer",
        color: (props.leftIconHover && props.leftIconHover.color) || "var(--DarkGrey)",
        size: (props.leftIconHover && props.leftIconHover.size) || "inherit",
        minHeight: (props.leftIconHover && props.leftIconHover.minHeight) || "auto",
        maxHeight: (props.leftIconHover && props.leftIconHover.maxHeight) || "auto",
        minWidth: (props.leftIconHover && props.leftIconHover.minWidth) || "auto",
        maxWidth: (props.leftIconHover && props.leftIconHover.maxWidth) || "auto",
        height: (props.leftIconHover && props.leftIconHover.height) || "auto",
        margin: (props.leftIconHover && props.leftIconHover.margin) || "0 -25px -2 0",
        width: (props.leftIconHover && props.leftIconHover.width) || "auto",
        opacity: (props.leftIconHover && props.leftIconHover.opacity) || 1,
        position: (props.rightIcon && props.rightIcon.icon) as "relative" | "absolute" | "fixed" | "static" | "sticky" | "inherit" | "initial" | "unset" || "static",
        top: (props.leftIconHover && props.leftIconHover.top) || "auto",
        left: (props.leftIconHover && props.leftIconHover.left) || "auto",
        right: (props.leftIconHover && props.leftIconHover.right) || "auto",
        bottom: (props.leftIconHover && props.leftIconHover.bottom) || "auto",
        zIndex: "1",
        outline: "none"
    };

    const boxStyle = {
        display: "flex",
        width: props.width || "100%",
        alignItems: props.box?.alignItems || "center",
        justifyContent: props.box?.justifyContent || "center",
    }

    const LeftIcon = (props.leftIcon && props.leftIcon.icon) || BaseRightIcon;
    const RightIcon = (props.rightIcon && props.rightIcon.icon) || BaseRightIcon;

    const rightIcon = <RightIcon style={rightIconHover ? rightIconHoverStyle : rightIconStyle} onMouseEnter={() => setRightIconHover(true)} onMouseLeave={() => setRightIconHover(false)} onClick={() => {props.rightIcon?.onClick && props.rightIcon.onClick()}}/>
    const leftIcon = <LeftIcon style={leftIconHover ? leftIconHoverStyle : leftIconStyle} onMouseEnter={() => setLeftIconHover(true)} onMouseLeave={() => setLeftIconHover(false)} onClick={() => {props.leftIcon?.onClick && props.leftIcon.onClick()}}/>;

    return (
        <Box style={boxStyle}>
            {leftIcon}
            <p style={style}>{props.text}</p>
            {rightIcon}
        </Box>
    )
};

export default Text;
import React, {useEffect, useState} from "react";
import "../assets/css/constants.css"
import {Box} from "@mui/material";

interface Props {
    // default
    defaultStyle: {
        onClick?: () => void
        resetOnClick?: () => void
        onClickLeftIcon?: () => void
        onClickRightIcon?: () => void
        backgroundColor?: string
        borderBottom?: string
        borderRadius?: string
        borderRight?: string
        borderLeft?: string
        fontWeight?: string
        fontFamily?: string
        transition?: string
        boxShadow?: string
        borderTop?: string
        minHeight?: string
        rightIcon?: any
        fontSize?: string
        leftIcon?: any
        minWidth?: string
        padding?: string
        text: string
        height?: string
        border?: string
        margin?: string
        cursor?: string
        color?: string
        width?: string
        selected?: boolean
    }

    // hover
    hoverStyle?: {
        backgroundColor?: string
        borderBottom?: string
        borderRadius?: string
        borderRight?: string
        borderLeft?: string
        fontWeight?: string
        fontFamily?: string
        transition?: string
        borderTop?: string
        minHeight?: string
        boxShadow?: string
        fontSize?: string
        minWidth?: string
        padding?: string
        height?: string
        border?: string
        margin?: string
        cursor?: string
        color?: string
        width?: string
    }

    // selected
    selectedStyle?: {
        backgroundColor?: string
        borderBottom?: string
        borderRadius?: string
        borderRight?: string
        borderLeft?: string
        fontWeight?: string
        fontFamily?: string
        transition?: string
        borderTop?: string
        minHeight?: string
        boxShadow?: string
        fontSize?: string
        minWidth?: string
        padding?: string
        height?: string
        border?: string
        margin?: string
        cursor?: string
        color?: string
        width?: string
    }

    // iconLeft
    iconLeftStyle?: {
        opacity?: string
        color?: string
    }

    // iconRight
    iconRightStyle?: {
        opacity?: string
        color?: string
    }
}

export const ButtonSelected = (props: Props) => {
    const [isHovered, setHovered] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isIconLeftHovered, setIconLeftHovered] = useState(false);
    const [isIconRightHovered, setIconRightHovered] = useState(false);

    useEffect(() => {
        if (!props.defaultStyle.selected) {
            setIsSelected(false);
        }
    }, [props.defaultStyle.selected]);

    const handleOnClick = () => {
        setIsSelected(!isSelected);
        if (!isSelected && props.defaultStyle.onClick)
            props.defaultStyle.onClick();
        else if (props.defaultStyle.resetOnClick)
            props.defaultStyle.resetOnClick();
    }

    // default
    const baseBorderBottom = "2px solid var(--Green)";
    const baseBorderRight = "2px solid var(--Green)";
    const baseBorderLeft = "2px solid var(--Green)";
    const baseBorderTop = "2px solid var(--Green)";
    const baseBackgroundColor = "var(--Green)";
    const baseBorderRadius = "var(--Radius)";
    const basePadding = "3px 3px 3px 3px";
    const baseTransition = "0.2s ease";
    const baseColor = "var(--White)";
    const baseCursor = "pointer";
    const baseMinWidth = "30%";

    // hover
    const hoverBaseBorderBottom = "2px solid lightgreen";
    const hoverBaseBorderRight = "2px solid lightgreen";
    const hoverBaseBorderLeft = "2px solid lightgreen";
    const hoverBaseBorderTop = "2px solid lightgreen";
    const hoverBaseBackgroundColor = "var(--Green)";
    const hoverBaseBorderRadius = "var(--Radius)";
    const hoverBasePadding = "3px 3px 3px 3px";
    const hoverBaseTransition = "0.2s ease";
    const hoverBaseColor = "var(--White)";
    const hoverBaseCursor = "pointer";
    const hoverBaseMinWidth = "30%";

    // selected
    const selectedBaseBackgroundColor = "var(--White)";
    const selectedBaseBorderRadius = "var(--Radius)";
    const selectedBasePadding = "3px 3px 3px 3px";
    const selectedBaseTransition = "0.2s ease";
    const selectedBaseColor = "var(--Green)";
    const selectedBaseBorderBottom = "2px solid var(--Green)";
    const selectedBaseBorderRight = "2px solid var(--Green)";
    const selectedBaseBorderLeft = "2px solid var(--Green)";
    const selectedBaseBorderTop = "2px solid var(--Green)";
    const selectedBaseCursor = "pointer";
    const selectedBaseMinWidth = "30%";

    // hoverIconRight
    const iconRightHoverBaseColor = "red"

    // default
    const styleDefault = {
        backgroundColor: props.defaultStyle.backgroundColor || baseBackgroundColor,
        borderRadius: props.defaultStyle.borderRadius || baseBorderRadius,
        borderBottom: props.defaultStyle.borderBottom || baseBorderBottom,
        borderRight: props.defaultStyle.borderRight || baseBorderRight,
        borderLeft: props.defaultStyle.borderLeft || baseBorderLeft,
        transition: props.defaultStyle.transition || baseTransition,
        borderTop: props.defaultStyle.borderTop || baseBorderTop,
        minWidth: props.defaultStyle.minWidth || baseMinWidth,
        padding: props.defaultStyle.padding || basePadding,
        cursor: props.defaultStyle.cursor || baseCursor,
        color: props.defaultStyle.color || baseColor,
        fontWeight: props.defaultStyle.fontWeight || "inherit",
        fontFamily: props.defaultStyle.fontFamily || "inherit",
        boxShadow: props.defaultStyle.boxShadow || "inherit",
        fontSize: props.defaultStyle.fontSize || "inherit",
        minHeight: props.defaultStyle.minHeight || "auto",
        height: props.defaultStyle.height || "auto",
        margin: props.defaultStyle.margin || "0",
        width: props.defaultStyle.width || "auto",
        display: "flex",
        outline: "none",
    };

    // hover
    const styleHover = {
        minWidth: props.hoverStyle ? props.hoverStyle.minWidth : props.defaultStyle.minWidth || hoverBaseMinWidth,
        backgroundColor: props.hoverStyle ? props.hoverStyle.backgroundColor : hoverBaseBackgroundColor,
        borderRadius: props.hoverStyle ? props.hoverStyle.borderRadius : hoverBaseBorderRadius,
        borderBottom: props.hoverStyle ? props.hoverStyle.borderBottom : hoverBaseBorderBottom,
        borderRight: props.hoverStyle ? props.hoverStyle.borderRight : hoverBaseBorderRight,
        borderLeft: props.hoverStyle ? props.hoverStyle.borderLeft : hoverBaseBorderLeft,
        transition: props.hoverStyle ? props.hoverStyle.transition : hoverBaseTransition,
        borderTop: props.hoverStyle ? props.hoverStyle.borderTop : hoverBaseBorderTop,
        padding: props.hoverStyle ? props.hoverStyle.padding : hoverBasePadding,
        cursor: props.hoverStyle ? props.hoverStyle.cursor : hoverBaseCursor,
        color: props.hoverStyle ? props.hoverStyle.color : hoverBaseColor,
        fontFamily: props.hoverStyle ? props.hoverStyle.fontFamily : props.defaultStyle.fontFamily || "inherit",
        fontWeight: props.hoverStyle ? props.hoverStyle.fontWeight : props.defaultStyle.fontWeight || "inherit",
        fontSize: props.hoverStyle ? props.hoverStyle.fontSize : props.defaultStyle.fontSize || "inherit",
        minHeight: props.hoverStyle ? props.hoverStyle.minHeight : props.defaultStyle.minHeight || "auto",
        height: props.hoverStyle ? props.hoverStyle.height : props.defaultStyle.height || "auto",
        margin: props.hoverStyle ? props.hoverStyle.margin : props.defaultStyle.margin || "0",
        width: props.hoverStyle ? props.hoverStyle.width : props.defaultStyle.width || "auto",
        display: "flex",
        outline: "none",
    };

    // selected
    const styleSelected =  {
        minWidth: props.selectedStyle ? props.selectedStyle.minWidth : props.defaultStyle.minWidth || selectedBaseMinWidth,
        backgroundColor: props.selectedStyle ? props.selectedStyle.backgroundColor : selectedBaseBackgroundColor,
        borderRadius: props.selectedStyle ?  props.selectedStyle.borderRadius : selectedBaseBorderRadius,
        borderBottom: props.selectedStyle ? props.selectedStyle.borderBottom : selectedBaseBorderBottom,
        borderRight: props.selectedStyle ? props.selectedStyle.borderRight : selectedBaseBorderRight,
        transition: props.selectedStyle ?  props.selectedStyle.transition : selectedBaseTransition,
        borderLeft: props.selectedStyle ? props.selectedStyle.borderLeft : selectedBaseBorderLeft,
        borderTop: props.selectedStyle ? props.selectedStyle.borderTop : selectedBaseBorderTop,
        padding: props.selectedStyle ? props.selectedStyle.padding : selectedBasePadding,
        cursor: props.selectedStyle ? props.selectedStyle.cursor : selectedBaseCursor,
        color: props.selectedStyle ? props.selectedStyle.color : selectedBaseColor,
        fontFamily: props.selectedStyle ? props.selectedStyle.fontFamily : props.defaultStyle.fontFamily || "inherit",
        fontWeight: props.selectedStyle ? props.selectedStyle.fontWeight : props.defaultStyle.fontWeight || "inherit",
        boxShadow: props.selectedStyle ? props.selectedStyle.boxShadow : props.defaultStyle.boxShadow || "inherit",
        fontSize: props.selectedStyle ? props.selectedStyle.fontSize : props.defaultStyle.fontSize || "inherit",
        minHeight: props.selectedStyle ? props.selectedStyle.minHeight : props.defaultStyle.minHeight || "auto",
        height: props.selectedStyle ? props.selectedStyle.height : props.defaultStyle.height || "auto",
        margin: props.selectedStyle ? props.selectedStyle.margin : props.defaultStyle.margin || "0",
        width: props.selectedStyle ? props.selectedStyle.width : props.defaultStyle.width || "auto",
        display: "flex",
        outline: "none",
    };

    // defaultIconLeft
    const styleIconLeftDefault = {
        opacity: props.iconLeftStyle ? props.iconLeftStyle.opacity : "inherit",
        color: props.iconLeftStyle ? props.iconLeftStyle.color : "inherit",
        paddingLeft: "10px",
        position: "relative" as "relative",
        left: "-10px",
        display: "flex",
        alignItems: "center",
        marginLeft: "auto"
    }

    // hoverIconLeft
    const styleIconLeftHover = {
        opacity: props.iconLeftStyle ? props.iconLeftStyle.opacity : "inherit",
        color: props.iconLeftStyle ? props.iconLeftStyle.color : "inherit",
        paddingLeft: "10px",
        position: "relative" as "relative",
        left: "-10px",
        display: "flex",
        alignItems: "center",
        marginLeft: "auto"
    }

    // defaultIconRight
    const styleIconRightDefault = {
        opacity: props.iconRightStyle ? props.iconRightStyle.opacity : "inherit",
        color: props.iconRightStyle ? props.iconRightStyle.color : iconRightHoverBaseColor,
        paddingRight: "10px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }

    // defaultIconRightHover
    const styleIconRightHover = {
        opacity: props.iconRightStyle ? props.iconRightStyle.opacity : "inherit",
        color: props.iconRightStyle ? props.iconRightStyle.color : iconRightHoverBaseColor,
        paddingRight: "10px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }

    return (
        <Box onClick={handleOnClick} style={isSelected ? styleSelected : isHovered ? styleHover : styleDefault} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Box style={isIconLeftHovered ? styleIconLeftHover : styleIconLeftDefault} onClick={props.defaultStyle.onClickLeftIcon} onMouseEnter={() => setIconLeftHovered(true)} onMouseLeave={() => setIconLeftHovered(false)}>
                {props.defaultStyle.leftIcon}
            </Box>
            <button style={{outline: "none", fontSize: "inherit", fontFamily: "inherit", fontWeight: "inherit", background: "none", border: "none", cursor: "pointer", color: "inherit", marginRight: "auto", paddingRight: (isSelected && props.defaultStyle.leftIcon) ? "0" : "22px", paddingLeft: !props.defaultStyle.leftIcon ? (isSelected && !props.defaultStyle.leftIcon) ? "15px" : "38px" : "0"}}>{props.defaultStyle.text}</button>
            {isSelected ? <Box style={isIconRightHovered ? styleIconRightHover : styleIconRightDefault} onClick={props.defaultStyle.onClickRightIcon} onMouseEnter={() => setIconRightHovered(true)} onMouseLeave={() => setIconRightHovered(false)}>
                {props.defaultStyle.rightIcon}
            </Box> : null}
        </Box>
    )
};

export default ButtonSelected;
import React, {useState} from "react";
import "../assets/css/constants.css"
import { Box } from "@mui/material";
import { MdCancel as BaseRightIcon} from "react-icons/md";

interface Props {
    backgroundColor?: string;
    borderBottom?: string;
    borderRadius?: string;
    onClick?: ({function:() => void, index: number})[];
    borderRight?: string;
    fontFamily?: string;
    borderLeft?: string;
    borderTop?: string;
    minHeight?: string;
    fontSize?: string;
    minWidth?: string;
    padding?: string;
    opacity?: number;
    height?: string;
    margin?: string;
    cursor?: string;
    color?: string;
    width?: string;
    texts: string[];

    hover?: {
        backgroundColor?: string;
        borderBottom?: string;
        borderRadius?: string;
        borderRight?: string;
        fontFamily?: string;
        borderLeft?: string;
        borderTop?: string;
        minHeight?: string;
        fontSize?: string;
        minWidth?: string;
        padding?: string;
        opacity?: number;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
    }

    selected?: {
        backgroundColor?: string;
        borderBottom?: string;
        borderRadius?: string;
        borderRight?: string;
        onClick?: ({function:() => void, index: number})[];
        fontFamily?: string;
        borderLeft?: string;
        borderTop?: string;
        minHeight?: string;
        fontSize?: string;
        minWidth?: string;
        padding?: string;
        opacity?: number;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        texts?: string[];
    }

    leftIcons?: {
        icons: {icon: any, index: number}[];
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick: ({function: () => void, index: number})[];
    };

    rightIcons?: {
        icons: {icon: any, index: number}[];
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
        onClick: ({function: () => void, index: number})[];
    };

    rightIconsHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };

    leftIconsHover?: {
        backgroundColor?: string;
        minHeight?: string;
        size?: string;
        minWidth?: string;
        padding?: string;
        height?: string;
        margin?: string;
        cursor?: string;
        color?: string;
        width?: string;
        opacity?: number;
    };

    box?: {
        flexDirection?: string;
    }
}

export const ButtonSelectedArray = (props: Props) => {
    const [selected, setSelected] = useState(-1);
    const [hover, setHover] = useState(-1);
    const [rightIconHover, setRightIconHover] = useState(-1);
    const [leftIconHover, setLeftIconHover] = useState(-1);

    const boxStyle = {
        display: "flex",
        // flexDirection: props.box?.flexDirection ? props.box.flexDirection as string | number | string & {} : "column" as "column",
        alignItems: "center",
        justifyContent: "center"
    }

    const style = {
        backgroundColor: props.backgroundColor || "var(--Green)",
        borderRadius: props.borderRadius || "4px",
        borderBottom: props.borderBottom || "none",
        borderRight: props.borderRight || "none",
        borderLeft: props.borderLeft || "none",
        borderTop: props.borderTop || "none",
        padding: props.padding || "8px 40px 8px 40px",
        cursor: props.cursor || "pointer",
        color: props.color || "var(--White)",
        fontFamily: props.fontFamily || "inherit",
        fontSize: props.fontSize || "inherit",
        minHeight: props.minHeight || "auto",
        minWidth: props.minWidth || "auto",
        height: props.height || "auto",
        margin: props.margin || "0",
        width: props.width || "auto",
        textAlign: "center" as "center",
        lineHeight: props.height || "auto",
        outline: "none"
    }

    const styleHover = {
        backgroundColor: (props.hover && props.hover.backgroundColor) || props.backgroundColor || "var(--Green)",
        borderRadius: (props.hover && props.hover.borderRadius) || props.borderRadius || "4px",
        borderBottom: (props.hover && props.hover.borderBottom) || props.borderBottom || "1px solid var(--LightGreen)",
        borderRight: (props.hover && props.hover.borderRight) || props.borderRight || "1px solid var(--LightGreen)",
        borderLeft: (props.hover && props.hover.borderLeft) || props.borderLeft || "1px solid var(--LightGreen)",
        borderTop: (props.hover && props.hover.borderTop) || props.borderTop || "1px solid var(--LightGreen)",
        padding: (props.hover && props.hover.padding) || props.padding || "7px 39px 7px 39px",
        cursor: (props.hover && props.hover.cursor) || props.cursor || "pointer",
        color: (props.hover && props.hover.color) || props.color || "var(--White)",
        fontFamily: (props.hover && props.hover.fontFamily) || props.fontFamily || "inherit",
        fontSize: (props.hover && props.hover.fontSize) || props.fontSize || "inherit",
        minHeight: (props.hover && props.hover.minHeight) || props.minHeight || "auto",
        minWidth: (props.hover && props.hover.minWidth) || props.minWidth || "auto",
        height: (props.hover && props.hover.height) || props.height || "auto",
        margin: (props.hover && props.hover.margin) || props.margin || "0",
        width: (props.hover && props.hover.width) || props.width || "auto",
        textAlign: "center" as "center",
        lineHeight: props.height || "auto",
        outline: "none"
    }

    const styleSelected = {
        backgroundColor: (props.selected && props.selected.backgroundColor) || props.backgroundColor || "var(--DarkGreen)",
        borderRadius: (props.selected && props.selected.borderRadius) || props.borderRadius || "4px",
        borderBottom: (props.selected && props.selected.borderBottom) || props.borderBottom || "none",
        borderRight: (props.selected && props.selected.borderRight) || props.borderRight || "none",
        borderLeft: (props.selected && props.selected.borderLeft) || props.borderLeft || "none",
        borderTop: (props.selected && props.selected.borderTop) || props.borderTop || "none",
        padding: (props.selected && props.selected.padding) || props.padding || "8px 40px 8px 40px",
        cursor: (props.selected && props.selected.cursor) || props.cursor || "pointer",
        color: (props.selected && props.selected.color) || props.color || "var(--White)",
        fontFamily: (props.selected && props.selected.fontFamily) || props.fontFamily || "inherit",
        fontSize: (props.selected && props.selected.fontSize) || props.fontSize || "inherit",
        minHeight: (props.selected && props.selected.minHeight) || props.minHeight || "auto",
        minWidth: (props.selected && props.selected.minWidth) || props.minWidth || "auto",
        height: (props.selected && props.selected.height) || props.height || "auto",
        margin: (props.selected && props.selected.margin) || props.margin || "0",
        width: (props.selected && props.selected.width) || props.width || "auto",
        textAlign: "center" as "center",
        lineHeight: props.height || "auto",
        outline: "none",
    }

    const rightIconsStyle = {
        backgroundColor: (props.rightIcons && props.rightIcons.backgroundColor) || "transparent",
        padding: (props.rightIcons && props.rightIcons.padding) || "0",
        cursor: (props.rightIcons && props.rightIcons.cursor) || "pointer",
        color: (props.rightIcons && props.rightIcons.color) || "var(--White)",
        size: (props.rightIcons && props.rightIcons.size) || "inherit",
        minHeight: (props.rightIcons && props.rightIcons.minHeight) || "auto",
        minWidth: (props.rightIcons && props.rightIcons.minWidth) || "auto",
        height: (props.rightIcons && props.rightIcons.height) || "auto",
        margin: (props.rightIcons && props.rightIcons.margin) || "0 0 0 -25px",
        width: (props.rightIcons && props.rightIcons.width) || "auto",
        opacity: (props.rightIcons && props.rightIcons.opacity) || 1,
        position: "relative" as "relative",
        outline: "none"
    };

    const rightIconsStyleNone = {
        backgroundColor: (props.rightIcons && props.rightIcons.backgroundColor) || "transparent",
        padding: (props.rightIcons && props.rightIcons.padding) || "0",
        cursor: (props.rightIcons && props.rightIcons.cursor) || "pointer",
        color: (props.rightIcons && props.rightIcons.color) || "var(--White)",
        size: (props.rightIcons && props.rightIcons.size) || "inherit",
        minHeight: (props.rightIcons && props.rightIcons.minHeight) || "auto",
        minWidth: (props.rightIcons && props.rightIcons.minWidth) || "auto",
        height: (props.rightIcons && props.rightIcons.height) || "auto",
        margin: (props.rightIcons && props.rightIcons.margin) || "0 0 0 -25px",
        width: (props.rightIcons && props.rightIcons.width) || "auto",
        opacity: (props.rightIcons && props.rightIcons.opacity) || 1,
        position: "relative" as "relative",
        zIndex: "-1",
        outline: "none"
    };

    const rightIconsHoverStyle = {
        backgroundColor: (props.rightIconsHover && props.rightIconsHover.backgroundColor) || "transparent",
        padding: (props.rightIconsHover && props.rightIconsHover.padding) || "0",
        cursor: (props.rightIconsHover && props.rightIconsHover.cursor) || "pointer",
        color: (props.rightIconsHover && props.rightIconsHover.color) || "var(--White)",
        size: (props.rightIconsHover && props.rightIconsHover.size) || "inherit",
        minHeight: (props.rightIconsHover && props.rightIconsHover.minHeight) || "auto",
        minWidth: (props.rightIconsHover && props.rightIconsHover.minWidth) || "auto",
        height: (props.rightIconsHover && props.rightIconsHover.height) || "auto",
        margin: (props.rightIconsHover && props.rightIconsHover.margin) || "0 0 0 -25px",
        width: (props.rightIconsHover && props.rightIconsHover.width) || "auto",
        opacity: (props.rightIconsHover && props.rightIconsHover.opacity) || 1,
        position: "relative" as "relative",
        outline: "none"
    };

    const leftIconsStyle = {
        backgroundColor: (props.leftIcons && props.leftIcons.backgroundColor) || "transparent",
        padding: (props.leftIcons && props.leftIcons.padding) || "0",
        cursor: (props.leftIcons && props.leftIcons.cursor) || "pointer",
        color: (props.leftIcons && props.leftIcons.color) || "var(--White)",
        size: (props.leftIcons && props.leftIcons.size) || "inherit",
        minHeight: (props.leftIcons && props.leftIcons.minHeight) || "auto",
        minWidth: (props.leftIcons && props.leftIcons.minWidth) || "auto",
        height: (props.leftIcons && props.leftIcons.height) || "auto",
        margin: (props.leftIcons && props.leftIcons.margin) || "0 -7px 0 0",
        width: (props.leftIcons && props.leftIcons.width) || "auto",
        opacity: (props.leftIcons && props.leftIcons.opacity) || 1,
        position: "relative" as "relative",
        left: "18px",
        zIndex: "1",
        outline: "none",
    };

    const leftIconsStyleNone = {
        backgroundColor: (props.leftIcons && props.leftIcons.backgroundColor) || "transparent",
        padding: (props.leftIcons && props.leftIcons.padding) || "0",
        cursor: (props.leftIcons && props.leftIcons.cursor) || "pointer",
        color: (props.leftIcons && props.leftIcons.color) || "var(--White)",
        size: (props.leftIcons && props.leftIcons.size) || "inherit",
        minHeight: (props.leftIcons && props.leftIcons.minHeight) || "auto",
        minWidth: (props.leftIcons && props.leftIcons.minWidth) || "auto",
        height: (props.leftIcons && props.leftIcons.height) || "auto",
        margin: (props.leftIcons && props.leftIcons.margin) || "0 -7px 0 0",
        width: (props.leftIcons && props.leftIcons.width) || "auto",
        opacity: (props.leftIcons && props.leftIcons.opacity) || 1,
        position: "relative" as "relative",
        left: "18px",
        zIndex: "-1",
        outline: "none",
    };

    const leftIconsHoverStyle = {
        backgroundColor: (props.leftIconsHover && props.leftIconsHover.backgroundColor) || "transparent",
        padding: (props.leftIconsHover && props.leftIconsHover.padding) || "0",
        cursor: (props.leftIconsHover && props.leftIconsHover.cursor) || "pointer",
        color: (props.leftIconsHover && props.leftIconsHover.color) || "var(--White)",
        size: (props.leftIconsHover && props.leftIconsHover.size) || "inherit",
        minHeight: (props.leftIconsHover && props.leftIconsHover.minHeight) || "auto",
        minWidth: (props.leftIconsHover && props.leftIconsHover.minWidth) || "auto",
        height: (props.leftIconsHover && props.leftIconsHover.height) || "auto",
        margin: (props.leftIconsHover && props.leftIconsHover.margin) || "0 -7px 0 0",
        width: (props.leftIconsHover && props.leftIconsHover.width) || "auto",
        opacity: (props.leftIconsHover && props.leftIconsHover.opacity) || 1,
        position: "relative" as "relative",
        left: "18px",
        zIndex: "1",
        outline: "none"
    };

    const isOnclick = (index: number) => {
        let returnValue = -1;

        props.onClick && props.onClick.map((onClick, i) => {
            if (onClick.index === index) {
                returnValue = i;
            }
        })
        return returnValue;
    }

    const isSelectedOnclick = (index: number) => {
        let returnValue = -1;

        props.selected?.onClick && props.selected.onClick.map((onClick, i) => {
            if (onClick.index === index) {
                returnValue = i;
            }
        })
        return returnValue;
    }

    const isRightIcon = (index: number) => {
        let returnValue = -1;

        props.rightIcons?.icons.map((icon, i) => {
            if (icon.index === index) {
                returnValue = i;
            }
        })
        return returnValue;
    }

    const isRightIconOnClick = (index: number) => {
        let returnValue = -1;

        props.rightIcons?.onClick.map((onClick, i) => {
            if (onClick.index === index) {
                returnValue = i;
            }
        })
        return returnValue;
    }

    const isLeftIcon = (index: number) => {
        let returnValue = -1;

        props.leftIcons?.icons.map((icon, i) => {
            if (icon.index === index) {
                returnValue = i;
            }
        })
        return returnValue;
    }

    const isLeftIconOnClick = (index: number) => {
        let returnValue = -1;

        props.leftIcons?.onClick.map((onClick, i) => {
            if (onClick.index === index) {
                returnValue = i;
            }
        })
        return returnValue;
    }

    const getRightIcon = (index: number) => {
        const RightIcon = (props.rightIcons?.icons && isRightIcon(index) !== -1 && props.rightIcons.icons[isRightIcon(index)].icon) || BaseRightIcon;

        return <RightIcon style={rightIconHover === index ? rightIconsHoverStyle : isRightIcon(index) !== -1 ? rightIconsStyle : rightIconsStyleNone} onMouseEnter={() => setRightIconHover(index)} onMouseLeave={() => setRightIconHover(-1)} onClick={() => {props.rightIcons?.onClick && isRightIconOnClick(index) !== -1 && props.rightIcons.onClick[isRightIconOnClick(index)].function()}}/>;
    }

    const getLeftIcon = (index: number) => {
        const LeftIcon = (props.leftIcons?.icons && isLeftIcon(index) !== -1 && props.leftIcons.icons[isLeftIcon(index)].icon) || BaseRightIcon;

        return <LeftIcon style={leftIconHover === index ? leftIconsHoverStyle : isLeftIcon(index) !== -1 ? leftIconsStyle : leftIconsStyleNone} onMouseEnter={() => setLeftIconHover(index)} onMouseLeave={() => setLeftIconHover(-1)} onClick={() => {props.leftIcons?.onClick && isLeftIconOnClick(index) !== -1 && props.leftIcons.onClick[isLeftIconOnClick(index)].function()}}/>;
    }

    const handleClick = (index: number) => {
        if (selected === index) {
            isSelectedOnclick(index) !== -1 && props.selected?.onClick && props.selected?.onClick[isSelectedOnclick(index)].function()
            setSelected(-1);
        } else {
            isOnclick(index) !== -1 && props.onClick && props.onClick[isOnclick(index)].function();
            setSelected(index);
        }
    }

    return (
        <Box style={boxStyle}>
            {props.texts && props.texts.map((text, index) => {
                return (
                    <Box style={{display: "flex"}}>
                        {getLeftIcon(index)}
                        <p onMouseEnter={() => setHover(index)} onMouseLeave={() => setHover(-1)} style={selected === index ? styleSelected : hover === index ? styleHover : style} onClick={() => handleClick(index)}>{selected && props.selected?.texts ? props.selected?.texts[index] : text}</p>
                        {getRightIcon(index)}
                    </Box>
                )
            })}
        </Box>
    )
};

export default ButtonSelectedArray;